import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import { GlobalContex } from "../../globalContex";
import "./filterDraw.scss";
import "./subdrawer.scss";
import back from "../../static/images/icons/back.svg";
import close from "../../static/images/icons/close1.svg";
import arrorRight from "../../static/images/icons/arrorRight.svg";
import FilterAssetList from "./FilterAssetList";
import FilterStatusList from "./FilterStatusList";
import FilterLength from "./FilterLength";
import axios from "axios";
import CustomerList from "./CustomerList";
import ProductStatusList from "./StatusList";

const GlobalFilterDrawer = ({ tabSelected }) => {
  const {
    bankerEmail,
    globalFilter,
    setGlobalFilter,
    refetchBonds,
    setRefetchBonds,
    selectedAssetFilters,
    setSelectedAssetFilters,
    selectedStatusFilters,
    setSelectedStatusFilters,
    selectedLengthFilter,
    setSelectedLengthFilter,
    selectedApp,
    selectedCustomer,
    setSelectedCustomer,
    setSelectedProductStatus
  } = useContext(GlobalContex);

  const [tab, setTab] = useState("Filter");
  const [step, setStep] = useState("");
  const [path, setPath] = useState([]);
  // const [subStep, setSubStep] = useState("");

  useEffect(() => {
    setStep("Filter");
    setPath(["Filter"]);
  }, []);

  const handleBackStep = () => {
    if (path.length > 1) {
      path.splice(-1);
      // console.log(tempPath, "kqjdkjwed");
      setPath([...path]);
      if (path.length > 0) {
        setStep(path[path.length - 1]);
      }
    }
  };

  const handleBreadcrumbClick = (clickedStep) => {
    const foundStepIndex = path.findIndex((o) => o === clickedStep);
    const tempPath = path;
    tempPath.splice(foundStepIndex + 1, tempPath.length);
    setPath([...tempPath]);
    console.log(path, "lwndwed");
  };

  useEffect(() => {
    setStep(path[path.length - 1]);

  }, [path]);


  console.log("filter-drawer", tabSelected)

  const getFilterData = () => {
    switch (tabSelected) {
      case "Orders":
        return <div
          className="filterCards"
          onClick={(e) => {
            setStep("Customer");
            setPath([...path, "Customer"]);
          }}
        >
          <div>
            <div className="cardTitle">Customer</div>

          </div>
          <img src={arrorRight} alt="" />
        </div>
      case "Products":
        return <div
          className="filterCards"
          onClick={(e) => {
            setStep("Status");
            setPath([...path, "Status"]);
          }}
        >
          <div>
            <div className="cardTitle">Status</div>

          </div>
          <img src={arrorRight} alt="" />
        </div>
      default:
        return <div></div>
    }
  }

  const getContent = () => {
    switch (step) {
      case "Asset":
        return (
          <>
            <div
              style={{
                padding: " 30px 35px",
              }}
            >
              <FilterAssetList />
            </div>
            <div
              className={
                selectedAssetFilters.length > 0
                  ? "bottomButtonFull"
                  : "bottomButtonDisabled"
              }
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
                onClick={(e) => {
                  setStep("");
                  handleBackStep();
                }}
              >
                Done Selecting
              </div>
            </div>
          </>
        );
      case "Customer":
        return (
          <>
            <div
              style={{
                padding: " 30px 35px",
              }}
            >
              <CustomerList />
            </div>
            <div
              className={
                selectedAssetFilters.length > 0
                  ? "bottomButtonFull"
                  : "bottomButtonDisabled"
              }
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
                onClick={(e) => {
                  setStep("");
                  handleBackStep();
                  setGlobalFilter(false);
                  setSelectedCustomer("")
                }}
              >
                Clear Selection
              </div>
            </div>
          </>
        );
      case "Status":
        return (
          <>
            <div
              style={{
                padding: " 30px 35px",
              }}
            >
              <ProductStatusList />
            </div>
            <div
              className={
                selectedAssetFilters.length > 0
                  ? "bottomButtonFull"
                  : "bottomButtonDisabled"
              }
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
                onClick={(e) => {
                  setStep("");
                  handleBackStep();
                  setGlobalFilter(false);
                  setSelectedProductStatus("")
                }}
              >
                Clear Selection
              </div>
            </div>
          </>
        );

      case "Length":
        return (
          <>
            <div
              style={{
                padding: " 30px 35px",
              }}
            >
              <FilterLength />
            </div>
            <div
              className={
                selectedLengthFilter.length > 0
                  ? "bottomButtonFull"
                  : "bottomButtonDisabled"
              }
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
                onClick={(e) => {
                  setStep("");
                  handleBackStep();
                }}
              >
                Done Selecting
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div
              style={{
                padding: " 30px 35px",
              }}
            >
              <div className="headerStyle">What would you like to filter by?</div>
              {getFilterData()}
            </div>
            <div
              className="bottomButtonWhite"
              onClick={(e) => {
                setGlobalFilter(false);
                setSelectedAssetFilters([]);
                setSelectedStatusFilters([]);
                setSelectedLengthFilter("");
                setSelectedCustomer("")
                setRefetchBonds(true);
              }}
            >
              <span className="fontAnimate">Clear Filter</span>
            </div>
            <div
              className="bottomButton"
              onClick={(e) => {
                setGlobalFilter(false);
                setRefetchBonds(true);
              }}
            >
              <span className="fontAnimate">Update Filter</span>
            </div>
          </>
        );
    }
  }

  console.log("abcdef", selectedApp);
  return (
    <>
      {selectedApp.DispName !== "Marketsverse" ? (
        <div
          className={
            globalFilter ? "right-drawer-visible" : "right-drawer-hidden"
          }
          style={{ height: window.innerHeight - 129, marginTop: "8px" }}
        >
          <div className="navs-disp">
            <div
              onClick={(e) => {
                setTab("Filter");
                setStep("");
              }}
              className={tab === "Filter" ? "navs-data active-tab" : "navs-data"}
            >
              <div>Filter</div>
            </div>
            <div
              onClick={(e) => {
                setTab("API");
                setStep("");
              }}
              className={tab === "API" ? "navs-data active-tab" : "navs-data"}
            >
              <div>API</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "30px",
              // paddingTop:"50px",
              height: "90px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ paddingLeft: "10px" }}>
                <div style={{ fontSize: "28px", fontWeight: "600" }}>
                  {tabSelected === "Products" ? "Product Filters" : "Filter By"}
                </div>

                <div
                  className={
                    step === path[path.length]
                      ? "breadcrumbSelected"
                      : "breadcrumb"
                  }
                >
                  {path?.map((item, index) => {
                    return (
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight:
                            index === path.length - 1 && index !== 0 ? 600 : "",
                        }}
                        onClick={(e) => handleBreadcrumbClick(item)}
                      >
                        {index !== 0 ? <span>&nbsp;{`>`}&nbsp;</span> : ""}&nbsp;
                        <span
                          className="hoverText"
                          style={{
                            textDecoration:
                              index === path.length - 1 && index !== 0
                                ? "underline"
                                : "",
                          }}
                        >
                          {item}
                        </span>
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {path.length > 1 ? (
                <div
                  className="backButton icon-imgs"
                  onClick={(e) => handleBackStep()}
                >
                  <img src={back} alt="" />
                </div>
              ) : (
                ""
              )}
              <div
                className="backButton icon-imgs"
                onClick={(e) => setGlobalFilter(false)}
                style={{
                  marginLeft: "10px",
                }}
              >
                <img src={close} alt="" />
              </div>
            </div>
          </div>
          {getContent()}
        </div>
      ) : (
        <div
          className={
            globalFilter ? "right-drawer-visible" : "right-drawer-hidden"
          }
          style={{ height: window.innerHeight - 177, top: 177 }}
        >
          <div
            style={{
              padding: "30px",
              height: "90px",
            }}
          >
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <img
                  src={
                    selectedBusiness?.profile_pic
                      ? selectedBusiness?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                  <div>
                    <div style={{ fontSize: "22px" }}>
                      Filters
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {path.length > 1 ? (
                    <div
                      className="backButton icon-imgs"
                    // onClick={(e) => handleBackStep()}
                    >
                      <img src={back} alt="" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => {
                      setGlobalFilter(false);
                    }}
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
              </div>

              <div
                className="breadcrumb"
                style={{ display: "flex", flexDirection: "row", paddingBottom: "20px" }}
              >
                {path?.map((item, index) => {
                  return (
                    <div>
                      {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                      <span
                        onClick={(e) => handleBreadcrumbClick(item)}
                        className={
                          index === path.length - 1 ? "crumbSelected" : "crumbs"
                        }
                      >
                        {item}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <>
              <div className="sidebarTitle">Select filter variable</div>
              <div className="sidebarScroll">
                <div
                  className="sidebarCard"
                // onClick={() => {
                //   setStep("Edit Type");
                //   setPath([...path, "Edit Type"]);
                // }}
                >
                  {/* <img
                src={editPub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
                  <div>By Affiliate</div>
                </div>
                <div
                  className="sidebarCard"
                // onClick={() => {
                //   setStep("Delete Publication");
                //   setPath([...path, "Delete Publication"]);
                // }}
                >
                  {/* <img
                src={deletePub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
                  <div>By Campaign</div>
                </div>
                {/* <div
                className="sidebarCard"
                onClick={() => {
                  setStep("Add To Publication");
                  setPath([...path, "Add To Publication"]);
                }}
              >
                
                <div>
                  Add Author
                </div>
              </div> */}
                <div
                  className="sidebarCard"
                // onClick={() => {
                //   setStep("Remove From Publication");
                //   setPath([...path, "Remove From Publication"]);
                // }}
                >
                  {/* <img
                src={editPub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
                  <div>
                    By Status
                  </div>
                </div>
                <div
                  className="sidebarCard"
                // onClick={() => {
                //   setStep("Remove From Publication");
                //   setPath([...path, "Remove From Publication"]);
                // }}
                >
                  {/* <img
                src={editPub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
                  <div>
                    By Date
                  </div>
                </div>
                <div
                  className="sidebarCard"
                // onClick={() => {
                //   setStep("Remove From Publication");
                //   setPath([...path, "Remove From Publication"]);
                // }}
                >
                  {/* <img
                src={editPub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
                  <div>
                    By Form
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default GlobalFilterDrawer;
