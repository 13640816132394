import React, { useContext, useEffect, useState } from "react";

import back from "../../../../../static/images/icons/back.svg";
import close from "../../../../../static/images/whiteClose.svg";
import editPub from "../../../../../static/images/clipIcons/editPub.svg";
import userIcon from "../../../../../static/images/clipIcons/userIcon.svg";
import deletePub from "../../../../../static/images/clipIcons/delete.svg";
import bondIcon from "../../../../../static/images/clipIcons/bondIcon.svg";
import plusIcon from "../../../../../static/images/clipIcons/plus.svg";
import yesIcon from "../../../../../static/images/clipIcons/yes.svg";
import noIcon from "../../../../../static/images/clipIcons/no.svg";
import addIcon from "../../../../../static/images/clipIcons/addicon.svg";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";

// import defaultImg from "../../../../../static/images/icons/app_placeholder.png";
// import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import defaultImg from "../../../../../static/images/icons/defaultImg.svg";
import lock from "../../../../../static/images/icons/lock.svg";

import changeBond from "../../../../../static/images/icons/changeBond.svg";
import okIcon from "../../../../../static/images/icons/tik.svg";
import backIcon from "../../../../../static/images/icons/backArrow.svg";

import { GlobalContex } from "../../../../../globalContex";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import { toast, ToastContainer } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import Scrollbars from "react-custom-scrollbars";
import Axios from "axios";
import * as jose from "jose";
import "./subdrawer.scss";
import UploadIconImg from "../../../../../static/images/uploadIconImg.svg";


function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const Actions = ({
  selectedBusiness,
  allBonds,
  currenciesData,
  loading,
  setLoading,
  step,
  setStep,
  path,
  setPath,
  selectedStory,
  setSelectedStory
}) => {
  const navigate = useNavigate();
  const {
    showSubDraw,
    setShowSubDraw,
    actionsSubDrawer,
    selectedListItem,
    setSelectedListItem,
    bondSelected,
    setBondSelected,
    bankerEmail,
    loginData,
    refetchBonds,
    setRefetchBonds,
    selectedMcbDashboardApp,
    allBusiness,
    setActionsSubDrawer,
    setRefetchAppData,
    StorySubDrawer,
    setStorySubDrawer,
    setTabSelected,
    refreshStories,
    setRefreshStories
  } = useContext(GlobalContex);

  const [bondStatus, setBondStatus] = useState("");
  const [search, setSearch] = useState("");
  const [allAuthors, setAllAuthors] = useState([]);
  const [allAuthorsInPub, setAllAuthorsInPub] = useState([]);
  const [allAuthorsNotInPub, setAllAuthorsNotInPub] = useState([]);
  const [theTitle, setTheTitle] = useState("Select An Action");

  const [selectedAuthors, setSelectedAuthors] = useState([]);
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [link, setLink] = useState("");
  const [colouredIcon, setColouredIcon] = useState("");
  const [colouredIconLoading, setColouredIconLoading] = useState("");
  const [email, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [newDesc, setNewDesc] = useState("");


  // useEffect(() => {
  //   setStep("Action");
  //   setPath(["Action"]);
  // }, [StorySubDrawer]);

  const handleBackStep = () => {
    if (path.length > 1) {
      path.splice(-1);
      // console.log(tempPath, "kqjdkjwed");
      setPath([...path]);
      if (path.length > 0) {
        setStep(path[path.length - 1]);
      }
    }
  };

  const handleBreadcrumbClick = (clickedStep) => {
    const foundStepIndex = path.findIndex((o) => o === clickedStep);
    const tempPath = path;
    tempPath.splice(foundStepIndex + 1, tempPath.length);
    setPath([...tempPath]);
    console.log(path, "lwndwed");
  };

  useEffect(() => {
    setStep(path[path.length - 1]);
  }, [path]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  const handleDeletePub = () => {
    setStep("loading")
    axios
      .delete(
        `https://publications.apimachine.com/webstory/${selectedStory?._id}`
      )
      .then(({ data }) => {
        if (data.status === false) {
          if (data.message === "jwt expired") {
            setStep("Token Expired");
          } else {
            toast.error(data.message || "API Error");
          }
          toast.success(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setStep("")
      })
      .finally(() => {
        setLoading(false);
        setTabSelected("Web Stories");
        setStep("");
        setColouredIcon("");
        setPath([]);
        setStorySubDrawer(false);
        setTheTitle("Select An Action")
        setRefreshStories(!refreshStories);
      });
  };

  const pathAdjust = () => {
    if (path.length > 0) {
      setPath(path.slice(0, -1))
    } else {
      setPath([])
    }
    // console.log(step, "steppp")
    if (step === "Add Story") {
      setTheTitle("Select An Action")
    } else if (step === "Edit Story") {
      setTheTitle("Select An Action")
    } else if (step === "Edit Name") {
      setTheTitle("What do you want to change?")
    } else if (step === "Edit Icon") {
      setTheTitle("What do you want to change?")
    } else if (step === "Edit Description") {
      setTheTitle("What do you want to change?")
    }
  }

  const WebStoryValidate = (item) => {
    console.log(item, "item")
    let body
    if (item === "name") {
      if (!newName) {
        toast.warning("Please Enter New Name")
        return
      } else {
        body = { name: newName }
      }
    } else if (item === "desc") {
      if (!newDesc) {
        toast.warning("Please Enter New Description")
        return
      } else {
        body = { desc: newDesc }
      }
    } else if (item === "icon") {
      if (!colouredIcon || colouredIcon === colouredIconLoading) {
        toast.warning("Please Upload Icon")
        return
      } else {
        body = { icon: colouredIcon }
      }
    }

    setStep("loading")
    axios
      .put(`https://publications.apimachine.com/webstory/${selectedStory?._id}`, body)
      .then(({ data }) => {
        if (data.status === false) {
          if (data.message === "jwt expired") {
            setStep("Token Expired");
          } else {
            toast.error(data.message || "API Error");
          }
          toast.success(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setStep("")
      })
      .finally(() => {
        setLoading(false);
        setTabSelected("Web Stories");
        setStep("");
        setColouredIcon("");
        setPath([]);
        setStorySubDrawer(false);
        setTheTitle("Select An Action")
        setRefreshStories(!refreshStories);
      });
  }

  const fullHeightDrawer = (message) => {
    if (message) {
      return (
        <div
          style={{
            height: window.innerHeight - 150,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingAnimation logoAnim sideDraw={true} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            {message}
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            height: window.innerHeight - 123,
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingAnimation logoAnim sideDraw={true} />
          <div
            style={{
              position: "absolute",
              bottom: 0,
              textAlign: "center",
            }}
          >
            Updating Brand List...
          </div>
        </div>
      );
    }
  };

  // useEffect(() => {
  //   if (selectedBusiness && selectedBusiness !== null) {
  //     axios
  //       .all([
  //         axios.get(
  //           `https://publications.apimachine.com/application/notInPublication/${selectedBusiness?._id}`
  //         ),
  //         axios.get(
  //           `https://publications.apimachine.com/application/publication/${selectedBusiness?._id}`
  //         ),
  //       ])
  //       .then(
  //         axios.spread((...responses) => {
  //           const responseOne = responses[0];
  //           const responseTwo = responses[1];
  //           setAllAuthorsNotInPub(responseOne.data.data);
  //           setAllAuthorsInPub(responseTwo.data.data);
  //           // console.log(responseTwo.data.data);
  //         })
  //       )
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   }
  // }, [selectedBusiness, refetchData]);

  // useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get(`https://publications.apimachine.com/publisher/`)
  //     .then(({ data }) => {
  //       setAllAuthors(data.data);
  //       setLoading(false);
  //     });
  // }, [selectedBusiness]);

  const validate = () => {
    if (
      colouredIcon &&
      name &&
      desc &&
      link
    ) {
      addNewStory();
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  };

  const addNewStory = () => {
    setLoading(true);
    setStep("loading")

    axios
      .post(`https://publications.apimachine.com/story/new`, {
        web_story_id: selectedStory?._id,
        name: name,
        email: bankerEmail,
        image: colouredIcon,
        desc: desc,
        link_to_article: link
      })
      .then(({ data }) => {
        if (data.status === false) {
          if (data.message === "jwt expired") {
            setStep("Token Expired");
          } else {
            toast.error(data.message || "API Error");
          }
          toast.success(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
        setTabSelected("Web Stories");
        setStep("");
        setPath([]);
        setStorySubDrawer(false);
        setRefreshStories(true);
      });
  };


  const conditionalSteps = () => {
    switch (step) {
      case "loading":
        return fullHeightDrawer(`Add ${name} to ${selectedStory?.name}`);

      case "success":
        return (
          <div
            className="assetDispText"
            style={{
              fontSize: "18px",
              fontWeight: "600",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              height: "60vh",
              padding: "0px 20px",
            }}
          >
            You have successfully added {name} to {selectedStory.name}. You will be redirected to the updated web stories template page now.
            {/* Publisher Added/Removed successfully to {selectedBusiness?.name}{" "}
            Publication */}
          </div>
        );

      case "Token Expired":
        return (
          <>
            <div className="sidebarTitle">Token Expired. Login Again.</div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <img src={lock} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Logout</div>
            </div>
          </>
        );

      case "Add Story":
        return (
          <>
            <div className="scrollstory">
              <div className="storyname" style={{ paddingTop: "20px" }}>
                Name
              </div>
              <div className="StoryInput">
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  className="textStory"
                  placeholder="Enter Name..."
                />
              </div>
              {/* <div className="storyname" style={{ paddingTop: "20px" }}>
              Email
            </div>
            <div className="StoryInput">
              <input
                value={bankerEmail}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                className="textStory"
                placeholder="Enter Email..."
              />
            </div> */}
              <div className="storyname" style={{ paddingTop: "20px" }}>
                Description
              </div>
              <div className="StoryInput">
                <input
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                  type="text"
                  className="textStory"
                  placeholder="Enter Description..."
                />
              </div>
              <div className="storyname" style={{ paddingTop: "20px" }}>Upload Image</div>
              <div>
                <div
                  className="filesUpload"
                  style={{ justifyContent: "flex-start" }}
                >
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(colouredIcon)}`}
                      src={
                        colouredIconLoading
                          ? loadingGif
                          : colouredIcon || cloudUploadIcon
                      }
                      alt=""
                      style={{ width: "40px" }}
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(
                          e,
                          setColouredIcon,
                          setColouredIconLoading
                        );
                      }}
                      accept="image/*"
                    />
                    <div
                      className="text"
                      style={{ fontWeight: "700", fontSize: "12px" }}
                    >
                      Colored Icon
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>
              </div>
              <div className="storyname" style={{ paddingTop: "20px" }}>
                Link
              </div>
              <div className="StoryInput">
                <input
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                  type="text"
                  className="textStory"
                  placeholder="Enter Link..."
                />
              </div>
            </div>
            <div className="bottomSubmit"
              onClick={() => validate()}
            >
              <p className="btm-text">Add Story</p>
            </div>
          </>
        );

      case "Edit Story":
        return (
          <>
            <div className="sidebarCard" onClick={() => {
              setStep("Edit Name");
              setPath([...path, "Edit Name"]);
              setTheTitle("Edit Name")
            }}>
              <div>Name</div>
            </div>
            <div className="sidebarCard" onClick={() => {
              setStep("Edit Description");
              setPath([...path, "Edit Description"]);
              setTheTitle("Edit Description")
            }}>
              <div>Description</div>
            </div>
            <div className="sidebarCard" onClick={() => {
              setStep("Edit Icon");
              setPath([...path, "Edit Icon"]);
              setTheTitle("Edit Icon")
            }}>
              <div>Icon</div>
            </div>
          </>
        )
      case "Edit Name":
        return (
          <>
            <div className="sidebarInputBox">
              <div>{selectedStory?.name}</div>
            </div>
            <div className="breakLine">
              <div className="horizantalLine"></div>
              &nbsp;&nbsp;&nbsp;<div>New</div>&nbsp;&nbsp;&nbsp;
              <div className="horizantalLine"></div>
            </div>
            <div className="sidebarInputBox">
              <input type="text" value={newName} className="inputBox" placeholder="Enter New Name" onChange={(e) => setNewName(e.target.value)} />
            </div>
            <div className="bottomSubmit" onClick={() => WebStoryValidate("name")}>
              <p className="btm-text">Submit Edit</p>
            </div>
          </>
        );

      case "Edit Description":
        return (
          <>
            <div className="sidebarInputBox">
              <div>{selectedStory?.desc}</div>
            </div>
            <div className="breakLine">
              <div className="horizantalLine"></div>
              &nbsp;&nbsp;&nbsp;<div>New</div>&nbsp;&nbsp;&nbsp;
              <div className="horizantalLine"></div>
            </div>
            <div className="sidebarInputBox">
              <input type="text" value={newDesc} className="inputBox" placeholder="Enter New Description" onChange={(e) => setNewDesc(e.target.value)} />
            </div>
            <div className="bottomSubmit" onClick={() => WebStoryValidate("desc")}>
              <p className="btm-text">Submit Edit</p>
            </div>
          </>
        );

      case "Edit Icon":
        return (
          <>
            <div>
              <img src={selectedStory?.icon} alt="" className="category-img" />
            </div>
            <div className="breakLine">
              <div className="horizantalLine"></div>
              &nbsp;&nbsp;&nbsp;<div>New</div>&nbsp;&nbsp;&nbsp;
              <div className="horizantalLine"></div>
            </div>
            <div className="bottomSubmit" onClick={() => WebStoryValidate("icon")}>
              <p className="btm-text">Submit Edit</p>
            </div>
            <label className="fileInp icon centerIco">
              <img
                className={`${Boolean(colouredIcon)} centerImg`}
                src={
                  colouredIconLoading
                    ? loadingGif
                    : colouredIcon || UploadIconImg
                }
                alt=""
                style={{ width: "40px" }}
              />
              <input
                type="file"
                onChange={(e) => {
                  uploadImage(
                    e,
                    setColouredIcon,
                    setColouredIconLoading
                  );
                }}
                accept="image/*"
              />
              {/* <div
                          className="text"
                          style={{ fontWeight: "700", fontSize: "12px" }}
                        >
                          Colored Icon
                        </div> */}
              <div className="hovTxt">
                Upload
                <br />
                New
              </div>
            </label>
          </>
        );

        case "Delete Template":
          return (
            <>
              <div className="sidebarTitle">
                Are you sure you want to delete this template?
              </div>
              <div
                className="sidebarCard"
                onClick={() => {
                  setStep("loading");
                  setPath([...path, "loading"]);
                  handleDeletePub();
                }}
              >
                {/* <img
                  src={yesIcon}
                  alt=""
                  className="coin"
                  style={{ width: "25px", height: "25px" }}
                /> */}
                <div>Yes I Am</div>
              </div>
              <div
                className="sidebarCard"
                onClick={() => {
                  setPath([]);
                  setTheTitle("Select An Action")
                  setStep("default");
                }}
              >
                {/* <img
                  src={noIcon}
                  alt=""
                  className="coin"
                  style={{ width: "25px", height: "25px" }}
                /> */}
                <div>Never Mind</div>
              </div>
            </>
          );




      default:
        return (
          <>
            <div className="sidebarTitle">What Would You Like To Do? </div>
            <div
              className="sidebarCard"
              onClick={() => {
                setStep("Add Story");
                setPath([...path, "Add Story"]);
                setTheTitle("Add Story");
              }}
            >
              {/* <img
                src={addIcon}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
              <div>Add Story</div>
            </div>
            <div
              className="sidebarCard"
              onClick={() => {
                setStep("Edit Story");
                setPath([...path, "Edit Story"]);
                setTheTitle("Edit Story");
              }}
            >
              {/* <img
                src={addIcon}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              /> */}
              <div>Edit Story</div>
            </div>
            <div
              className="sidebarCard"
              onClick={() => {
                setStep("Delete Template");
                setPath([...path, "Delete Template"]);
                setTheTitle("Delete Template");
              }}
            >
              <div>Delete Template</div>
            </div>
            {/* <div
              className="sidebarCard"
              onClick={() => {
                setStep("Add To Publication");
                setPath([...path, "Add To Publication"]);
              }}
            >
              <img
                src={editPub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ paddingLeft: "10px" }}>
                Add Authors To Publication
              </div>
            </div> */}
            {/* <div
              className="sidebarCard"
              onClick={() => {
                setStep("Remove From Publication");
                setPath([...path, "Remove From Publication"]);
              }}
            >
              <img
                src={editPub}
                alt=""
                className="coin"
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ paddingLeft: "10px" }}>
                Remove Authors From Publication
              </div>
            </div> */}
          </>
        );
    }
  };

  return (
    <>
      <div
        style={{
          padding: "30px",
          height: "90px",
        }}
      >
        {loading ||
          step === "loading" ||
          step === "Token Expired" ||
          step == "success" ? (
          ""
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <div style={{ fontSize: "22px" }}>
                    {theTitle}
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="backButton icon-imgs close-img"
                  onClick={(e) => {
                    setPath([]);
                    setStorySubDrawer(false);
                    setStep("");
                    setTheTitle("Select An Action")
                  }}
                  style={{
                    marginLeft: "20px",
                  }}
                >
                  <img src={close} alt="" />
                </div>
              </div>
              {path.length > 0 ? (
                <div className="back-btm-button" onClick={pathAdjust}>
                  <p className="btm-text">Go Back</p>
                  {/* <button className="btm-text">go back</button> */}
                </div>
              ) : (
                ""
              )}
            </div>

            <div
              className="breadcrumb"
              style={{ display: "flex", flexDirection: "row" }}
            >

            </div>
          </div>
        )}
        {conditionalSteps()}

      </div>

      <ToastContainer />
    </>
  );
};

export default Actions;
