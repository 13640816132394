import React, { useEffect } from "react";
import { useContext } from "react";
import { GlobalContex } from "../globalContex";
// import AddNewCategory from "./AddNewCategory";
// import AddNewSubCategory from "./AddNewSubCategory";
// import AddNewFilter from "./AddNewFilter";
// import AddNewBrand from "./AddNewBrand";
import GlobalSidebar from "../globalComponents/GlobalSidebar";
import LocalSidebar from "../globalComponents/LocalSidebar";
import TabsLayout from "../globalComponents/TabsLayout";
import MobileMenu from "../globalComponents/MobileMenu";
// import AddNewProduct from "./AddNewProduct";
// import NavBar from "../globalComponents/NavBar";
import GetProductForm from "./GetProductForm"
import GetCategoryForm from "./GetCategoryForm"
import GetSubCategoryForm from "./SubCategoryForm"
import GetFilterForm from "./GetFilterForm";
import GetBrandForm from "./GetBrandForm";
import GlobalGetForm from "./GlobalGetFormData";



const GetFormData = () => {
    const {
        selectedForm,
        setSelectedForm,
        collapse,
        setTabs,
        setSelectedTab,
        selectedTab,
        loginData,
        setBankerEmail,
        modalOpen,
        setModalOpen,
        localMenu,
        globalMenu,
        mcbMenu,
        mallsMenu,
        setDispdraw,
        showMobileMenu,
        tabSelected,
        setTabSelected,
        selectedAddNew,
        selectedFormData
    } = useContext(GlobalContex);

    useEffect(() => {
        setSelectedForm(selectedAddNew)
    }, [selectedAddNew])

    // const openside = (e) => {
    //   themail = e.currentTarget.id;
    //   value.setselectemail(e.currentTarget.id);
    //   value.setdispdraw("TranslateX(0%)");
    //   value.settriggerevent("none");
    // };

    const conditionalPages = () => {
        switch (selectedFormData) {
            case "Product":
                return <GlobalGetForm theOrder={true} theStatus={true} theSelectField={false} theExternalID={true}/>
            case "Categories":
                return <GlobalGetForm theOrder={true} theStatus={true} theSelectField={false} theExternalID={false}/>
            case "Sub-Categories":
                return <GlobalGetForm theOrder={true} theStatus={true} theSelectField={true} theExternalID={false}/>
            case "Filters":
                return <GlobalGetForm theOrder={false} theStatus={false} theSelectField={false} theExternalID={false}/>
            case "Brands":
                return <GlobalGetForm theOrder={false} theStatus={false} theSelectField={false} theExternalID={false}/>
            default:
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: "70vh",
                        }}
                    >
                        Form Data Coming Soon
                    </div>
                );
        }
    };
    return <>
        <>
            <div className="desktopWrapper">
                <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
                    <GlobalSidebar globalMenu={globalMenu} />
                    <LocalSidebar localMenu={mallsMenu} />
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <TabsLayout />
                        {/* <NavBar/> */}
                        <div style={{}}>{conditionalPages()}</div>
                    </div>
                </div>
            </div>
            {showMobileMenu ? (
                <MobileMenu />
            ) : (
                <div className="mobileWrapper">
                    <TabsLayout />
                    <div style={{}}>{conditionalPages()}</div>
                </div>
            )}
        </>
    </>
};

export default GetFormData;
