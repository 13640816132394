// Package Imports
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import axios from "axios";

// CSS Imports

// Context Imports
import { GlobalContex } from "../../globalContex";

// Image Imports

//cPage/Component Imports
import MarketsVerse from "../../Apps/MarketsVerse";
import MyCryptoBrand from "../../Apps/MyCryptoBrand";
import Banker from "../../Apps/Banker";
import Capitalized from "../../Apps/Capitalized";
import Creating from "../../Apps/Creating";
import FundManagers from "../../Apps/FundManagers";
import OTCDesks from "../../Apps/OTCDesks";
import Terminals from "../../Apps/Terminals";
import Publishers from "../../Apps/Publishers";
import Authors from "../../Apps/Authors";
import Classrooms from "../../Apps/Classrooms";
import MyApps from "../../Apps/MyApps";
import Malls from "../../Apps/Malls";
import AddNewForm from "../../AddNewForms"
import GetFormData from "../../GetFormData";

const HomePage = () => {
  const { loginData, setBankerTag, setBanker, AddNewField, setAddNewField, TheFormData } = useContext(GlobalContex);

  useEffect(() => {
    axios
      .get(
        "https://teller2.apimachine.com/banker/details",

        {
          headers: {
            email: loginData.user.email,
            token: loginData.idToken,
          },
        }
      )
      .then((res) => {
        if (!localStorage.getItem("bankerTag")) {
          console.log(res.data, "jhffjy");
          localStorage.setItem("bankerTag", res.data.data.bankerTag);
          setBankerTag(res.data.data.bankerTag);

          localStorage.setItem("banker", JSON.stringify(res.data.data));
          setBanker(res.data.data);
        }
      });
  }, []);

  if (AddNewField) {
    return <AddNewForm />
  }

  if (TheFormData) {
    return <GetFormData />
  }


  return (
    <>
      <Routes>
        <Route path="MarketsVerse" element={<MarketsVerse />} />
        <Route path="MyCryptoBrand" element={<MyCryptoBrand />} />
        <Route path="CapitalMarkets" element={<Banker />} />
        <Route path="Capitalized" element={<Capitalized />} />
        <Route path="Creating" element={<Creating />} />
        <Route path="FundManagers" element={<FundManagers />} />
        <Route path="OTCDesks" element={<OTCDesks />} />
        <Route path="Terminals" element={<Terminals />} />
        <Route path="Publishers" element={<Publishers />} />
        <Route path="Authors" element={<Authors />} />
        <Route path="Classrooms" element={<Classrooms />} />
        <Route path="MyApps" element={<MyApps />} />
        <Route path="Admin" element={<Malls />} />
      </Routes>
    </>
  );
};

export default HomePage;
