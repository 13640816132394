import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewLogisticPincode = () => {
    const tabs = ["Add Logistic Pincode"];
    const [name, setName] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [allStates, setAllStates] = useState([])
    const [allCities, setAllCities] = useState([])
    const [allAreas , setAllAreas] = useState([])
    const [selectedState, setSelectedState] = useState("")
    const [selectedCity, setSelectedCity] = useState("")
    const [selectedArea , setSelectedArea] = useState("")



    const { tabSelected, setTabSelected, setAddNewField,  logPincodeTrigger , setLogPinCodeTrigger, selectedBusiness, cityTrigger, setCityTrigger } = useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add  Logistic Pincode");
        console.log(selectedBusiness?._id, "businessDetail")
        axios.get(
            `https://frissly.apimachine.com/api/business/logistics/get/state?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setAllStates(data.data);
            }
        });
        axios.get(
            `https://frissly.apimachine.com/api/business/logistics/get/city?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setAllCities(data.data);
            }
        });
        axios.get(
            `https://frissly.apimachine.com/api/business/logistics/get/area?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setAllAreas(data.data);
            }
        });
    }, []);

    useEffect(() => {
        if (logPincodeTrigger) {
            if (name) {
                setApiLoading(true)
                let body = {
                    businessId: selectedBusiness?._id,
                    stateId: !selectedState ? allStates?.[0]?._id : selectedState,
                    cityId: !selectedCity ? allCities?.[0]?._id : selectedCity,
                    areaId : !selectedArea ? allAreas?.[0]?._id : selectedArea,
                    pincode: name
                }
                console.log(body, "fwetwe")
                if (body.pincode) {
                    axios.post("https://frissly.apimachine.com/api/business/logistics/create/pincode", body)
                        .then(({ data }) => {
                            if (data.status) {
                                toast.success("pincode Added Successfully")
                                setTimeout(() => {
                                    setAddNewField(false)
                                    // navigate("/Admin")
                                    setTabSelected("Staff")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setLogPinCodeTrigger(false)
    }, [logPincodeTrigger])

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
    const uploadImage = async (e, setUpdatedData, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf(".")
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append("files", file);

        let { data } = await axios.post(
            `https://publications.apimachine.com/article/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        setUpdatedData(data[0].urlName);
        setLoading(false);
    };



    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Add Name...." />
                        {/* <p className="categoryName topmargin">Icon</p>
                        <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                            <label
                                className="fileInp icon"
                                style={{
                                    flex: "0 0 100%",
                                    maxWidth: "103px",
                                    height: "100px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}
                            >
                                <img
                                    style={{ width: updatedData ? "100px" : "46px", height: updatedData ? "100px" : "46px" }}
                                    className={`${Boolean(updatedData)}`}
                                    src={
                                        imgLoading
                                            ? loadingGif
                                            : updatedData || cloudUploadIcon
                                    }
                                    alt=""
                                />
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        uploadImage(e, setUpdatedData, setImgLoading);
                                    }}
                                    accept="image/*"
                                />

                                <div className="hovTxt">
                                    Upload
                                    <br />
                                    New
                                </div>
                            </label>
                        </div> */}
                        {/* <p className="categoryName">Description</p>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Example: Cookies" ></textarea>
                        <p className="categoryName topmargin">Short Form</p>
                        <input type="text" value={shortForm} onChange={(e) => setShortForm(e.target.value)} className="inputStyle" placeholder="Add Short Form...." /> */}
                        <p className="categoryName topmargin">Select State</p>
                        <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                                {allStates.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div>
                        <p className="categoryName topmargin">Select City</p>
                        <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                                {allCities.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div>
                        <p className="categoryName topmargin">Select Area</p>
                        <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={selectedArea} onChange={(e) => setSelectedArea(e.target.value)}>
                                {allAreas.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div>
                        {/* <p className="categoryName topmargin">GST code</p>
                        <input type="text" value={gstCode} onChange={(e) => setGstCode(e.target.value)} className="inputStyle" placeholder="Add Gst Code...." /> */}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewLogisticPincode;