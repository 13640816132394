import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewFilter = () => {
    const tabs = ["Add Filter"];
    const [updatedData, setUpdatedData] = useState("");
    const [imgLoading, setImgLoading] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState("")
    const [coverLoading, setCoverLoading] = useState("")
    const [additionalLoading, setAdditionalLoading] = useState(false)
    const [additionalPhoto, setAdditionalPhoto] = useState("")
    const [metaArr, setMetaArr] = useState([])
    const [metaTag, setMetaTag] = useState("")
    const [altTagsArr, setAltTagsArr] = useState([])
    const [altTags, setAltTags] = useState("")
    const [imageArr, setImageArr] = useState([])
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [categoryNumber, setCategoryNumber] = useState("")
    const [pageTitle, setPageTitle] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [status, setStatus] = useState("online")
    const [colourCode, setColourCode] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [username, setUserName] = useState("")
    const [fetchUsers, setFetchUsers] = useState([])
    const navigate = useNavigate();



    const { tabSelected, setTabSelected, filterTrigger, setFilterTrigger, setAddNewField, selectedBusiness } =
        useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add Filter");
        axios.get(
            `https://frissly.apimachine.com/api/business`
        ).then(({ data }) => {
            if (data.status) {
                setFetchUsers(data.data);
                setUserName(data?.data[0]?.businessusername)
            }
        });
    }, []);

    useEffect(() => {
        if (filterTrigger) {
            if (name) {
                setApiLoading(true)
                let body = {
                    name: name,
                    businessusername: selectedBusiness?.businessusername,
                    description,
                    icon: updatedData,
                    coverPhoto,
                    colourCode,
                    altImagesTags: altTagsArr,
                    metaTags: metaArr,
                    pageTitle,
                    // categoryOrder: categoryNumber,
                    MetaDescription: metaDescription,
                    // toDisplay: status,
                    images: imageArr
                }
                console.log(body, "fwetwe")
                if (body.name || body.businessusername) {
                    axios.post("https://frissly.apimachine.com/api/filter/create", body)
                        .then(({ data }) => {
                            if (data.status) {
                                toast.success("Filter Added Successfully")
                                setTimeout(() => {
                                    setAddNewField(false)
                                    navigate("/Admin")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setFilterTrigger(false)
    }, [filterTrigger])

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    const uploadImage = async (e, setUpdatedData, setLoading, theText) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf(".")
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append("files", file);

        let { data } = await axios.post(
            `https://publications.apimachine.com/article/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        setUpdatedData(data[0].urlName);
        setLoading(false);
        console.log(theText)
        if (theText === "additionalImg") {
            setImageArr([...imageArr, data[0].urlName])
        }
    };

    const addMetaTag = (metaTag) => {
        if (metaTag === "") return
        setMetaArr([...metaArr, metaTag])
        console.log(metaArr, "metaArr")
        setMetaTag("")
    }

    const removeTag = (item) => {
        setMetaArr(metaArr.filter((tag) => tag !== item));
    };

    const removeImages = (item) => {
        setImageArr(imageArr.filter((tag) => tag !== item));
    };


    const addAltTag = (tag) => {
        if (tag === "") return
        setAltTagsArr([...altTagsArr, tag])
        setAltTags("")
    }

    const removeAltTag = (item) => {
        setAltTagsArr(altTagsArr.filter((keyword) => keyword !== item));
    };


    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Example: Cookies" />
                        {/* <p className="categoryName topmargin">Select Business User</p> */}
                        {/* <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={username} onChange={(e) => setUserName(e.target.value)}>
                                {fetchUsers.map((e) => {
                                    return <>
                                        <option value={e.businessusername}>{e?.businessusername}</option>
                                    </>
                                })}
                            </select>
                        </div> */}
                        <p className="categoryName topmargin">Description</p>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Example: Cookies" ></textarea>
                        {/* <p className="categoryName topmargin">Category Order</p>
                        <input type="number" value={categoryNumber} onChange={(e) => setCategoryNumber(e.target.value)} className="inputStyle" placeholder="Example: 1" /> */}
                    </div>
                    <br />
                    <div className="basicForm">
                        <p className="categoryTitle">SEO Details</p>
                        <p className="categoryName">Page title</p>
                        <input type="text" value={pageTitle} onChange={(e) => setPageTitle(e.target.value)} className="inputStyle" placeholder="Example: Cookies" />
                        <p className="categoryName topmargin">Page Meta Description</p>
                        <textarea value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Example: Cookies" ></textarea>
                        <p className="categoryName topmargin">Metatags</p>
                        {metaArr.map((item, index) => {
                            return <><div style={{ display: "flex" }}>
                                <input type="text" value={item} className="inputStyle" placeholder="Example: Food" disabled style={{ opacity: 0.5 }} />
                                &nbsp;&nbsp;
                                <button className="removeBtn" onClick={() => removeTag(item)}>Remove</button>
                            </div>
                                <br />
                            </>
                        })}
                        &nbsp;
                        <div style={{ display: "flex" }}>
                            <input type="text" value={metaTag}
                                onChange={(e) => setMetaTag(e.target.value)} className="inputStyle" placeholder="Example: Food" />
                            &nbsp;&nbsp;
                            <button className="addBtn" onClick={() => addMetaTag(metaTag)} >Save</button>
                        </div>
                        <p className="categoryName topmargin">AltTags</p>
                        {altTagsArr.map((item, index) => {
                            return (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <input type="text" value={item} className="inputStyle" placeholder="Example: Food" disabled style={{ opacity: 0.5 }} />
                                        &nbsp;&nbsp;
                                        <button className="removeBtn" onClick={() => removeAltTag(item)} >Remove</button>
                                    </div>
                                    <br />
                                </>
                            )
                        })}
                        &nbsp;
                        <div style={{ display: "flex" }}>
                            <input type="text" value={altTags}
                                onChange={(e) => setAltTags(e.target.value)} className="inputStyle" placeholder="Example: Food" />
                            &nbsp;&nbsp;
                            <button className="addBtn" onClick={() => addAltTag(altTags)}>Save</button>
                        </div>
                    </div>
                    {/* <br />
                    <div className="basicForm">
                        <p className="categoryTitle">Status</p>
                        <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={status} onChange={(e) => setStatus(e.target.value)}>
                                <option value="online">Online</option>
                                <option value="offline">Offline</option>
                            </select>
                        </div>
                    </div> */}
                </div>
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Cosmetic Details</p>
                        <p className="categoryName">Icon</p>
                        <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                            <label
                                className="fileInp icon"
                                style={{
                                    flex: "0 0 100%",
                                    maxWidth: "103px",
                                    height: "100px",
                                    borderRadius: "10px",
                                    background: "white"
                                }}
                            >
                                <img
                                    style={{ width: updatedData ? "100px" : "46px", height: updatedData ? "100px" : "46px" }}
                                    className={`${Boolean(updatedData)}`}
                                    src={
                                        imgLoading
                                            ? loadingGif
                                            : updatedData || cloudUploadIcon
                                    }
                                    alt=""
                                />
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        uploadImage(e, setUpdatedData, setImgLoading);
                                    }}
                                    accept="image/*"
                                />

                                <div className="hovTxt">
                                    Upload
                                    <br />
                                    New
                                </div>
                            </label>
                        </div>
                        <p className="categoryName topmargin">Cover Photo</p>
                        <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                            <label
                                className="fileInp icon"
                                style={{
                                    flex: "0 0 100%",
                                    maxWidth: "100%",
                                    height: "170px",
                                    borderRadius: "10px",
                                    background: "white",
                                    position: "relative",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}
                            >
                                <img
                                    style={{ width: coverPhoto ? "100%" : "46px", height: coverPhoto ? "170px" : "46px", objectFit: coverPhoto ? "cover" : "", border: coverPhoto ? "1px solid #E7E7E7" : "", borderRadius: "10px", marginTop: coverPhoto ? "22px" : "" }}
                                    className={`${Boolean(coverPhoto)}`}
                                    src={
                                        coverLoading
                                            ? loadingGif
                                            : coverPhoto || cloudUploadIcon
                                    }
                                    alt=""
                                />
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        uploadImage(e, setCoverPhoto, setCoverLoading);
                                    }}
                                    accept="image/*"
                                />

                                <div className="hovTxt">
                                    Upload
                                    <br />
                                    New
                                </div>
                                <br />
                                <p className="imgText" style={{ display: coverPhoto ? "none" : "" }}><span className="highlightText">Click to Upload</span> or drag and drop</p>
                            </label>
                        </div>
                        <br />
                        <p className="categoryName topmargin">Colour Code</p>
                        <div style={{ position: "relative", display: "flex" }}>
                            <input value={colourCode} onChange={(e) => setColourCode(e.target.value)} type="text" className="inputStyle" placeholder="Example: 00000" />
                            <div className="colourBox" style={{ background: colourCode }}></div>
                        </div>
                    </div>
                    <br />
                    <div className="basicForm">
                        <p className="categoryTitle">Additional Media</p>
                        <p className="categoryName topmargin">Accept’s Images & Videos</p>
                        {imageArr.map((e, index) => {
                            return <>
                                <div className="fitImage">
                                    <img src={CloseIcon} alt="close" className="theCloseIcon" onClick={() => removeImages(e)} />
                                    <img src={e} alt="images" className="additionalImages" />
                                </div>
                                <br />
                            </>
                        })}
                        <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                            <label
                                className="fileInp icon"
                                style={{
                                    flex: "0 0 100%",
                                    maxWidth: "100%",
                                    height: "170px",
                                    borderRadius: "10px",
                                    background: "white",
                                    position: "relative",
                                    alignItems: "center",
                                    flexDirection: "column"
                                }}
                            >
                                <img
                                    style={{ width: "46px", height: "46px" }}
                                    className={`${Boolean(additionalPhoto)}`}
                                    src={
                                        additionalLoading ? loadingGif : cloudUploadIcon
                                    }
                                    alt=""
                                />
                                <input
                                    type="file"
                                    onChange={(e) => {
                                        uploadImage(e, setAdditionalPhoto, setAdditionalLoading, "additionalImg");
                                    }}
                                    accept="image/*"
                                />

                                <div className="hovTxt">
                                    Upload
                                    <br />
                                    New
                                </div>
                                <br />
                                <p className="imgText"><span className="highlightText">Click to Upload</span> or drag and drop</p>
                            </label>
                        </div>
                        <br />
                        <br />
                        {/* <button className="addBtn" style={{ width: "150px" }}>Add Another</button> */}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewFilter;