import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
// import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const OrdersDrawer = ({ selectedProduct, setSelectedProduct }) => {
    const [step, setStep] = useState("default")
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [selectStatus, setSelectStatus] = useState("")
    const [quantity, setQuantity] = useState("")
    const [refundStatus, setRefundStatus] = useState(false)




    const { bankerEmail, setTabSelected, productSubDrawer, setProductSubDrawer, login, loginData,
        refetchProduct, setRefechProduct, productDrawerOpen, setProductsDrawerOpen, selectedBusiness, ordersSubDrawer, setOrdersSubDrawer,
        selectedSubOrder, selectedOrder, setSelectedSubOrder } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Change Product Quantity");
        setPath(["Change Product Quantity"]);
    }, [ordersSubDrawer]);


    let StatusFields = [
        {
            name: "Payment received",
            value: "paymentRecieved"
        },
        {
            name: "Payment cancelled",
            value: "paymentCancelled"
        },
        {
            name: "Order confirmed",
            value: "confirmedOrder"
        },
        {
            name: "Payment received",
            value: "paymentRecieved"
        },
        {
            name: "Manufacturing",
            value: "manufacuring"
        },
        {
            name: "Dispatch ready",
            value: "dispactchRead"
        },
        {
            name: "Dispatched",
            value: "dispactch"
        },
        {
            name: "Delivered",
            value: "delivered"
        },
    ]

    // useEffect(() => {
    //     fetchCurrentSubCategory()
    // }, [currentSubCategory])

    // useEffect(() => {
    //   console.log(ActionBtn, "ActionBtn")
    //   if (ActionBtn === "Actions") {
    //     setStep("Actions")
    //   } else{
    //     setStep("default")
    //   }
    // }, [step, ActionBtn])

    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    const updateStatusField = () => {
        let body = {
            transactionId: selectedSubOrder?._id,
            businessusername: selectedBusiness?.businessusername,
            paymentStatus: selectStatus?.value
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Status...`)
        Axios.put(`https://frissly.apimachine.com/api/order/${selectedSubOrder?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg("You have successfully update status. You will now be redirected to your updated orders list.")
                    setTabSelected("Orders")
                    setStep("default");
                    ordersSubDrawer(false);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }


    const ActionStatus = () => {
        let body = {
            token: localStorage.getItem("TokenId"),
            refund: refundStatus,
            reduceProducts: [{
                product: selectedSubOrder?.product?._id,
                quantity: quantity,
                dimentionId: selectedSubOrder?.dimentionId
            }]
        }
        console.log(body, "submitted-data")
        setStep("loading")
        setloadingtext(`Adding New Price For ${selectedProduct?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/order/edit/${selectedOrder._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated status for ${selectedSubOrder?.product?.name}. You will now be redirected to your updated orders list.`)
                    setTimeout(() => {
                        setTabSelected("Orders")
                        setStep("default");
                        setOrdersSubDrawer(false);
                        setQuantity("")
                        setRefundStatus(false)
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }












    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );

            case "Confirmation":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={(e) => updateStatusField()}
                            >
                                <div>Yes Confirm</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={(e) => {
                                    setStep("Update order status");
                                    setPath(["Action", "Update order status"]);
                                }}
                            >
                                <div>Nevermind</div>
                            </div>
                        </div>
                    </>
                )

            case "Update order status":
                return (
                    <>
                        <div className="sidebarTitle">What is the new status for this order?</div>
                        <div className="sidebarScroll">
                            {StatusFields.map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => {
                                        setSelectStatus(item)
                                        setStep("Confirmation");
                                        setPath([...path, "Confirmation"]);
                                    }}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}

                        </div>
                    </>
                )
            default:
                return (
                    <div className="sidebarScroll">
                        <div className="sidebarTitle">Current Quantity</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={selectedSubOrder?.quantity} placeholder="current quantity" disabled style={{ fontWeight: 800 }} />
                        </div>
                        <div className="sidebarTitle">New quantity</div>
                        <div className="sidebarBox">
                            <input type="text" className="inputBox" value={quantity} onChange={(e) => setQuantity(e.target.value)} placeholder="0" />
                        </div>
                        <div className="sidebarTitle">Refund Status</div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div className="theSidebarButton" style={{ background: refundStatus ? "rgb(102, 105, 176)" : "", color: refundStatus ? "white" : "" }} onClick={() => setRefundStatus(true)}>Initiate Refund</div>
                            <div className="theSidebarButton" style={{ background: !refundStatus ? "rgb(102, 105, 176)" : "", color: !refundStatus ? "white" : "" }} onClick={() => setRefundStatus(false)}>No Refund</div>
                        </div>
                        <div className="theBottomSubmitCard" onClick={() => ActionStatus()}>Submit</div>
                        <div className="theBottomCard" onClick={() => setOrdersSubDrawer(false)}>Go Back</div>
                    </div>
                )
        }
    }



    return (
        <div
            className={ordersSubDrawer ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* <img
                  src={
                    selectedBusiness?.profile_pic
                      ? selectedBusiness?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {selectedSubOrder?.product?.name}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* {path.length > 1 ? (
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => handleBackStep()}
                  >
                    <img src={back} alt="" />
                  </div>
                ) : (
                  ""
                )} */}
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            setOrdersSubDrawer(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default OrdersDrawer;
