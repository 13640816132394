import React from "react";
import { useContext } from "react";
import GlobalSidebar from "../../globalComponents/GlobalSidebar";
import LocalSidebar from "../../globalComponents/LocalSidebar";
import { GlobalContex } from "../../globalContex";

import TabsLayout from "../../globalComponents/TabsLayout";

import MobileMenu from "../../globalComponents/MobileMenu";
import "../commonAppCss.scss";
import Stores from "./Stores";
import Products from "./Products";
import CRM from "./CRM";
import People from "./People";
import Delivery from "./Delivery";
import Promotions from "./Promotions";
import NewLists from "./New Lists";
import MachineAndVehicle from "./Machine and Vehicle";
import Orders from "./Orders";
const Malls = () => {
  const {
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    localMenu,
    globalMenu,
    mcbMenu,
    mallsMenu,
    setDispdraw,
    showMobileMenu,
    tabSelected,
    setTabSelected,
    selectedAddNew
  } = useContext(GlobalContex);

  // const openside = (e) => {
  //   themail = e.currentTarget.id;
  //   value.setselectemail(e.currentTarget.id);
  //   value.setdispdraw("TranslateX(0%)");
  //   value.settriggerevent("none");
  // };



  const conditionalPages = () => {
    switch (selectedTab?.menuName) {
      case "Categories":
        return (
          <Stores tabSelected={tabSelected} setTabSelected={setTabSelected} />
        );
      case "Products":
        return <Products />
      case "CRM":
        return <CRM />
      case "People":
        return <People />
      case "Delivery":
        return <Delivery />
      case "Promotions":
        return <Promotions />
      case "New Lists":
        return <NewLists />
      case "Machine Vehicle":
        return <MachineAndVehicle />
      case "Orders":
        return <Orders/>
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
        break;
    }
  };

  return (
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={mallsMenu} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabsLayout />
            <div style={{}}>{conditionalPages()}</div>
          </div>
        </div>
      </div>
      {showMobileMenu ? (
        <MobileMenu />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}
      {/* {modalOpen && (
        <AdminModal
          onClose={() => setModalOpen(false)}
          onSuccess={() => setModalOpen(false)}
        />
      )} */}
    </>
  );
};

export default Malls;
