import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";

import loadingGif from "../../../static/images/loading.gif";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAllergies, faCaretDown } from "@fortawesome/free-solid-svg-icons";

// import LoadingAnimation from "../LoadingAnimation";
import { useNavigate } from "react-router-dom";
// import { selectLoginData } from "../../app/loginSlice";
import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";
import CountryList from "./CountryList";

// import "./numberInput.css";
import { GlobalContex } from "../../../globalContex";

import plus from "../../../static/images/globaldrawer/plus.svg";
import lock from "../../../static/images/icons/lock.svg";
// import MetaverseBrandList from "./MetaverseBrandList";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

const NewMcbBrand = ({ step, setStep, setMainMenu }) => {
  const navigate = useNavigate();

  const {
    loginData,
    bankerEmail,
    setGlobalMenuAdd,
    refetchBrandData,
    setRefetchBrandData,
  } = useContext(GlobalContex);

  const [loading, setLoading] = useState(false);

  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenAvailable, setTokenAvailable] = useState(null);

  //myCryptoBrand New Brand
  const [country, setCountry] = useState(null);
  const [brandName, setBrandName] = useState("");
  const [brandContactEmail, setBrandContactEmail] = useState(
    loginData.user.email
  );
  const [brandContactNumber, setBrandContactNumber] = useState("");
  const [brandEstd, setBrandEstd] = useState("");

  const [colouredIcon, setColouredIcon] = useState("");
  const [coverPhoto, setCoverPhoto] = useState("");

  const allMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  //logo loading states

  const [colouredIconLoading, setColouredIconLoading] = useState("");
  const [coverPhotoLoading, setCoverPhotoLoading] = useState("");

  const [primaryColour, setPrimaryColour] = useState("");
  const [website, setWebsite] = useState("");

  const [visibleSubmit, setVisibleSubmit] = useState(false);

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  const handleCheckTokenAvailability = () => {
    if (tokenAvailable === null && tokenSymbol !== "") {
      axios
        .get(
          `https://comms.globalxchange.io/coin/investment/path/get?token=${tokenSymbol}`
        )
        .then((res) => {
          if (res.data.status) {
            setTokenAvailable(false);
          } else {
            setTokenAvailable(true);
          }
        });
    } else {
      setTokenAvailable(null);
      setTokenSymbol("");
    }
  };

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          // // navigate("/ventures/Brands");
          // window.location.reload();
          setGlobalMenuAdd(false);
        }, 1000);
        setRefetchBrandData(!refetchBrandData);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                You Have Successfully Created the brand {brandName}. You Will Be
                Redirected To The Updated Brand List Automatically
              </div>
            </div>
          </div>
        );

      case "Token Expired":
        return (
          <>
            <div className="newConglomerate">
              <div className="succesView">
                <div className="labelItm" style={{ textAlign: "center" }}>
                  Token Expired. Login Again.
                </div>
              </div>
            </div>

            <div className="footerBtns">
              <div
              // onClick={(e) => handleBackStep()}
              >
                Go Back
              </div>
              <div
                onClick={(e) => {
                  localStorage.clear();
                  window.location.reload();
                }}
              >
                Logout
              </div>
            </div>
          </>
        );

      case "country":
        return (
          <div className="newConglomerate">
            <CountryList setCountry={setCountry} onClose={() => setStep("")} />
          </div>
        );

      // case "Metaverse Brand":
      //   return (
      //     <div className="newConglomerate">
      //       <MetaverseBrandList
      //         setBrand={setBrand}
      //         onClose={() => setStep("")}
      //       />
      //     </div>
      //   );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Select Brand Country */}
                <div className="name">Select Brand Country</div>
                {country?.name ? (
                  <div className="user" onClick={() => setStep("country")}>
                    <img className="dp" src={country?.image} alt="" />
                    <div className="userDetail" style={{ padding: "0px" }}>
                      <div className="name">{country?.name}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("country")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Create Brand Name */}
                <div className="name">Create Brand Name</div>
                <div className="inputWrap">
                  <input
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Brand Name..."
                  />
                </div>

                {/* Enter Brand Contact Email */}
                <div className="name">Enter Brand Contact Email</div>
                <div className="inputWrap">
                  <input
                    value={brandContactEmail}
                    onChange={(e) => setBrandContactEmail(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Brand Email...."
                  />
                </div>

                {/* Enter Brand Contact Number */}
                <div className="name">Enter Brand Contact Number</div>
                <div className="inputWrap">
                  <input
                    value={brandContactNumber}
                    onChange={(e) => setBrandContactNumber(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Brand Number...."
                  />
                </div>

                {/* When Was The Brand Founded? */}
                <div className="name">When Was The Brand Founded?</div>
                <div className="inputWrap">
                  <input
                    value={brandEstd}
                    onChange={(e) => setBrandEstd(e.target.value)}
                    type="date"
                    className="text"
                    // placeholder="Select Date"
                  />
                </div>

                {/* Enter Primary Colour Code */}
                <div className="name">Enter Primary Colour Code</div>
                <div className="inputWrap">
                  <input
                    value={primaryColour}
                    onChange={(e) => setPrimaryColour(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="#0000000...."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      background: primaryColour
                        ? "#" + primaryColour
                        : "#ffffff",
                      width: "68px",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>

                {/* Upload Logos */}
                <div className="name">Upload Logos</div>

                <div>
                  <div className="filesUpload" style={{}}>
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(colouredIcon)}`}
                        src={
                          colouredIconLoading
                            ? loadingGif
                            : colouredIcon || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: "40px" }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(
                            e,
                            setColouredIcon,
                            setColouredIconLoading
                          );
                        }}
                        accept="image/*"
                      />
                      <div
                        className="text"
                        style={{ fontWeight: "700", fontSize: "12px" }}
                      >
                        Colored Icon
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                    <label className="fileInp icon">
                      <img
                        className={`${Boolean(coverPhoto)}`}
                        src={
                          coverPhotoLoading
                            ? loadingGif
                            : coverPhoto || cloudUploadIcon
                        }
                        alt=""
                        style={{ width: "40px" }}
                      />
                      <input
                        type="file"
                        onChange={(e) => {
                          uploadImage(e, setCoverPhoto, setCoverPhotoLoading);
                        }}
                        accept="image/*"
                      />
                      <div
                        className="text"
                        style={{ fontWeight: "700", fontSize: "12px" }}
                      >
                        Cover Photo
                      </div>
                      <div className="hovTxt">
                        Upload
                        <br />
                        New
                      </div>
                    </label>
                    <label
                      className="fileInp icon"
                      style={{ visibility: "hidden" }}
                    ></label>
                  </div>
                </div>

                <div className="space"></div>
              </Scrollbars>
            </div>

            <div className="footerBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                onClick={() => validate()}
                style={{
                  fontWeight: 700,
                  opacity:
                    country &&
                    brandName &&
                    brandContactEmail &&
                    brandContactNumber &&
                    brandEstd &&
                    primaryColour &&
                    colouredIcon &&
                    coverPhoto
                      ? 1
                      : 0.3,
                }}
              >
                Submit Brand
              </div>
            </div>

            {/* <div className="ftBtns">
              <div
                className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}
              >
                Go Back
              </div>
              <div
                className={
                  country &&
                  brandName &&
                  brandContactEmail &&
                  brandContactNumber &&
                  brandEstd &&
                  primaryColour &&
                  colouredIcon &&
                  coverPhoto
                    ? "btnSubmit"
                    : "newField"
                }
                onClick={() => validate()}
                style={{
                  fontWeight: 700,
                  opacity:
                    country &&
                    brandName &&
                    brandContactEmail &&
                    brandContactNumber &&
                    brandEstd &&
                    primaryColour &&
                    colouredIcon &&
                    coverPhoto
                      ? 1
                      : 0.3,
                }}
              >
                Submit Brand
              </div>
            </div> */}
          </>
        );
    }
  }

  const validate = () => {
    if (
      country &&
      brandName &&
      brandContactEmail &&
      brandContactNumber &&
      brandEstd &&
      primaryColour &&
      colouredIcon &&
      coverPhoto
    ) {
      setVisibleSubmit(true);
      createBrand();
    } else {
      setVisibleSubmit(false);
      toast.warning("All Fields Are Mandatory");
    }
  };

  const createBrand = () => {
    setLoading(true);
    const data = brandEstd.split("-");
    console.log(data[0], data[1], data[2]);
    axios
      .post(
        `https://comms.globalxchange.io/gxb/app/gxlive/user/operator/create`,

        {
          email: bankerEmail, // user email
          token: loginData.idToken, // user token
          brand_name: brandName,
          brand_logo: colouredIcon,
          brand_cover: coverPhoto,
          brand_country: country,
          brand_email: brandContactEmail,
          brand_phone: brandContactNumber,
          founding_date: `${allMonths[brandEstd.split("-")[1] - 1]} ${
            brandEstd.split("-")[2]
          } ${brandEstd.split("-")[0]}`,
          // "Jan 2 2022",
          other_data: {
            colour_code: primaryColour,
          },
        }
      )
      .then(({ data }) => {
        if (data.status === false) {
          if (data.message === "jwt expired") {
            setStep("Token Expired");
          }
          toast.success(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#16171A" }}>
            Creating New Brand ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
};

export default NewMcbBrand;
