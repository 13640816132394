import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
// import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const SubCategoriesDrawer = ({ selectedCategory }) => {
    const [step, setStep] = useState("default")
    const [name, setName] = useState("");
    const [username, setUsername] = useState("")
    const [bio, setBio] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [twitter, setTwitter] = useState("");
    const [country, setCountry] = useState("");
    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [allCategories, setAllCategories] = useState([])

    const { bankerEmail, setTabSelected, SubCategoriesDrawerOpen, SetSubCategoriesDrawerOpen, login, loginData,
        refetchProduct, setRefechProduct, productDrawerOpen, setProductsDrawerOpen, selectedBusiness, statusValues } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Action");
        setPath(["Action"]);
    }, [SubCategoriesDrawerOpen]);

    // useEffect(() => {
    //   console.log(ActionBtn, "ActionBtn")
    //   if (ActionBtn === "Actions") {
    //     setStep("Actions")
    //   } else{
    //     setStep("default")
    //   }
    // }, [step, ActionBtn])

    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    // const uploadImage = async (e, setImage, setLoading) => {
    //   setLoading(true);
    //   const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
    //     e.target.files[0].name.lastIndexOf(".")
    //   )}`;
    //   const formData = new FormData();
    //   const file = renameFile(e.target.files[0], fileName);
    //   formData.append("files", file);
    //   const path_inside_brain = "root/";

    //   const jwts = await signJwt(fileName, emailDev, secret);
    //   console.log(jwts, "lkjkswedcf");
    //   let { data } = await Axios.post(
    //     `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
    //     formData,
    //     {
    //       headers: {
    //         "Access-Control-Allow-Origin": "*",
    //       },
    //     }
    //   );
    //   setImage(data.payload.url);
    //   setLoading(false);
    // };

    const uploadImage = async (e, setImage, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf('.')
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append('files', file);
        const path_inside_brain = 'root/';

        const jwts = await signJwt(fileName, emailDev, secret);
        // console.log(jwts, 'lkjkswedcf');
        let { data } = await Axios.post(
            `https://insurance.apimachine.com/insurance/general/upload`,
            formData,
            {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                },
            }
        );
        console.log(data[0].urlName, 'drive uploaded img');
        setImage(data[0].urlName);
        setLoading(false);
    };

    const ActionStatus = (item) => {
        let body = {
            status: item.toLowerCase()
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Status`)
        Axios.put(`https://frissly.apimachine.com/api/subcategory/${selectedCategory?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated status for ${selectedCategory?.name}. You will now be redirected to your updated list.`)
                    setTimeout(() => {
                        setTabSelected("Sub-Categories")
                        setStep("default");
                        SetSubCategoriesDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }


    // const validate = () => {
    //   if (
    //     name &&
    //     username &&
    //     bio &&
    //     linkedIn &&
    //     twitter &&
    //     country &&
    //     colouredIcon
    //   ) {
    //     let data = {
    //       name,
    //       user_name: username,
    //       email: bankerEmail,
    //       description: bio,
    //       social_media: [{ "LinkedIn": linkedIn, "Twitter": twitter }],
    //       profile_pic: colouredIcon,
    //       country,
    //     }
    //     addProfile(data)
    //   } else {
    //     toast.warning("Please fill all the fields")
    //   }
    // }

    const ImageUploadAPI = () => {
        let body = {
            email: bankerEmail,
            token: login?.idToken,
            field_name: "product_icon",
            field_value: colouredIcon,
            product_id: selectedCategory.product_id
        }
        console.log(body, "body")
        setStep("loading")
        setloadingtext(`Updating Icon...`)
        Axios.post("https://comms.globalxchange.io/gxb/product/edit", body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg("You have successfully edited product icon. You will be redirected to the updated Products page now.")
                    setTabSelected("Products")
                    setStep("default");
                    SetSubCategoriesDrawerOpen(false);
                    setColouredIcon("")
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
        // .finally(() => {
        //   setTabSelected("My Profile")
        //   setStep("default");
        //   SetShowDrawer(false);
        //   setRefechProfile(!refechProfile)
        // })
    }

    const deleteSubCategory = () => {
        let body = {
            status: "inactive"
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Deleting ${selectedCategory?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/subcategory/${selectedCategory?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully deleted ${selectedCategory?.name}. You will now be redirected to your updated sub categories list.`)
                    setTimeout(() => {
                        setTabSelected("Sub-Categories")
                        setStep("default");
                        SetSubCategoriesDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }
    const updateParentCategory = (item) => {
        let body = {
            categoryId: item?._id
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Parent Category`)
        Axios.put(`https://frissly.apimachine.com/api/subcategory/${selectedCategory?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully updated category for ${selectedCategory?.name}. You will now be redirected to your updated sub category list.`)
                    setTimeout(() => {
                        setTabSelected("Sub-Categories")
                        setStep("default");
                        SetSubCategoriesDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const fetchParentCategory = () => {
        Axios.get(`https://frissly.apimachine.com/api/category/?businessusername=${selectedBusiness?.businessusername}`)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setAllCategories(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }



    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );

            case "Edit Product":
                return (
                    <>
                        <div className="sidebarTitle">What element do you want to edit?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Icon");
                                    setPath([...path, "Icon"]);
                                }}
                            >
                                {/* <img
              src={editPub}
              alt=""
              className="coin"
              style={{ width: "25px", height: "25px" }}
            /> */}
                                <div>Icon</div>
                            </div>
                        </div>
                    </>
                )
            case "Icon":
                return (
                    <>
                        <div className="productScrollDrawer">
                            {/* <div className="storyname" style={{ paddingTop: "20px" }}>
            Name
          </div> */}
                            <div>
                                <div className="storyname">Current Icon</div>

                                <div
                                    className="filesUpload ImgBox"
                                    style={{ justifyContent: "flex-start", width: "100%", height: "250px" }}
                                >
                                    <div className="fileInp icon flexProduct">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                selectedCategory?.product_icon

                                            }
                                            alt=""
                                            style={{ width: "95%", height: "95%" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="storyname">New Icon</div>

                                <div
                                    className="filesUpload ImgBox"
                                    style={{ justifyContent: "flex-start", width: "100%" }}
                                >
                                    <label className="fileInp icon flexProduct">
                                        <img
                                            className={`${Boolean(colouredIcon)}`}
                                            src={
                                                colouredIconLoading
                                                    ? loadingGif
                                                    : colouredIcon || uploadGroupIcon
                                            }
                                            alt=""
                                            style={{ width: "100px" }}
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                uploadImage(
                                                    e,
                                                    setColouredIcon,
                                                    setColouredIconLoading
                                                );
                                            }}
                                            accept="image/*"
                                        />

                                        <div className="hovTxt">
                                            Upload
                                            <br />
                                            New
                                        </div>
                                    </label>
                                </div>
                            </div>

                            <div className="bottomProductBtn">
                                <button className="updateProductBtn" onClick={ImageUploadAPI}>Update Product</button>
                            </div>
                        </div>
                    </>
                );
            case "Update Parent Category":
                return (
                    <>
                        <div className="sidebarTitle">Select new parent category</div>
                        <div className="sidebarScroll">
                            {allCategories.map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => updateParentCategory(item)}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}
                        </div>
                    </>
                )
            case "Delete  Sub Categories":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure you want to delete this sub category?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={deleteSubCategory}
                            >
                                <div>Yes delete it</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Action");
                                    setPath(["Action"]);
                                }}
                            >
                                <div>Never mind</div>
                            </div>
                        </div>
                    </>
                )
            case "Change Status":
                return (
                    <>
                        <div className="sidebarTitle">Select new status</div>
                        <div className="sidebarScroll">
                            {statusValues.map((item, value) => {
                                return (
                                    <div className="sidebarCard" style={{ background: item?.toLowerCase() === selectedCategory?.status.toLowerCase() ? "#6669B0" : "" }} onClick={() => ActionStatus(item)}>
                                        <div style={{ color: item?.toLowerCase() === selectedCategory?.status.toLowerCase() ? "white" : "" }}>{item}</div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="sidebarTitle">What do you want to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update Parent Category");
                                    setPath([...path, "Update Parent Category"]);
                                    fetchParentCategory()
                                }}
                            >
                                <div>Update Parent Category</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Change Status");
                                    setPath([...path, "Change Status"]);
                                }}
                            >
                                <div>Change Status</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Delete Sub Categories");
                                    setPath([...path, "Delete  Sub Categories"]);
                                }}
                            >
                                <div>Delete Sub Category</div>
                            </div>
                        </div>
                    </>
                )
        }
    }



    return (
        <div
            className={SubCategoriesDrawerOpen ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* <img
                  src={
                    selectedBusiness?.profile_pic
                      ? selectedBusiness?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {selectedCategory?.name}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* {path.length > 1 ? (
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => handleBackStep()}
                  >
                    <img src={back} alt="" />
                  </div>
                ) : (
                  ""
                )} */}
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            SetSubCategoriesDrawerOpen(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default SubCategoriesDrawer;
