import React, { useContext, useEffect, useState } from "react";

import back from "../../../../../static/images/icons/back.svg";
import close from "../../../../../static/images/icons/close1.svg";
import editApp from "../../../../../static/images/clipIcons/appData.svg";
import userIcon from "../../../../../static/images/clipIcons/userIcon.svg";
import deleteApp from "../../../../../static/images/clipIcons/delete.svg";
import bondIcon from "../../../../../static/images/clipIcons/bondIcon.svg";
import plusIcon from "../../../../../static/images/clipIcons/plus.svg";
import closeIcon from "../../../../../static/images/clipIcons/no.svg";
import defaultImg from "../../../../../static/images/icons/app_placeholder.png";

import okIcon from "../../../../../static/images/icons/tik.svg";
import backIcon from "../../../../../static/images/icons/backArrow.svg";

import { GlobalContex } from "../../../../../globalContex";
import axios from "axios";
import UserList from "./UserList";
import BondsList from "./BondsList";

const Actions = ({
  allBonds,
  currenciesData,
  bondSelected,
  loading,
  setLoading,
  path,
  setPath,
  step,
  setStep,
}) => {
  const {
    showSubDraw,
    setShowSubDraw,
    loginData,
    bankerEmail,
    refetchTiers,
    setRefetchTiers,
  } = useContext(GlobalContex);

  const [bondHoldersdata, setBondHoldersdata] = useState(null);
  const [bondHoldersdata1, setBondHoldersdata1] = useState(null);
  const [bondHoldersdata2, setBondHoldersdata2] = useState(null);

  const [addingUsers, setAddingUsers] = useState([]);
  const [addingBondList, setAddingBondList] = useState([]);

  useEffect(() => {
    console.log(bondSelected, "ljwedlkwjede");
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/users/data/get?bond_template_id=${bondSelected?.bond_template_id}`
      )
      .then((res) => {
        if (res.data.status) {
          setBondHoldersdata(res.data);
        }
      });
  }, [bondSelected]);
  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/get?bond_template_id=${bondSelected?.bond_template_id}`
      )
      .then((res) => {
        if (res.data.status) {
          setBondHoldersdata1(res.data);
        }
      });
  }, [bondSelected]);

  useEffect(() => {
    axios
      .get(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/stats?bond_template_id=${bondSelected?.bond_template_id}`
      )
      .then((res) => {
        if (res.data.status) {
          setBondHoldersdata2(res.data);
        }
      });
  }, [bondSelected]);

  const tiersDefaultCase = [
    {
      name: "Edit Tier Data",
      icon: editApp,
      value: null,
      enabled: false,
    },
    {
      name: "Edit Tier Users",
      icon: userIcon,
      value: null,
      enabled: true,
    },
    {
      name: "Edit Tier Bonds",
      icon: bondIcon,
      value: null,
      enabled: true,
    },
    {
      name: "Delete Tier",
      icon: deleteApp,
      value: null,
      enabled: true,
    },
  ];

  const addingUsersFn = async (params) => {
    setLoading(true);
    const { data } = await axios.post(
      "https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/add",
      params
    );

    if (data.status) {
      setLoading(false);
      setStep("AddUserSuccess");
      // getBondList();

      setTimeout(() => {
        setAddingUsers([]);
        setShowSubDraw(false);
        setRefetchTiers(!refetchTiers);
      }, 1500);
    } else {
      setLoading(false);
      setStep("JWT Expired");
    }
  };

  const removeUserFn = () => {
    setLoading(true);
    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/remove`,
        {
          email: bankerEmail,
          token: loginData.idToken,
          list_id: bondSelected.list_id,
          remove_from_all: false,
          email_list: [...addingUsers.map((usr) => usr.email)],
        }
      )
      .then((res) => {
        console.log(res.data, "kjwdkwed");
        if (res.data.status) {
          setLoading(false);
          setStep("RemoveUserSuccess");
          // getBondList();

          setTimeout(() => {
            setAddingUsers([]);
            setShowSubDraw(false);
            setRefetchTiers(!refetchTiers);
          }, 1500);
        } else {
          setLoading(false);
          setStep("JWT Expired");
        }
      });
  };

  const addingBondsFn = async (params) => {
    setLoading(true);
    const { data } = await axios.post(
      "https://comms.globalxchange.io/coin/iced/banker/custom/bond/add/list",
      params
    );
    if (data.status) {
      setLoading(false);
      setStep("AddBondSuccess");
      // getBondList();

      setTimeout(() => {
        setAddingBondList([]);
        setShowSubDraw(false);
        setRefetchTiers(!refetchTiers);
      }, 1500);
    } else {
      setLoading(false);
      setStep("JWT Expired");
    }
  };

  const removingBondsFn = async (params) => {
    setLoading(true);
    const { data } = await axios.post(
      "https://comms.globalxchange.io/coin/iced/banker/custom/bond/remove/list",
      params
    );
    if (data.status) {
      setLoading(false);
      setStep("RemoveBondSuccess");
      // getBondList();

      setTimeout(() => {
        setAddingBondList([]);
        setShowSubDraw(false);
        // onClose();
        setRefetchTiers(!refetchTiers);
      }, 1500);
    } else {
      setLoading(false);
      setStep("JWT Expired");
    }
  };

  const deleteTier = () => {
    setLoading(true);
    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/delete`,
        {
          email: bankerEmail,
          token: loginData.idToken,
          list_id: bondSelected?.list_id,
        }
      )
      .then((res) => {
        if (res.data.status) {
          setLoading(false);
          setStep("DeleteTierSuccess");
          // getBondList();
          setTimeout(() => {
            setShowSubDraw(false);
            setRefetchTiers(!refetchTiers);
          }, 2000);
        } else {
          setLoading(false);
          setStep("JWT Expired");
        }
      });
  };

  useEffect(() => {
    setStep("Actions");
    setPath(["Actions"]);
  }, [showSubDraw]);

  const handleBackStep = () => {
    if (path.length > 1) {
      path.splice(-1);
      // console.log(tempPath, "kqjdkjwed");
      setPath([...path]);
      if (path.length > 0) {
        setStep(path[path.length - 1]);
      }
    }
  };

  const handleBreadcrumbClick = (clickedStep) => {
    const foundStepIndex = path.findIndex((o) => o === clickedStep);
    const tempPath = path;
    tempPath.splice(foundStepIndex + 1, tempPath.length);
    setPath([...tempPath]);
    console.log(path, "lwndwed");
  };

  useEffect(() => {
    setStep(path[path.length - 1]);
  }, [path]);

  const conditionalSteps = () => {
    switch (step) {
      case "step1":
        return (
          <>
            <div className="sidebarTitle">This is the 1st step </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("step2");
                setPath([...path, "step2"]);
              }}
            >
              <img src={editApp} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Item 1 of Step 1</div>
            </div>
          </>
        );

      //Edit Tier User Cases

      case "Edit Tier Users":
        return (
          <>
            <div className="sidebarTitle">
              Select One Of The Following Actions
            </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("Add New User");
                setPath([...path, "Add New User"]);
              }}
            >
              <img src={plusIcon} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Add New User</div>
            </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("Remove Existing User");
                setPath([...path, "Remove Existing User"]);
              }}
            >
              <img src={closeIcon} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Remove Existing User</div>
            </div>
          </>
        );

      case "Add New User":
        return (
          <>
            <UserList
              addingUsers={addingUsers}
              setAddingUsers={setAddingUsers}
              listId={bondSelected?.list_id}
              appCode={bondSelected?.app_code}
              remove={false}
              // subStep={subStep}
            />

            <div
              className="bottomButtonWhite"
              onClick={(e) => {
                setStep("");
                handleBackStep();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Go Back
              </div>
            </div>
            <div
              className="bottomButton"
              onClick={(e) => {
                addingUsersFn({
                  email: bondSelected?.email,
                  token: loginData.idToken,
                  list_id: bondSelected?.list_id,
                  email_list: addingUsers.map((usr) => usr.email),
                });
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Next
              </div>
            </div>
          </>
        );

      case "Remove Existing User":
        return (
          <>
            <UserList
              addingUsers={addingUsers}
              setAddingUsers={setAddingUsers}
              listId={bondSelected?.list_id}
              // appCode={bondTiersSelected?.app_code}
              remove={true}
            />
            <div
              className="bottomButtonWhite"
              onClick={(e) => {
                setStep("");
                handleBackStep();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Go Back
              </div>
            </div>
            <div
              className="bottomButton"
              onClick={(e) => {
                removeUserFn();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Next
              </div>
            </div>
          </>
        );

      case "AddUserSuccess":
        return (
          <>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#050505",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              You Have Successfully Added {addingUsers.length} Bonds To “
              {bondSelected?.name}”. You Are Being Redirected To The Bond Tiers
              List Automatically.
            </div>
          </>
        );

      case "RemoveUserSuccess":
        return (
          <>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#050505",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              You Have Successfully Removed {addingUsers.lngth} Users From "
              {bondSelected?.name}”. You Are Being Redirected To The Bond Tiers
              List Automatically.
            </div>
          </>
        );

      //Edit Tier Bond Cases

      case "Edit Tier Bonds":
        return (
          <>
            <div className="sidebarTitle">
              Select One Of The Following Actions
            </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("Add New Bond");
                setPath([...path, "Add New Bond"]);
              }}
            >
              <img src={plusIcon} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Add New Bond</div>
            </div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                setStep("Remove Existing Bond");
                setPath([...path, "Remove Existing Bond"]);
              }}
            >
              <img src={closeIcon} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Remove Existing Bond</div>
            </div>
          </>
        );

      case "Add New Bond":
        return (
          <>
            <BondsList
              email={bondSelected?.email}
              addingBonds={addingBondList}
              setAddingBonds={setAddingBondList}
              notAdded={true}
              listId={bondSelected?.list_id}
            />
            <div
              className="bottomButtonWhite"
              onClick={(e) => {
                setStep("");
                handleBackStep();
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Go Back
              </span>
            </div>
            <div
              className="bottomButton"
              onClick={() => {
                addingBondsFn({
                  list_id: bondSelected?.list_id,
                  bond_template_ids: addingBondList.map(
                    (bond) => bond?.bond_template_id
                  ),
                  email: bondSelected?.email,
                  token: loginData.idToken,
                });
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Next
              </span>
            </div>
          </>
        );

      case "Remove Existing Bond":
        return (
          <>
            <BondsList
              email={bondSelected?.email}
              addingBonds={addingBondList}
              setAddingBonds={setAddingBondList}
              notAdded={false}
              listId={bondSelected?.list_id}
            />
            <div
              className="bottomButtonWhite"
              onClick={(e) => {
                setStep("");
                handleBackStep();
              }}
            >
              <span
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Go Back
              </span>
            </div>
            <div
              className="bottomButton"
              onClick={() => {
                removingBondsFn({
                  list_id: bondSelected?.list_id,
                  bond_template_ids: addingBondList.map(
                    (bond) => bond?.bond_template_id
                  ),
                  email: bondSelected?.email,
                  token: loginData.idToken,
                });
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Next
              </div>
            </div>
          </>
        );

      case "AddBondSuccess":
        return (
          <>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#050505",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              You Have Successfully Added {addingBondList.length} Bonds To “
              {bondSelected?.name}”. You Are Being Redirected To The Bond Tiers
              List Automatically.
            </div>
          </>
        );

      case "RemoveBondSuccess":
        return (
          <>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#050505",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              You Have Successfully Removed {addingBondList.length} Bonds To “
              {bondSelected?.name}”. You Are Being Redirected To The Bond Tiers
              List Automatically.
            </div>
          </>
        );

      //Delete Tier Cases

      case "Delete Tier":
        return (
          <>
            <div className="sidebarTitle">
              Are You Sure You Want To Delete {bondSelected?.name}?
            </div>
            <div className="sidebarCard" onClick={(e) => deleteTier()}>
              <img src={okIcon} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Yes</div>
            </div>
            <div className="sidebarCard" onClick={handleBackStep}>
              <img src={backIcon} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>No, Go Back</div>
            </div>
          </>
        );

      case "DeleteTierSuccess":
        return (
          <>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#050505",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              You Have Successfully Deleted “{bondSelected?.name}”. You Are
              Being Redirected To The Bond Tiers List Automatically.
            </div>
          </>
        );

      //Token Expired Case

      case "JWT Expired":
        return (
          <>
            <div
              style={{
                fontSize: "16px",
                textAlign: "center",
                fontWeight: 400,
                lineHeight: "30px",
                color: "#050505",
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Token Expired. Please Login Again.
            </div>
            <div
              className="bottomButtonWhite"
              onClick={(e) => {
                setStep("");
                handleBackStep();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Go Back
              </div>
            </div>
            <div
              className="bottomButton"
              onClick={() => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                className="buttonText fontAnimate"
              >
                Logout
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="sidebarTitle">What Would You Like To See? </div>
            <div
              style={{ overflowY: "scroll", height: window.innerHeight - 380 }}
            >
              {tiersDefaultCase.map((item) => {
                return (
                  <div
                    // className={
                    //   item.enabled ? "sidebarCard" : "sidebarCardDisabled"
                    // }
                    className="sidebarCard"
                    onClick={(e) => {
                      if (item.enabled) {
                        setStep(item.name);
                        setPath([...path, item.name]);
                      }
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={item.icon}
                        alt=""
                        style={{ width: "20px", height: "20px" }}
                      />
                      <div style={{ paddingLeft: "10px" }}>{item.name}</div>
                    </div>
                    <div>{item.value}</div>
                  </div>
                );
              })}
            </div>
          </>
        );
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#16171A" }}>
            Updating Tier List ...
          </div>
        </div>
      ) : (
        <div
          style={{
            padding: "30px",
            height: "90px",
          }}
        >
          {!loading &&
          step !== "JWT Expired" &&
          step !== "AddBondSuccess" &&
          step !== "RemoveUserSuccess" &&
          step !== "RemoveBondSuccess" &&
          step !== "RemoveUserSuccess" &&
          step !== "DeleteTierSuccess" ? (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={bondSelected?.icon}
                    alt=""
                    style={{
                      width: "28px",
                      height: "28px",
                      borderRadius: "100%",
                    }}
                  />
                  <div style={{ paddingLeft: "10px" }}>
                    <div style={{ fontSize: "22px" }}>{bondSelected?.name}</div>
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {path.length > 1 ? (
                    <div
                      className="backButton icon-imgs"
                      onClick={(e) => handleBackStep()}
                    >
                      <img src={back} alt="" />
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => setShowSubDraw(false)}
                    style={{
                      marginLeft: "20px",
                    }}
                  >
                    <img src={close} alt="" />
                  </div>
                </div>
              </div>

              <div
                className="breadcrumb"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {path?.map((item, index) => {
                  return (
                    <div>
                      {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                      <span
                        onClick={(e) => handleBreadcrumbClick(item)}
                        className={
                          index === path.length - 1 ? "crumbSelected" : "crumbs"
                        }
                      >
                        {item}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          {conditionalSteps()}
        </div>
      )}
    </>
  );
};

export default Actions;
