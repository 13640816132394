import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewPincode = () => {
    const tabs = ["Add Pincode"];
    const [pincode, setPincode] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [deliveryCost, setDeliveryCost] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [theDelivery, setTheDelivery] = useState("")
    const [timeRequired , setTimeRequired] = useState("")
    const navigate = useNavigate();



    const { tabSelected, setTabSelected, setAddNewField, pincodeTrigger, setPincodeTrigger, selectedBusiness } = useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add Pincode");
        console.log(selectedBusiness?._id, "businessDetail")
        axios.get(
            `https://frissly.apimachine.com/api/business/delivery/mode?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setTheDelivery(data.data);
            }
        });
    }, []);

    useEffect(() => {
        if (pincodeTrigger) {
            if (pincode) {
                setApiLoading(true)
                let body = {
                    deliveryId: theDelivery?.[0]?._id,
                    name: name,
                    pincode: pincode,
                    charge: deliveryCost,
                    delivery_time: timeRequired
                }
                console.log(body, "fwetwe")
                if (body.pincode) {
                    axios.post("https://frissly.apimachine.com/api/business/delivery/mode/distance-based/create", body)
                        .then(({ data }) => {
                            if (data.status) {
                                // toast.success("Admin Added Successfully")
                                let theSecondBody = {
                                    userId: data.data._id,
                                    businessId: selectedBusiness?._id
                                }
                                axios.post("https://frissly.apimachine.com/api/user/business/mapping/create", theSecondBody)
                                    .then(({ data }) => {
                                        if (data.status) {
                                            toast.success("Pincode Added Successfully")
                                        }
                                    })
                                setTimeout(() => {
                                    setAddNewField(false)
                                    // navigate("/Admin")
                                    setTabSelected("Staff")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setPincodeTrigger(false)
    }, [pincodeTrigger])





    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Pincode</p>
                        <input type="text" value={pincode} onChange={(e) => setPincode(e.target.value)} className="inputStyle" placeholder="Add Pincode...." />
                        <p className="categoryName topmargin">Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Add Name...." />
                        <p className="categoryName topmargin">Delivery cost</p>
                        <input type="text" value={deliveryCost} onChange={(e) => setDeliveryCost(e.target.value)} className="inputStyle" placeholder="Add Delivery cost...." />
                        <p className="categoryName topmargin">Time </p>
                        <input type="text" value={timeRequired} onChange={(e) => setTimeRequired(e.target.value)} className="inputStyle" placeholder="0.00 Mins" />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewPincode;