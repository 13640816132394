import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../../static/images/loading.gif";
import Lock from "../../../static/images/icons/lock.svg";

import "./newBondTier.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";

import BankerList from "./BankerList";
import AppsList from "./AppsList";

import { GlobalContex } from "../../../globalContex";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewBondTier({ step, setStep, loading, setLoading }) {
  const navigate = useNavigate();

  // const { step, setStep } = useContext(MyContext);
  const {
    loginData,
    bankerEmail,
    allBankers,
    refetchTiers,
    setRefetchTiers,
    handleReplaceTab,
    bankerMenu,
    selectedTab,
    tabSelected,
    setTabSelected,
    setGlobalMenuAdd,
  } = useContext(GlobalContex);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [tierName, setTierName] = useState("");
  const [tierDesc, setTierDesc] = useState("");
  const [banker, setBanker] = useState(null);
  const [tierType, setTierType] = useState("buy");
  const [app, setApp] = useState();

  const [bankerTag, setBankerTag] = useState("");

  const [iconLoading, setIconLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case "Success":
        return (
          <>
            <div className="newConglomerate">
              <div className="succesView">
                <div className="labelItm">
                  You Have Successfully Added “{tierName}”. You Will Be
                  Redirected To The Updated Bond Tiers List Automatically
                </div>
              </div>
            </div>
          </>
        );
      case "app":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <AppsList setApp={setApp} onClose={() => setStep("")} />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
          Submit BondTier
        </div> */}
            </div>
          </>
        );

      case "banker":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BankerList setBanker={setBanker} onClose={() => setStep("")} />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
            </div>
          </>
        );
      case "Token Expired":
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div className="sidebarTitle">Token Expired. Login Again.</div>
            <div
              className="sidebarCard"
              onClick={(e) => {
                localStorage.clear();
                window.location.reload();
              }}
            >
              <img src={Lock} alt="" style={{ width: "20px" }} />
              <div style={{ paddingLeft: "10px" }}>Logout</div>
            </div>
          </div>
        );
      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Select Banker */}
                {/* <div className="name">Select Banker</div>
                {banker?.email ? (
                  <div className="user" onClick={() => setStep("banker")}>
                    <img className="dp" src={banker?.profilePicURL} alt="" />
                    <div className="userDetail">
                      <div className="name">{banker?.displayName}</div>
                      <div className="email">{banker?.email}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("banker")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click Here To Select A Banker"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )} */}

                {/* Tier Name */}
                <div className="name">Tier Name</div>
                <div className="inputWrap">
                  <input
                    value={tierName}
                    onChange={(e) => setTierName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter Tier Name"
                  />
                </div>

                {/* Select Tier Type */}
                <div className="name">Select Tier Type</div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "20px 0 40px 0",
                  }}
                >
                  <div
                    className={tierType === "buy" ? "btnSelected" : "btn1"}
                    onClick={(e) =>
                      setTierType(tierType === "buy" ? "" : "buy")
                    }
                  >
                    Buy
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div
                    className={tierType === "visible" ? "btnSelected" : "btn1"}
                    onClick={(e) =>
                      setTierType(tierType === "visible" ? "" : "visible")
                    }
                  >
                    See
                  </div>
                </div>

                {/* Upload Tier Icon */}

                <div className="name">Upload Tier Icon</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text" style={{ fontWeight: "700" }}>
                      Colored Icon
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>

                {/* Tier Description */}
                <div className="name">Tier Description</div>
                <div className="inputWrap">
                  <input
                    value={tierDesc}
                    onChange={(e) => setTierDesc(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter ShareToken Full Name"
                  />
                </div>

                {/* Select App */}
                <div className="name">Select App</div>
                {app?.app_code ? (
                  <div className="user" onClick={() => setStep("app")}>
                    <img className="dp" src={app?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{app?.app_name}</div>
                      <div className="email">{app?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("app")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click Here To Select An App"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div>
              <div className="fullBtn" onClick={() => validate(true)}>
                <span
                  style={{
                    opacity:
                      icon && tierName && tierDesc && tierType && app ? 1 : 0.3,
                  }}
                >
                  Submit Bond Tier
                </span>
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
          Submit BondTier
        </div> */}
            </div>
            {/* <div className="ftBtns">
              <div className="newField" onClick={() => {}}>
                Go Back
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Next Step
              </div>
            </div> */}
          </>
        );
    }
  }

  const createNewBondTier = () => {
    setLoading(true);
    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/create`,
        {
          email: bankerEmail, // BANKER EMAIL
          token: loginData.idToken, // BANKER AUTH TOKEN
          name: tierName,
          app_code: app.app_code,
          listType: tierType, // buy / visible
          icon: icon,
          description: tierDesc,
        }
      )
      .then(({ data }) => {
        if (data.status) {
          setStep("Success");
          setTimeout(() => {
            setGlobalMenuAdd(false);
            setRefetchTiers(!refetchTiers);
            if (tabSelected !== "Tiers") {
              console.log(tabSelected, "lkwnlwkefe");
              handleReplaceTab(bankerMenu.find((o) => o.menuName === "Bonds"));
              setTabSelected("Tiers");
            }

            // navigate("/ventures/Brands");
            // window.location.reload();
          }, 2000);
        } else if (!data.status && data.message === "jwt expired") {
          setStep("Token Expired");
        } else {
          toast.success(data.message || "API Error");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  };

  function validate() {
    if ((icon, banker, tierName, tierDesc, tierType, app)) {
      createNewBondTier();

      setLoading(true);
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {/* {getContent()} */}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#16171A" }}>
            Creating New Bond Tier ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
}

export default NewBondTier;
