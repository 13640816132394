import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import { message } from "antd";
// import ActionIndex from "./CategorySubDrawer/ActionIndex"
import SubCategoriesDrawer from "./SubCategoriesDrawer";

const SubCategories = () => {
    const { loginData, globalSearch, collapse, getDisplayDate, getDays, fetchProductData, setFetchProductData, productSubDrawer, setProductSubDrawer, refetchProduct, setRefechProduct, selectedBusiness, SubCategoriesDrawerOpen, SetSubCategoriesDrawerOpen, setTheFormData, setSelectedFormData, setFetchForm,setEditSubCategoryTrigger } = useContext(GlobalContex);

    const [allSubCategories, setAllSubCategories] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("")
    //   const [page, setPage] = useState(0);

    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;

    // useEffect(() => {
    //   setDataLoading(true);
    //   console.log(Math.ceil((window.innerHeight - 175) / 90), "innerHeight")
    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;
    //   axios
    //     .get(
    //       `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get?skip=0&limit=${limit}`
    //     )
    //     .then((res) => {
    //       setAllMarketsverseUsers(res.data.users);
    //       setDataLoading(false);
    //     });
    // }, [])

    const fetchData = async () => {
        // console.log(pageNumber, "page")
        setDataLoading(true);

        // console.log("call API")

        setDataLoading(true);

        try {
            const response = await fetch(`https://frissly.apimachine.com/api/subcategory?status=active&&status=draft&&businessusername=${selectedBusiness?.businessusername}`);
            const jsonData = await response.json();
            const newData = jsonData.data;

            setAllSubCategories(newData);
            console.log(newData, "newData")
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setDataLoading(false);

    };






    useEffect(() => {
        fetchData();
        setEditSubCategoryTrigger(false)
    }, [fetchProductData, refetchProduct, setRefechProduct, selectedBusiness]);



    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid,
        showSubDraw
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div id="usersData"
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflow: 'auto'
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.filter(
                                    (item) =>
                                        item?.name
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase()) ||
                                        item?.categoryId?.name
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase())).map((item) => {
                                                return contentSection(item, "listDataGrid", desktopDataGrid);
                                            })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        <SubCategoriesDrawer selectedCategory={selectedCategory} />
                    </div>
                </div>

                <div className="mobileWrapper">
                    <div style={{ overflowY: "scroll", height: "80vh" }}>
                        {headerSection("listGridMobile", mobileDataGrid)}

                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return contentSection(
                                        item,
                                        "listDataGridMobile",
                                        mobileDataGrid
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", mobileDataGrid)
                        )}
                        <SubCategoriesDrawer selectedCategory={selectedCategory} />
                    </div>
                </div>
            </>
        );
    };

    // Change these three Sections according to the design

    const headerSection = (gridClass, gridValues) => {
        return (
            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Name</div>
                <div style={{ textAlign: "left" }}>Category</div>
                <div style={{ textAlign: "left" }}></div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues) => {
        let imageDisp
        if (item?.icon?.slice(0, 4) === "http") {
            imageDisp = item?.icon
        } else {
            imageDisp = defaultImg
        }
        console.log(item?.icon?.slice(0, 4), "cionn")
        return (
            <div
                // onClick={(e) => {
                //     setSelectedCategory(item);
                //     SetSubCategoriesDrawerOpen(true);
                // }}
                className={gridClass}
                style={{
                    gridTemplateColumns: gridValues,
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={imageDisp}
                        alt=""
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                        }}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                        <div className="title">{item?.name} &nbsp;</div>
                        <div className="subtitle">{item?._id}</div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginRight: "20px",
                    }}
                >
                    <div className="title">{item?.categoryId?.name}</div>
                </div>
                <div className="btngrp">
                    <button className="actionbtn" onClick={(e) => {
                       setSelectedCategory(item);
                       SetSubCategoriesDrawerOpen(true);
                    }}>Actions</button>
                    <button className="actionbtn" style={{ width: "150px" }} onClick={() => {
                        setTheFormData(true)
                        setSelectedFormData("Sub-Categories")
                        setFetchForm(item)
                        }}>Sub Category page</button>
            </div>
            </div>
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        {/* <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={70} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={100} />
                        </div> */}
                    </div>
                );
            });
    };

    return (
        <div>
            {conditionalResposiveView(
                allSubCategories,
                dataLoading,
                "2fr 1fr 1fr", // Desktop view Grid columns
                "250px 200px 200px" // Mobile view Grid columns
            )}
        </div>
    );
};

export default SubCategories;
