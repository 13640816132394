import React, { useContext, useEffect, useState } from "react";
import "../../AddNewForms/AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import editIcon from "../../static/images/editIconDisp.svg"
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const GlobalGetForm = ({ theOrder, theStatus, theSelectField, theExternalID }) => {
    const { tabSelected,
        setTabSelected,
        productTrigger,
        setProductTrigger,
        setAddNewField,
        selectedBusiness,
        fetchForm,
        setFetchForm,
        editForm,
        setEditForm,
        editProductTrigger,
        setEditProductTrigger,
        setTheFormData,
        editCategoryTrigger,
        setEditCategoryTrigger,
        editSubCategoryTrigger,
        setEditSubCategoryTrigger,
        selectedFormData,
        editFilterTrigger,
        setEditFilterTrigger,
        editBrandTrigger,
        setEditBrandTrigger } =
        useContext(GlobalContex);
    const tabs = ["Go Back"];
    const [updatedData, setUpdatedData] = useState(fetchForm?.icon);
    const [imgLoading, setImgLoading] = useState(false);
    const [coverPhoto, setCoverPhoto] = useState(fetchForm?.coverPhoto)
    const [coverLoading, setCoverLoading] = useState("")
    const [additionalLoading, setAdditionalLoading] = useState(false)
    const [additionalPhoto, setAdditionalPhoto] = useState("")
    const [metaArr, setMetaArr] = useState(fetchForm?.metaTags?.length > 0 ? fetchForm?.metaTags : [])
    const [metaTag, setMetaTag] = useState("")
    const [altTagsArr, setAltTagsArr] = useState(fetchForm?.altImagesTags?.length > 0 ? fetchForm?.altImagesTags : [])
    const [altTags, setAltTags] = useState("")
    const [imageArr, setImageArr] = useState(fetchForm?.images?.length > 0 ? fetchForm?.images : [])
    const [name, setName] = useState(fetchForm?.name)
    const [description, setDescription] = useState(fetchForm?.description)
    const [categoryNumber, setCategoryNumber] = useState(selectedFormData === "Product" ? fetchForm?.productOrderCategory : fetchForm?.categoryOrder)
    const [pageTitle, setPageTitle] = useState(fetchForm?.pageTitle)
    const [metaDescription, setMetaDescription] = useState(fetchForm?.MetaDescription)
    const [status, setStatus] = useState(fetchForm?.toDisplay)
    const [colourCode, setColourCode] = useState(fetchForm?.colourCode)
    const [apiLoading, setApiLoading] = useState(false)
    const [selectCategory, setSelectCategory] = useState(fetchForm?.categoryId?._id || "")
    const [categories, setCategories] = useState([])
    const [username, setUserName] = useState("")
    const [fetchUsers, setFetchUsers] = useState([])
    const navigate = useNavigate();

    let themetaTagArr = editForm ? metaArr : fetchForm?.metaTags
    let theAltTagArr = editForm ? altTagsArr : fetchForm?.altImagesTags
    let theImagesArr = editForm ? imageArr : fetchForm?.images
    let theNum = selectedFormData === "Product" ? fetchForm?.productOrderCategory : fetchForm?.categoryOrder




    const shallowEqual = (obj1, obj2) => {
        // Get the keys of each object
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        // console.log(obj1 , obj2 , "abcd")

        // Check if the number of keys is the same
        if (keys1.length !== keys2.length) {
            return false;
        }

        // Iterate through keys and compare values
        for (const key of keys1) {
            // Check if the key exists in both objects
            if (!keys2.includes(key)) {
                return false;
            }

            console.log(obj1[key], obj2[key], JSON.stringify(obj1[key]) === JSON.stringify(obj2[key]), key, "keyss")

            // Check if the values are the same
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
                return false;
            }
        }

        // If all checks pass, the objects are equal
        return true;
    }

    useEffect(() => {
        console.log(theSelectField, "theFieldd")
        setTabSelected("Go Back");
        console.log(fetchForm, "formmm")
        if (theSelectField) {
            axios.get(
                `https://frissly.apimachine.com/api/category?businessusername=${selectedBusiness.businessusername}`
            ).then(({ data }) => {
                if (data.status) {
                    console.log(data.data, "thedata")
                    setCategories(data.data);
                }
            });
        }
    }, []);




    useEffect(() => {
        let theAPI
        if (editProductTrigger) {
            theAPI = `https://frissly.apimachine.com/api/product/${fetchForm?._id}`
        } else if (editCategoryTrigger) {
            theAPI = `https://frissly.apimachine.com/api/category/${fetchForm?._id}`
        } else if (editSubCategoryTrigger) {
            theAPI = `https://frissly.apimachine.com/api/subcategory/${fetchForm?._id}`
        } else if (editFilterTrigger) {
            theAPI = `https://frissly.apimachine.com/api/filter/${fetchForm?._id}`
        } else if (editBrandTrigger) {
            theAPI = `https://frissly.apimachine.com/api/brand/${fetchForm?._id}`
        } else {
            return
        }

        // console.log("Hit Edit API", editProductTrigger, editCategoryTrigger, editSubCategoryTrigger, editFilterTrigger)



        let intialValues = {
            name: fetchForm?.name,
            description: fetchForm?.description,
            icon: fetchForm?.icon,
            coverPhoto: fetchForm?.coverPhoto,
            colourCode: fetchForm?.colourCode,
            altImagesTags: fetchForm?.altImagesTags,
            metaTags: fetchForm?.metaTags,
            pageTitle: fetchForm?.pageTitle,
            MetaDescription: fetchForm?.MetaDescription,
            toDisplay: fetchForm?.toDisplay,
            images: fetchForm?.images,
        }

        let body = {
            name: name,
            description: description,
            icon: updatedData,
            coverPhoto: coverPhoto,
            colourCode: colourCode,
            altImagesTags: altTagsArr,
            metaTags: metaArr,
            pageTitle: pageTitle,
            MetaDescription: metaDescription,
            toDisplay: status,
            images: imageArr,
        }

        // console.log(selectedFormData)
        let theCollect = []
        theCollect.push(shallowEqual(body, intialValues))

        if (selectedFormData === "Sub-Categories") {
            intialValues.categoryId = fetchForm?.categoryId?._id
            body.categoryId = selectCategory
        }

        if (selectedFormData === "Product") {
            intialValues.productOrderCategory = fetchForm?.productOrderCategory
            body.productOrderCategory = categoryNumber
        } else {
            intialValues.categoryOrder = fetchForm?.categoryOrder
            body.categoryOrder = categoryNumber
        }

        console.log(shallowEqual(body, intialValues), "the-data")

        if (!shallowEqual(body, intialValues) && theAPI) {
            setApiLoading(true)
            axios.put(theAPI, body)
                .then(({ data }) => {
                    if (data.status) {
                        toast.success(`${selectedFormData} Updated Successfully`)
                        setTimeout(() => {
                            setEditForm(false)
                            setTheFormData(false)
                        }, 1000)
                    } else {
                        toast.error(data?.message || "Something went wrong")
                    }
                })
                .catch((err) => {
                    toast.error("API Error");
                })
            setApiLoading(false)
        } else {
            console.log(theCollect, "collect")
            setEditProductTrigger(false)
            setEditCategoryTrigger(false)
            setEditSubCategoryTrigger(false)
            setEditFilterTrigger(false)
            setEditBrandTrigger(false)
        }
    }, [editProductTrigger, editCategoryTrigger, editSubCategoryTrigger, editFilterTrigger, editBrandTrigger])

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }

    const uploadImage = async (e, setUpdatedData, setLoading, theText) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf(".")
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append("files", file);

        let { data } = await axios.post(
            `https://publications.apimachine.com/article/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        setUpdatedData(data[0].urlName);
        setLoading(false);
        console.log(data[0].urlName)
        if (theText === "additionalImg") {
            setImageArr([...imageArr, data[0].urlName])
        }
    };

    const addMetaTag = (metaTag) => {
        if (metaTag === "") return
        setMetaArr([...metaArr, metaTag])
        console.log(metaArr, "metaArr")
        setMetaTag("")
    }

    const removeTag = (item) => {
        setMetaArr(metaArr.filter((tag) => tag !== item));
    };

    const removeImages = (item) => {
        setImageArr(imageArr.filter((tag) => tag !== item));
    };


    const addAltTag = (tag) => {
        if (tag === "") return
        setAltTagsArr([...altTagsArr, tag])
        setAltTags("")
    }

    const removeAltTag = (item) => {
        setAltTagsArr(altTagsArr.filter((keyword) => keyword !== item));
    };


    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData"  >
                <div style={{ pointerEvents: editForm ? "" : "none" }}>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Name</p>
                        <div className="inputDiv">
                            <input type="text" value={editForm ? name : fetchForm?.name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Example: Cookies" />
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>
                        {/* <p className="categoryName topmargin">Select Business User</p> */}
                        {/* <div className="select-wrapper">
                            <select name="Online" className="selectStyle" value={username} onChange={(e) => setUserName(e.target.value)}>
                                {fetchUsers.map((e) => {
                                    return <>
                                        <option value={e.businessusername}>{e?.businessusername}</option>
                                    </>
                                })}
                            </select>
                        </div> */}
                        <p className="categoryName topmargin">Description</p>
                        <div className="inputDiv">
                            <textarea value={editForm ? description : fetchForm?.description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Example: Cookies" ></textarea>
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>
                        <p className="categoryName topmargin" style={{ display: theOrder ? "" : "none" }}>Category Order</p>
                        <div className="inputDiv" style={{ display: theOrder ? "" : "none" }}>
                            <input type="number" value={editForm ? categoryNumber : theNum} onChange={(e) => setCategoryNumber(e.target.value)} className="inputStyle" placeholder="Example: 1" />
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>
                    </div>
                    <br />
                    <div className="basicForm">
                        <p className="categoryTitle">SEO Details</p>
                        <p className="categoryName">Page title</p>
                        <div className="inputDiv">
                            <input type="text" value={editForm ? pageTitle : fetchForm?.pageTitle} onChange={(e) => setPageTitle(e.target.value)} className="inputStyle" placeholder="Example: Cookies" />
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>

                        <p className="categoryName topmargin">Page Meta Description</p>
                        <div className="inputDiv">
                            <textarea value={editForm ? metaDescription : fetchForm?.MetaDescription} onChange={(e) => setMetaDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Example: Cookies" ></textarea>
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>
                        <p className="categoryName topmargin">Metatags</p>
                        {themetaTagArr.map((item, index) => {
                            return <><div style={{ display: "flex" }}>
                                <input type="text" value={item} className="inputStyle" placeholder="Example: Food" disabled style={{ opacity: 0.5 }} />
                                &nbsp;&nbsp;
                                <button className="removeBtn" onClick={() => removeTag(item)}>Remove</button>
                            </div>
                                <br />
                            </>
                        })}
                        &nbsp;
                        <div style={{ display: "flex" }}>
                            <div className="inputDiv">
                                <input type="text" value={editForm ? metaTag : ""}
                                    onChange={(e) => setMetaTag(e.target.value)} className="inputStyle" placeholder="Example: Food" />
                                <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                            </div>
                            &nbsp;&nbsp;
                            <button className="addBtn" onClick={() => addMetaTag(metaTag)} >Save</button>
                        </div>
                        <p className="categoryName topmargin">AltTags</p>
                        {theAltTagArr.map((item, index) => {
                            return (
                                <>
                                    <div style={{ display: "flex" }}>
                                        <input type="text" value={item} className="inputStyle" placeholder="Example: Food" disabled style={{ opacity: 0.5 }} />
                                        &nbsp;&nbsp;
                                        <button className="removeBtn" onClick={() => removeAltTag(item)} >Remove</button>
                                    </div>
                                    <br />
                                </>
                            )
                        })}
                        &nbsp;
                        <div style={{ display: "flex" }}>
                            <div className="inputDiv">
                                <input type="text" value={editForm ? altTags : ""}
                                    onChange={(e) => setAltTags(e.target.value)} className="inputStyle" placeholder="Example: Food" />
                                <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                            </div>

                            &nbsp;&nbsp;
                            <button className="addBtn" onClick={() => addAltTag(altTags)}>Save</button>
                        </div>
                    </div>
                    <br />
                    <div className="basicForm" style={{ display: theStatus ? "" : "none" }}>
                        <p className="categoryTitle">Status</p>
                        <div className="select-wrapper">
                            <div className="inputDiv">
                                <select name="online" className="selectStyle" value={editForm ? status : fetchForm?.toDisplay} onChange={(e) => setStatus(e.target.value)}>
                                    <option value="online">Online</option>
                                    <option value="offline">Offline</option>
                                    <option value="draft">Draft</option>
                                </select>
                            </div>
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>
                        <br/>
                        <br/>
                        <div style={{ display: theExternalID ? "" : "none" }}>
                        <p className="categoryName">External ID</p>
                        <div className="inputDiv">
                            <input type="text" value={fetchForm?.externalId} className="inputStyle" placeholder="External Id" />
                            {/* <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} /> */}
                        </div>
                        </div>
                    </div>
                    <br />
                    <div className="basicForm" style={{ display: theSelectField ? "" : "none" }}>
                        <p className="categoryTitle">Select Category</p>
                        <div className="select-wrapper">
                            <div className="inputDiv">
                                <select name="Online" className="selectStyle" value={selectCategory} onChange={(e) => setSelectCategory(e.target.value)}>
                                    <option value={fetchForm?.categoryId?._id}>{fetchForm?.categoryId?.name}</option>
                                    {categories.filter((id) => id._id !== fetchForm?.categoryId?._id).map((e) => {
                                        return <>
                                            <option value={e._id}>{e?.name}</option>
                                        </>
                                    })}
                                </select>
                            </div>
                            <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                        </div>
                    </div>
                </div>
                <div style={{ pointerEvents: editForm ? "" : "none" }}>
                    <div className="basicForm">
                        <p className="categoryTitle">Cosmetic Details</p>
                        <p className="categoryName">Icon</p>
                        <div className="inputDiv">
                            <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                                <label
                                    className="fileInp icon"
                                    style={{
                                        flex: "0 0 100%",
                                        maxWidth: "103px",
                                        height: "100px",
                                        borderRadius: "10px",
                                        background: "white"
                                    }}
                                >
                                    <img
                                        style={{ width: updatedData ? "100px" : "46px", height: updatedData ? "100px" : "46px" }}
                                        className={`${Boolean(updatedData)}`}
                                        src={
                                            imgLoading
                                                ? loadingGif
                                                : editForm ? updatedData : fetchForm?.icon || cloudUploadIcon
                                        }
                                        alt=""
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            uploadImage(e, setUpdatedData, setImgLoading);
                                        }}
                                        accept="image/*"
                                    />

                                    <div className="hovTxt">
                                        Upload
                                        <br />
                                        New
                                    </div>
                                    <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                                </label>
                            </div>
                            <p className="categoryName topmargin">Cover Photo</p>
                            <div className="inputDiv">
                                <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                                    <label
                                        className="fileInp icon"
                                        style={{
                                            flex: "0 0 100%",
                                            maxWidth: "100%",
                                            height: "170px",
                                            borderRadius: "10px",
                                            background: "white",
                                            position: "relative",
                                            alignItems: "center",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <img
                                            style={{ width: coverPhoto ? "100%" : "46px", height: coverPhoto ? "170px" : "46px", objectFit: coverPhoto ? "cover" : "", border: coverPhoto ? "1px solid #E7E7E7" : "", borderRadius: "10px", marginTop: coverPhoto ? "22px" : "" }}
                                            className={`${Boolean(coverPhoto)}`}
                                            src={
                                                coverLoading
                                                    ? loadingGif
                                                    : editForm ? coverPhoto : fetchForm?.coverPhoto || cloudUploadIcon
                                            }
                                            alt=""
                                        />
                                        <input
                                            type="file"
                                            onChange={(e) => {
                                                uploadImage(e, setCoverPhoto, setCoverLoading);
                                            }}
                                            accept="image/*"
                                        />

                                        <div className="hovTxt">
                                            Upload
                                            <br />
                                            New
                                        </div>
                                        <br />
                                        <p className="imgText" style={{ display: coverPhoto ? "none" : "" }}><span className="highlightText">Click to Upload</span> or drag and drop</p>
                                    </label>

                                </div>
                                <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                            </div>


                            <br />
                            <p className="categoryName topmargin">Colour Code</p>
                            <div className="inputDiv">
                                <div style={{ position: "relative", display: "flex" }}>
                                    <input value={editForm ? colourCode : fetchForm?.colourCode} onChange={(e) => setColourCode(e.target.value)} type="text" className="inputStyle" placeholder="Example: 00000" />
                                    <div className="colourBox" style={{ background: editForm ? colourCode : fetchForm?.colourCode }}></div>
                                </div>
                                <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                            </div>
                        </div>

                    </div>
                    <br />
                    <div className="basicForm">
                        <p className="categoryTitle">Additional Media</p>
                        <p className="categoryName topmargin">Accept’s Images & Videos</p>
                        {theImagesArr.map((e, index) => {
                            return <>
                                <div className="fitImage">
                                    <img src={CloseIcon} alt="close" className="theCloseIcon" onClick={() => removeImages(e)} />
                                    <img src={e} alt="images" className="additionalImages" />
                                </div>
                                <br />
                            </>
                        })}
                        <div className="filesUpload" style={{ height: "120px", padding: 0 }}>
                            <div className="inputDiv">
                                <label
                                    className="fileInp icon"
                                    style={{
                                        flex: "0 0 100%",
                                        maxWidth: "100%",
                                        height: "170px",
                                        borderRadius: "10px",
                                        background: "white",
                                        position: "relative",
                                        alignItems: "center",
                                        flexDirection: "column"
                                    }}
                                >
                                    <img
                                        style={{ width: "46px", height: "46px" }}
                                        className={`${Boolean(additionalPhoto)}`}
                                        src={
                                            additionalLoading ? loadingGif : cloudUploadIcon
                                        }
                                        alt=""
                                    />
                                    <input
                                        type="file"
                                        onChange={(e) => {
                                            uploadImage(e, setAdditionalPhoto, setAdditionalLoading, "additionalImg");
                                        }}
                                        accept="image/*"
                                    />

                                    <div className="hovTxt">
                                        Upload
                                        <br />
                                        New
                                    </div>
                                    <br />
                                    <p className="imgText"><span className="highlightText">Click to Upload</span> or drag and drop</p>
                                </label>
                                <img src={editIcon} alt="Edit" className="editIconDisp" style={{ display: editForm ? "" : "none" }} />
                            </div>
                        </div>
                        <br />
                        <br />
                        {/* <button className="addBtn" style={{ width: "150px" }}>Add Another</button> */}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default GlobalGetForm;