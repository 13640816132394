import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewMachine = () => {
    const tabs = ["Add Machine"];
    const [name, setName] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [AllMachineCategory, setAllMachineCategory] = useState([]);
    const [selectedMachineCategory , setSelectedMachineCategory] = useState("")
    const [numOfUnits, setNumOfUnits] = useState("");
    const [dateOfCommencement, setDateOfCommencement] = useState("")
    const [machineStatus, setMachineStatus] = useState("")
    const [allFactories, setAllFactories] = useState([])
    const [allCities, setAllCities] = useState([])
    const [selectedFactory, setSelectedFactory] = useState("")
    const [selectedCity, setSelectedCity] = useState("")



    const { tabSelected, setTabSelected, setAddNewField, machineTrigger , setMachineTrigger, selectedBusiness, cityTrigger, setCityTrigger } = useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add Machine");
        console.log(selectedBusiness?._id, "businessDetail")
        axios.get(
            `https://frissly.apimachine.com/api/business/logistics/get/machine/category?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setAllMachineCategory(data.data);
            }
        });
        // axios.get(
        //     `https://frissly.apimachine.com/api/business/logistics/get/city?businessId=${selectedBusiness?._id}`
        // ).then(({ data }) => {
        //     if (data.status) {
        //         setAllCities(data.data);
        //     }
        // });
    }, []);

    useEffect(() => {
        if (machineTrigger) {
            console.log(selectedFactory, selectedCity, "factory11")
            if (name) {
                setApiLoading(true)
                let body = {
                    businessId: selectedBusiness?._id,
                    machineCategoryId: !selectedMachineCategory ? AllMachineCategory?.[0]?._id : selectedMachineCategory,
                    name: name,
                    description: description,
                    numberOfUnits: numOfUnits,
                    dateOfCommencement: dateOfCommencement,
                    machineStatus: machineStatus
                }
                console.log(body, "fwetwe")
                if (body.name) {
                    axios.post("https://frissly.apimachine.com/api/business/logistics/create/machine", body)
                        .then(({ data }) => {
                            if (data.status) {
                                toast.success("Machine Added Successfully")
                                setTimeout(() => {
                                    setAddNewField(false)
                                    setTabSelected("Staff")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setMachineTrigger(false)
    }, [machineTrigger])

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
    const uploadImage = async (e, setUpdatedData, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf(".")
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append("files", file);

        let { data } = await axios.post(
            `https://publications.apimachine.com/article/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        setUpdatedData(data[0].urlName);
        setLoading(false);
    };



    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Add Name...." />
                        <p className="categoryName topmargin">Description</p>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Add Description..." ></textarea>
                        <p className="categoryName topmargin">Number of Units</p>
                        <input type="text" value={numOfUnits} onChange={(e) => setNumOfUnits(e.target.value)} className="inputStyle" placeholder="Add Number of Units...." />
                        <p className="categoryName topmargin">Date of Commencement</p>
                        <input type="text" value={dateOfCommencement} onChange={(e) => setDateOfCommencement(e.target.value)} className="inputStyle" placeholder="Add Date...." />
                        <p className="categoryName topmargin">Machine Status</p>
                        <input type="text" value={machineStatus} onChange={(e) => setMachineStatus(e.target.value)} className="inputStyle" placeholder="Add Machine Status...." />
                        <p className="categoryName topmargin">Select Machine Category</p>
                        <div className="select-wrapper">
                            <select name="factory" className="selectStyle" value={selectedMachineCategory} onChange={(e) => setSelectedMachineCategory(e.target.value)}>
                                {AllMachineCategory.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div>
                        {/* <p className="categoryName topmargin">Select City</p>
                        <div className="select-wrapper">
                            <select name="city" className="selectStyle" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                                {allCities.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div> */}

                        {/* <p className="categoryName topmargin">GST code</p>
                        <input type="text" value={gstCode} onChange={(e) => setGstCode(e.target.value)} className="inputStyle" placeholder="Add Gst Code...." /> */}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewMachine;