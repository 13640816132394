import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../../static/images/loading.gif";

import bonds from "../../../static/images/sidebarIcons/bonds.svg";

import "./newBondTier.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";

import BankerList from "./BankerList";
import AppsList from "./AppsList";

import { GlobalContex } from "../../../globalContex";
import BondTypeList from "./BondTypeList";
import BondAssetList from "./BondAssetList";
import BondCollectionAppList from "./BondCollectionAppList";
import BondPayoutAppList from "./BondPayoutAppList";
import WholesaleBondList from "./WholesaleBondList";
// import { useBankerDetails } from "../../../queryHooks";
// import jwt from "jsonwebtoken";
// import { sign } from "react-native-pure-jwt";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewBond({ step, setStep, loading, setLoading }) {
  const navigate = useNavigate();

  const {
    loginData,
    bankerEmail,
    allBankers,
    tabs,
    setTabs,
    selectedTab,
    setSelectedTab,
    setGlobalMenuAdd,
    FormatCurrency,
    FormatNumber,
    allCoins,
    refetchBonds,
    setRefetchBonds,
    tabSelected,
    setTabSelected,
    bankerMenu,
  } = useContext(GlobalContex);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [tierName, setTierName] = useState("");
  const [tierDesc, setTierDesc] = useState("");
  const [banker, setBanker] = useState();
  const [tierType, setTierType] = useState("buy");
  const [app, setApp] = useState();

  const [bankerTag, setBankerTag] = useState("");

  const [iconLoading, setIconLoading] = useState(false);

  const [bondType, setBondType] = useState(null);
  const [bondAsset, setBondAsset] = useState(null);
  const [bondCollectionApp, setBondCollectionApp] = useState(null);
  const [bondPayoutApp, setBondPayoutApp] = useState(null);
  const [costOneBond, setCostOneBond] = useState("");
  const [bondLength, setBondLength] = useState("");
  const [dailyIntRate, setDailyIntRate] = useState("");
  const [affFee, setAffFee] = useState("");
  const [redemptionFee, setRedemptionFee] = useState("yes");
  const [wholesaleBond, setWholesaleBond] = useState(null);
  const [connectABond, setConnectABond] = useState(false);

  const [bondError, setBondError] = useState("");

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  const handleReplaceTab = () => {
    const item = {
      menuName: "Bonds",
      menuIcon: bonds,
      enabled: true,
    };
    if (
      tabs.findIndex((o) => o.menuName === item.menuName) < 0 ||
      tabs.length < 1
    ) {
      const index = tabs.findIndex((o) => o.menuName === selectedTab.menuName);
      console.log(
        tabs.findIndex((o) => o.menuName === selectedTab.menuName),
        selectedTab,
        "jhwgjwhe"
      );
      tabs.splice(index, 1, item);
      setSelectedTab(tabs.length < 1 ? tabs[index] : item);
    } else {
      setSelectedTab(item);
    }
  };

  function getContent() {
    switch (step) {
      // case "Success":
      //   setTimeout(() => {
      //     // navigate("/ventures/Brands");
      //     window.location.reload();
      //   }, 2000);
      //   return (
      //     <div className="newConglomerate">
      //       <div className="succesView">
      //         <div className="labelItm">
      //           You Have Successfully Created a new “{bondAsset} Bond”. You Will
      //           Be Redirected To The Updated Bond List Automatically
      //         </div>
      //       </div>
      //     </div>
      //   );

      case "Success":
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                You Have Successfully Created a New “{bondAsset?.coinName}{" "}
                Bond”. You Will Be Redirected To The Updated Bond List
                Automatically
              </div>
            </div>
          </div>
        );

      case "bondAddFailware":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          setGlobalMenuAdd(false);
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm" style={{ textAlign: "center" }}>
                Bond Creation Failed.
              </div>
            </div>
          </div>
        );

      case "bondType":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BondTypeList
                setBondType={setBondType}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
          Submit BondTier
        </div> */}
            </div>
          </>
        );
      case "bondAsset":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BondAssetList
                setBondAsset={setBondAsset}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
            Submit BondTier
          </div> */}
            </div>
          </>
        );
      case "collectionApps":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BondCollectionAppList
                setBondCollectionApp={setBondCollectionApp}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
            Submit BondTier
          </div> */}
            </div>
          </>
        );
      case "payoutApps":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BondPayoutAppList
                setBondPayoutApp={setBondPayoutApp}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
              Submit BondTier
            </div> */}
            </div>
          </>
        );
      case "wholesaleBonds":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <WholesaleBondList
                setWholesaleBond={setWholesaleBond}
                bondAsset={bondAsset?.coinSymbol}
                bondLength={bondLength}
                onClose={() => setStep("")}
              />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
              {/* <div className="btnSubmit" onClick={() => onClose()}>
                Submit BondTier
              </div> */}
            </div>
          </>
        );

      case "banker":
        return (
          <>
            <div className="newConglomerate" style={{ paddingBottom: "0px" }}>
              <BankerList setBanker={setBanker} onClose={() => setStep("")} />
            </div>
            <div>
              <div className="fullBtn" onClick={() => setStep("")}>
                Go Back
              </div>
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Select Bond Type */}
                <div className="name">Select Bond Type</div>
                {bondType ? (
                  <div
                    onClick={() => setStep("bondType")}
                    className="user"
                    style={{ fontSize: "22px", fontWeight: 700 }}
                  >
                    {bondType.name}
                    {/* <img className="dp" src={banker?.profilePicURL} alt="" />
                    <div className="userDetail">
                      <div className="name">{banker?.displayName}</div>
                      <div className="email">{banker?.email}</div>
                    </div> */}
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("bondType")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Select Bond Asset */}
                <div className="name">Select Bond Asset</div>
                {bondAsset ? (
                  <div
                    className="user"
                    onClick={() => setStep("bondAsset")}
                    // onClick={() => setStep("banker")}
                  >
                    <img className="dp" src={bondAsset?.coinImage} alt="" />
                    <div className="userDetail">
                      <div className="name">{bondAsset?.coinName}</div>
                      <div className="email">{bondAsset?.coinSymbol}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("bondAsset")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Select Bond Collection App */}
                <div className="name">Select Bond Collection App</div>
                {bondCollectionApp ? (
                  <div
                    className="user"
                    onClick={() => setStep("collectionApps")}
                  >
                    <img
                      className="dp"
                      src={bondCollectionApp?.app_icon}
                      alt=""
                    />
                    <div className="userDetail">
                      <div className="name">{bondCollectionApp?.app_name}</div>
                      <div className="email">{bondCollectionApp?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("collectionApps")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Select Bond payout App */}
                <div className="name">Select Bond payout App</div>
                {bondPayoutApp ? (
                  <div className="user" onClick={() => setStep("payoutApps")}>
                    <img className="dp" src={bondPayoutApp?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{bondPayoutApp?.app_name}</div>
                      <div className="email">{bondPayoutApp?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="inputWrap"
                    onClick={() => setStep("payoutApps")}
                  >
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* What Is The Cost Of One Bond  */}

                <div className="name">What Is The Cost Of One Bond</div>
                <div className="inputWrap">
                  <input
                    value={costOneBond}
                    onChange={(e) => setCostOneBond(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="0.0000"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "16px",
                    }}
                  >
                    {bondAsset ? bondAsset.coinSymbol : "BTC"}
                  </div>
                </div>

                {/* What Is The Bond Length  */}

                <div className="name">What Is The Bond Length</div>
                <div className="inputWrap">
                  <input
                    value={bondLength}
                    onChange={(e) => setBondLength(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="Enter Days..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "16px",
                    }}
                  >
                    Days
                  </div>
                </div>

                {/* What Is The Daily Interest Rate  */}

                <div className="name">What Is The Daily Interest Rate</div>
                <div className="inputWrap">
                  <input
                    value={dailyIntRate}
                    onChange={(e) => setDailyIntRate(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="0.00"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "160px",
                      fontSize: "16px",
                    }}
                  >
                    % Per Day
                  </div>
                </div>

                {/* Set The Default Affiliate Fee  */}

                <div className="name">Set The Default Affiliate Fee</div>
                <div className="inputWrap">
                  <input
                    value={affFee}
                    onChange={(e) => setAffFee(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="0.00"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "160px",
                      fontSize: "16px",
                    }}
                  >
                    % Of Interest
                  </div>
                </div>

                {/* Do You Want To Enable A Redemption Fee */}
                <div className="name">
                  Do You Want To Enable A Redemption Fee
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "20px 0 40px 0",
                  }}
                >
                  <div
                    className={redemptionFee === "yes" ? "btnSelected" : "btn1"}
                    onClick={(e) => setRedemptionFee("yes")}
                  >
                    Yes
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div
                    className={redemptionFee === "no" ? "btnSelected" : "btn1"}
                    onClick={(e) => setRedemptionFee("no")}
                  >
                    No
                  </div>
                </div>

                {/* Do You Want To Enable A Redemption Fee */}

                <div className="name">Do You Want To Connect A Bond</div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    margin: "20px 0 40px 0",
                  }}
                >
                  <div
                    className={connectABond === true ? "btnSelected" : "btn1"}
                    onClick={(e) => setConnectABond(true)}
                  >
                    Yes
                  </div>
                  &nbsp;&nbsp;&nbsp;
                  <div
                    className={connectABond === false ? "btnSelected" : "btn1"}
                    onClick={(e) => setConnectABond(false)}
                  >
                    No
                  </div>
                </div>

                {/* Connect Bond To Wholesale Bond */}
                {connectABond ? (
                  <>
                    <div className="name">Connect Bond To Wholesale Bond</div>
                    {wholesaleBond ? (
                      <div
                        className="bond"
                        onClick={(e) => setStep("wholesaleBonds")}
                        style={{
                          border: "0.5px solid #e7e7e7",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <div className="coinDetail">
                          <img
                            className="dp"
                            style={{ width: "22px", height: "22px" }}
                            src={
                              allCoins.find(
                                (o) =>
                                  o.coinSymbol ===
                                  wholesaleBond?.coinsData?.[0]?.coin
                              ).coinImage
                            }
                            alt=""
                          />
                          <div
                            className="name"
                            style={{ color: "#050505", fontSize: "16px" }}
                          >
                            {
                              allCoins.find(
                                (o) =>
                                  o.coinSymbol ===
                                  wholesaleBond?.coinsData?.[0]?.coin
                              ).coinName
                            }
                          </div>
                        </div>
                        <div className="bondValues">
                          <span
                            className="cost"
                            style={{ fontSize: "12px", fontWeight: 700 }}
                          >
                            {FormatCurrency(
                              wholesaleBond?.coinsData?.[0]?.bondCost,
                              wholesaleBond?.coinsData?.[0]?.coin
                            )}{" "}
                            {wholesaleBond?.coinsData?.[0]?.coin}
                          </span>
                          <span
                            className="intrst"
                            style={{ fontSize: "12px", fontWeight: 700 }}
                          >
                            {FormatNumber(
                              wholesaleBond?.daily_interest_rate,
                              3
                            )}
                            %/Day
                          </span>
                          <span
                            className="days"
                            style={{ fontSize: "12px", fontWeight: 700 }}
                          >
                            {FormatNumber(wholesaleBond?.days)} Days
                          </span>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ opacity: bondAsset && bondLength ? 1 : 0.4 }}
                        className="inputWrap"
                        onClick={() => {
                          if (bondAsset && bondLength) {
                            setStep("wholesaleBonds");
                          }
                        }}
                      >
                        <input
                          type="text"
                          className="text"
                          placeholder="Click To Select"
                          readOnly
                        />
                        <div
                          className="btnCheck"
                          style={{
                            border: "none",
                          }}
                        >
                          <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}

                {/* Upload Tier Icon

                <div className="name">Upload Tier Icon</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text" style={{ fontWeight: "700" }}>
                      Colored Icon
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div> */}

                {/* Tier Description
                <div className="name">Tier Description</div>
                <div className="inputWrap">
                  <input
                    value={tierDesc}
                    onChange={(e) => setTierDesc(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter ShareToken Full Name"
                  />
                </div> */}

                {/* Connect Bond To Wholesale Bond */}
                {/* <div className="name">Connect Bond To Wholesale Bond</div>
                {app?.app_code ? (
                  <div className="user" onClick={() => setStep("app")}>
                    <img className="dp" src={app?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{app?.app_name}</div>
                      <div className="email">{app?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("app")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click Here To Select An App"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )} */}
                <div className="space"></div>
              </Scrollbars>
            </div>
            {/* <div
              className="footerBtns"
              style={{
                paddingTop: 0,
                position: "fixed",

                bottom: "0px",
                // left: "395px",
                right: "0px",
                width: "500px",
              }}
            >
              <div
                className="btnSave"
                style={{
                  borderLeft: "0.5px solid #E7E7E7",
                  borderRight: "0.5px solid #E7E7E7",
                  opacity: 0.4,
                }}
                // onClick={() => setSubStep("")}
              >
                Go Back
              </div>
              <div
                className="btnSave"
                onClick={() => {
                  addingUsersMutate({
                    email: bondTiersSelected?.email,
                    token: loginData.idToken,
                    list_id: bondTiersSelected?.list_id,
                    email_list: addingUsers.map((usr) => usr.email),
                  });
                }}
              >
                Next Step
              </div>
            </div> */}
            <div>
              <div className="fullBtn" onClick={() => validate(true)}>
                <span
                  style={{
                    opacity:
                      bondType &&
                      bondAsset &&
                      bondCollectionApp &&
                      bondPayoutApp &&
                      costOneBond &&
                      bondLength &&
                      dailyIntRate &&
                      affFee
                        ? 1
                        : 0.3,
                  }}
                >
                  Create Bond
                </span>
              </div>
            </div>
            {/* <div className="ftBtns">
              <div className="newField" onClick={() => {}}>
                Go Back
              </div>
              <div className="btnSubmit" onClick={() => validate(true)}>
                Next Step
              </div>
            </div> */}
          </>
        );
    }
  }

  useEffect(() => {
    console.log(wholesaleBond, "ljwedlwed");
  }, [wholesaleBond]);

  const createNewBond = () => {
    setLoading(true);

    axios
      .post(
        `https://comms.globalxchange.io/coin/iced/banker/custom/bond/create`,
        {
          email: bankerEmail,
          token: loginData?.idToken,
          bond_type: "fixed",
          coinsData: [
            {
              coin: bondAsset?.coinSymbol,
              bondCost: costOneBond,
            },
          ],
          days: bondLength,
          daily_interest_rate: dailyIntRate,
          payoutProfileId: bondPayoutApp?.profile_id,
          payinProfileId: bondCollectionApp?.profile_id,
          connectedBondEnabled: connectABond,
          connectedBond_templateId: wholesaleBond?.bond_template_id,
          remove_redemption_fee: redemptionFee === "yes" ? false : true,
          bond_upline_fee: affFee,
        }
      )
      .then(({ data }) => {
        if (data.status) {
          setStep("Success");
          setTimeout(() => {
            setGlobalMenuAdd(false);
            setRefetchBonds(!refetchBonds);
            if (tabSelected !== "Offerings") {
              console.log(tabSelected, "lkwnlwkefe");
              handleReplaceTab(bankerMenu.find((o) => o.menuName === "Bonds"));
              setTabSelected("Offerings");
            }

            // navigate("/ventures/Brands");
            // window.location.reload();
          }, 2000);
        } else if (!data.status && data.message === "jwt expired") {
          setStep("Token Expired");
        } else {
          setStep("bondAddFailware");
          toast.success(data.message || "API Error");
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        setBondError(error?.response?.data?.message);
        setStep("bondAddFailware");
      })
      .finally(() => setLoading(false));

    // axios
    //   .post(
    //     `https://comms.globalxchange.io/coin/iced/banker/custom/bond/user/list/create`,
    //     {
    //       email: banker.email, // BANKER EMAIL
    //       token: loginData.idToken, // BANKER AUTH TOKEN
    //       name: tierName,
    //       app_code: app.app_code,
    //       listType: tierType, // buy / visible
    //       icon: icon,
    //       description: tierDesc,
    //     }
    //   )
    //   .then(({ data }) => {
    //     if (data.status === false) {
    //       toast.success(data.message || "API Error");
    //     } else setStep("success");
    //   })
    //   .catch((error) => {
    //     toast.error(error?.response?.data?.message);
    //   })
    //   .finally(() => setLoading(false));
  };

  function validate() {
    console.log(
      bondType,
      bondAsset,
      bondCollectionApp,
      bondPayoutApp,
      costOneBond,
      bondLength,
      dailyIntRate,
      affFee,
      redemptionFee,
      connectABond,
      "kjwhkwjedw"
    );
    if (
      bondType &&
      bondAsset &&
      bondCollectionApp &&
      bondPayoutApp &&
      costOneBond &&
      bondLength &&
      dailyIntRate &&
      affFee
    ) {
      createNewBond();
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {/* {getContent()} */}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#16171A" }}>
            Creating New Bond ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
}

export default NewBond;
