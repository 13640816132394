import React, { useContext, useEffect, useState } from "react";
import Axios from "axios";
import Scrollbars from "react-custom-scrollbars";
import * as jose from "jose";
import cloudUploadIcon from "../../../static/images/clipIcons/cloudUpload.svg";

import loadingGif from "../../../static/images/loading.gif";
import AppsList from "./AppsList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import BankerList from "./AssetList";
// import LoadingAnimation from "../LoadingAnimation";
import { useNavigate } from "react-router-dom";
// import { selectLoginData } from "../../app/loginSlice";
import { toast } from "react-toastify";
import axios from "axios";
import clearInput from "../../../static/images/icons/refresh.svg";
import AssetList from "./AssetList";
import CountryList from "./CountryList";
import ComapnyList from "./CompanyList";
import CompanyList from "./CompanyList";
import EquityList from "./EquityList";
import "./numberInput.css";
import { GlobalContex } from "../../../globalContex";
// import { useBankerDetails } from "../../../queryHooks";
// import jwt from "jsonwebtoken";
// import { sign } from "react-native-pure-jwt";

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.

function NewShareToken({ step, setStep, loading, setLoading,setMainMenu }) {
  const navigate = useNavigate();

  // const { step, setStep } = useContext(MyContext);
  const { loginData, bankerEmail } = useContext(GlobalContex);

  // Form Variables
  const [icon, setIcon] = useState("");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [banker, setBanker] = useState();
  const [type, setType] = useState("buy");
  const [app, setApp] = useState();

  const [bankerTag, setBankerTag] = useState("");

  const [iconLoading, setIconLoading] = useState(false);

  const [issuanceAsset, setIssuanceAsset] = useState();
  const [country, setCountry] = useState();
  const [company, setCompany] = useState();
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenAvailable, setTokenAvailable] = useState(null);
  const [primaryColour, setPrimaryColour] = useState("");
  const [secondaryColour, setSecondaryColour] = useState("");
  const [equity, setEquity] = useState("");
  const [shareTokenCount, setShareTokenCount] = useState();
  const [initialSalePrice, setInitialSalePrice] = useState();

  const [commissionForAffiliates, setCommissionForAffiliates] = useState("");
  const [directAffiliatesCommission, setDirectAffiliatesCommission] =
    useState("");
  const [networkChainCommission, setNetworkChainCommission] = useState("");
  const [directExecutiveOverride, setDirectExecutiveOverride] = useState("");
  const [executiveNetworkChainCommission, setExecutiveNetworkChainCommission] =
    useState("");

  useEffect(() => {
    localStorage.setItem("step", step);
  }, [step]);

  const handleCheckTokenAvailability = () => {
    if (tokenAvailable === null && tokenSymbol !== "") {
      axios
        .get(
          `https://comms.globalxchange.io/coin/investment/path/get?token=${tokenSymbol}`
        )
        .then((res) => {
          if (res.data.status) {
            setTokenAvailable(false);
          } else {
            setTokenAvailable(true);
          }
        });
    } else {
      setTokenAvailable(null);
      setTokenSymbol("");
    }
  };

  const signJwt = async (fileName, emailDev, secret) => {
    try {
      const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
        .setProtectedHeader({ alg: "HS512" })
        .setIssuer("gxjwtenchs512")
        .setExpirationTime("10m")
        .sign(new TextEncoder().encode(secret));
      return jwts;
    } catch (error) {
      console.log(error, "kjbedkjwebdw");
    }
  };

  const uploadImage = async (e, setImage, setLoading) => {
    setLoading(true);
    const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
      e.target.files[0].name.lastIndexOf(".")
    )}`;
    const formData = new FormData();
    const file = renameFile(e.target.files[0], fileName);
    formData.append("files", file);
    const path_inside_brain = "root/";

    const jwts = await signJwt(fileName, emailDev, secret);
    console.log(jwts, "lkjkswedcf");
    let { data } = await Axios.post(
      `https://drivetest.globalxchange.io/file/dev-upload-file?email=${emailDev}&path=${path_inside_brain}&token=${jwts}&name=${fileName}`,
      formData,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    setImage(data.payload.url);
    setLoading(false);
  };

  function getContent() {
    switch (step) {
      case "success":
        setTimeout(() => {
          // navigate("/ventures/Brands");
          window.location.reload();
        }, 2000);
        return (
          <div className="newConglomerate">
            <div className="succesView">
              <div className="labelItm">
                You Have Successfully Added the ShareToken. You Will Be
                Redirected To The Updated Bond Tiers List Automatically
              </div>
            </div>
          </div>
        );
      case "app":
        return (
          <div className="newConglomerate">
            <AppsList setApp={setApp} onClose={() => setStep("")} />
          </div>
        );
      case "asset":
        return (
          <div className="newConglomerate">
            <AssetList
              setIssuanceAsset={setIssuanceAsset}
              onClose={() => setStep("")}
            />
          </div>
        );
      case "country":
        return (
          <div className="newConglomerate">
            <CountryList setCountry={setCountry} onClose={() => setStep("")} />
          </div>
        );
      case "company":
        return (
          <div className="newConglomerate">
            <CompanyList setCompany={setCompany} onClose={() => setStep("")} />
          </div>
        );
      case "equity":
        return (
          <div className="newConglomerate">
            <EquityList setEquity={setEquity} onClose={() => setStep("")} />
          </div>
        );

      default:
        return (
          <>
            <div className="newConglomerate">
              <Scrollbars
                className="scrollForm"
                renderTrackHorizontal={() => <div />}
                renderThumbHorizontal={() => <div />}
                renderTrackVertical={() => <div />}
                renderThumbVertical={() => <div />}
              >
                {/* Select Asset */}

                <div className="name">Select Issuance Asset</div>
                {issuanceAsset?.coinName ? (
                  <div className="user" onClick={() => setStep("asset")}>
                    <img className="dp" src={issuanceAsset?.coinImage} alt="" />
                    <div className="userDetail">
                      <div className="name">{issuanceAsset?.coinName}</div>
                      <div className="email">{issuanceAsset?.coinSymbol}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("asset")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}
                {/* Select Country */}
                <div className="name">Select Issuance Country</div>
                {country?.name ? (
                  <div className="user" onClick={() => setStep("country")}>
                    <img className="dp" src={country?.image} alt="" />
                    <div className="userDetail">
                      <div className="name">{country?.name}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("country")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* select Company For ShareToken */}

                <div className="name">Select Company For ShareToken</div>
                {company?.app_name ? (
                  <div className="user" onClick={() => setStep("company")}>
                    <img className="dp" src={company?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{company?.app_name}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("company")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* Create Your ShareToken Ticker Symbol */}

                <div className="name">Create Your ShareToken Ticker Symbol</div>
                <div className="inputWrap">
                  <div
                    style={{
                      width: "10px",
                      background:
                        tokenAvailable !== null
                          ? tokenAvailable === true
                            ? "#78C92E"
                            : "#EA0F0F"
                          : "",
                    }}
                  >
                    &nbsp;
                  </div>
                  <input
                    value={tokenSymbol}
                    onChange={(e) => setTokenSymbol(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter ShareToken Symbol.."
                  />
                  <div
                    onClick={(e) => handleCheckTokenAvailability()}
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                    }}
                  >
                    {tokenAvailable !== null ? (
                      tokenAvailable === true ? (
                        <img style={{ cursor: "pointer" }} src={clearInput} />
                      ) : (
                        <img style={{ cursor: "pointer" }} src={clearInput} />
                      )
                    ) : (
                      "Check"
                    )}
                  </div>
                </div>

                {/* Create Your ShareToken Name */}

                <div className="name">Create Your ShareToken Name</div>
                <div className="inputWrap">
                  <input
                    value={tokenName}
                    onChange={(e) => setTokenName(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter ShareToken Full Name"
                  />
                </div>

                {/* Upload ShareToken Icon */}

                <div className="name">Upload ShareToken Icon</div>
                <div className="filesUpload">
                  <label className="fileInp icon">
                    <img
                      className={`${Boolean(icon)}`}
                      src={iconLoading ? loadingGif : icon || cloudUploadIcon}
                      alt=""
                    />
                    <input
                      type="file"
                      onChange={(e) => {
                        uploadImage(e, setIcon, setIconLoading);
                      }}
                      accept="image/*"
                    />
                    <div className="text" style={{ fontWeight: "700" }}>
                      Colored Icon
                    </div>
                    <div className="hovTxt">
                      Upload
                      <br />
                      New
                    </div>
                  </label>
                </div>

                {/* Enter Primary Colour Code */}

                <div className="name">Enter Primary Colour Code</div>
                <div className="inputWrap">
                  <input
                    value={primaryColour}
                    onChange={(e) => setPrimaryColour(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter Primary Colour Code"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      background: primaryColour
                        ? "#" + primaryColour
                        : "#ffffff",
                      width: "68px",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>

                {/* Enter Secondary Colour Code */}

                <div className="name">Enter Secondary Colour Code</div>
                <div className="inputWrap">
                  <input
                    value={secondaryColour}
                    onChange={(e) => setSecondaryColour(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter Secondary Colour Code"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      background: secondaryColour
                        ? "#" + secondaryColour
                        : "#ffffff",
                      width: "68px",
                    }}
                  >
                    &nbsp;
                  </div>
                </div>

                {/* Select The Equity Round */}

                <div className="name">Select The Equity Round</div>
                {equity ? (
                  <div className="user" onClick={() => setStep("equity")}>
                    <div className="userDetail">
                      <div className="name">{equity}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("equity")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click To Select"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )}

                {/* How Many ShareTokens Are You Issuing */}

                <div className="name">How Many ShareTokens Are You Issuing</div>
                <div className="inputWrap">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    value={shareTokenCount}
                    onChange={(e) => setShareTokenCount(e.target.value)}
                    className="text"
                    placeholder="Enter Initial Supply..."
                  />
                </div>

                {/* What Is The Initial Sale Price? */}

                <div className="name">What Is The Initial Sale Price?</div>
                <div className="inputWrap">
                  <input
                    value={initialSalePrice}
                    onChange={(e) => setInitialSalePrice(e.target.value)}
                    type="text"
                    className="text"
                    placeholder="Enter Price Per Token"
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={issuanceAsset?.coinImage} width="20px" />
                    &nbsp;
                    <div style={{ fontWeight: 700 }}>
                      {issuanceAsset?.coinSymbol}
                    </div>
                  </div>
                </div>

                {/* What Is The Commission For Affiliates?  */}

                <div className="name">
                  What Is The Commission For Affiliates?
                </div>
                <div className="inputWrap">
                  <input
                    value={commissionForAffiliates}
                    onChange={(e) => setCommissionForAffiliates(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="Enter Percentage Of Sale..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "30px",
                    }}
                  >
                    %
                  </div>
                </div>

                {/* Set Direct Affiliate Commission  */}

                <div className="name">Set Direct Affiliate Commission</div>
                <div className="inputWrap">
                  <input
                    value={directAffiliatesCommission}
                    onChange={(e) =>
                      setDirectAffiliatesCommission(e.target.value)
                    }
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="Enter Percentage Of Sale..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "30px",
                    }}
                  >
                    %
                  </div>
                </div>

                {/* Set NetworkChain Commission  */}

                <div className="name">Set NetworkChain Commission</div>
                <div className="inputWrap">
                  <input
                    value={networkChainCommission}
                    onChange={(e) => setNetworkChainCommission(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="Enter Percentage Of Sale..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "30px",
                    }}
                  >
                    %
                  </div>
                </div>

                {/* Set Direct Executive Override  */}

                <div className="name">Set Direct Executive Override</div>
                <div className="inputWrap">
                  <input
                    value={directExecutiveOverride}
                    onChange={(e) => setDirectExecutiveOverride(e.target.value)}
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="Enter Percentage Of Sale..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "30px",
                    }}
                  >
                    %
                  </div>
                </div>

                {/* Set Executive NetworkChain Commission  */}

                <div className="name">
                  Set Executive NetworkChain Commission
                </div>
                <div className="inputWrap">
                  <input
                    value={executiveNetworkChainCommission}
                    onChange={(e) =>
                      setExecutiveNetworkChainCommission(e.target.value)
                    }
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    max="100"
                    className="text"
                    placeholder="Enter Percentage Of Sale..."
                  />
                  <div
                    className="btnCheck"
                    style={{
                      borderLeft: "solid 1px #e7e7e7",
                      display: "flex",
                      alignItems: "center",
                      fontWeight: 700,
                      width: "100px",
                      fontSize: "30px",
                    }}
                  >
                    %
                  </div>
                </div>

                {/* old */}

                {/* <div className="name">Select App</div>
                {app?.app_code ? (
                  <div className="user" onClick={() => setStep("app")}>
                    <img className="dp" src={app?.app_icon} alt="" />
                    <div className="userDetail">
                      <div className="name">{app?.app_name}</div>
                      <div className="email">{app?.app_code}</div>
                    </div>
                  </div>
                ) : (
                  <div className="inputWrap" onClick={() => setStep("app")}>
                    <input
                      type="text"
                      className="text"
                      placeholder="Click Here To Select An App"
                      readOnly
                    />
                    <div
                      className="btnCheck"
                      style={{
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                )} */}
                <div className="space"></div>
              </Scrollbars>
            </div>
            <div className="footerBtns">
              <div className="newField"
                style={{ fontWeight: 700 }}
                onClick={(e) => {
                  setStep("");
                  setMainMenu("");
                }}>
                Go Back
              </div>
              <div className="btnSubmit" onClick={() => validate()}>
                Next Step
              </div>
            </div>
          </>
        );
    }
  }

  function createNewShareToken(newBankerTag) {
    setTokenAvailable(false);
    setLoading(true);
    Axios.post("https://comms.globalxchange.io/coin/investment/path/define", {
      email: bankerEmail,
      token: loginData.idToken,
      asset: issuanceAsset.coinSymbol,
      banker: newBankerTag,
      country: country.name,
      investmentType: "EQT",
      investmentSubType: "privateequity",
      pathType: "shareToken",
      pathAppCode: company.app_code,
      commission_fee_percentage: commissionForAffiliates,
      total_steps: {
        step1: {
          name: "Initiated",
          status: "Initiated",
        },
        step2: {
          name: "Reedemption state",
          status: "Redeemed",
        },
      },
      banker_trade_fee: 0,
      gx_trade_fee: 0,
      banker_fixed_fee: 0,
      gx_fixed_fee: 0,
      success_step: "step2",
      failure_step: "step1",
      seriesName: equity,
      isssuingAmount: shareTokenCount,
      token_price: initialSalePrice,
      token_symbol: tokenSymbol,
      token_profile_data: {
        coinName: tokenName,
        coinSymbol: tokenSymbol,
        coinImage: icon,
        color_codes: [primaryColour, secondaryColour],
      },
      commission_distribution: {
        direct: directAffiliatesCommission,
        indirect: networkChainCommission,
        direct_bd: directExecutiveOverride,
        indirect_bd: executiveNetworkChainCommission,
      },
    })
      .then(({ data }) => {
        if (data.status === false) {
          toast.success(data.message || "API Error");
        } else setStep("success");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      })
      .finally(() => setLoading(false));
  }

  const getBankerTag = () => {
    axios
      .get(
        `https://teller2.apimachine.com/admin/allBankers?email=${bankerEmail}`
      )
      .then(({ data }) => {
        if (data.status) {
          console.log(data.data[0].bankerTag, "kjwbkfjgwekfwef");
          setBankerTag(data.data[0].bankerTag);
          localStorage.setItem("bankerTag", data.data[0].bankerTag);
          createNewShareToken(data.data[0].bankerTag);
        }
      });
  };

  function validate() {
    if (
      icon &&
      executiveNetworkChainCommission &&
      directExecutiveOverride &&
      networkChainCommission &&
      initialSalePrice &&
      shareTokenCount &&
      equity &&
      secondaryColour &&
      primaryColour &&
      tokenName &&
      tokenSymbol &&
      company &&
      country &&
      issuanceAsset &&
      directAffiliatesCommission &&
      commissionForAffiliates
    ) {
      // console.log("i am inside validation");
      getBankerTag();
      // setLoading(true);
    } else {
      toast.warning("All Fields Are Mandatory");
    }
  }

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="loader"></div>
          <div style={{ padding: "20px", fontWeight: 600, color: "#16171A" }}>
            Creating New Sharetoken ...
          </div>
        </div>
      ) : (
        getContent()
      )}
    </>
  );
}

export default NewShareToken;
