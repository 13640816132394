import React, { useMemo, useState, useEffect, useContext } from "react";

import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import StateList from "./StateList"
import CityList from "./CityList";
import AreaList from "./AreaList"
import PincodeList from "./PincodeList";
import ColonyList from "./ColonyList";
import FactoryList from "./FactoryList"
import FactoryLicenseTypeList from "./FactoryLicenseTypeList"
import FactoryLicenseList from "./FactoryLicenseList"
import FactoryServicingCityList from "./FactoryServicingCityList";


const NewLists = () => {
    const tabs = ["States", "Cities", "Areas", "Pincodes", "Colony", "Factory", "Factory License Type", "Factory License", "Factory Servicing City"];

    const { setShowSubDraw, tabSelected, setTabSelected, selectedAddNew, setSelectedAddNew, setEditProductTrigger } =
        useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("States");
        setEditProductTrigger(false)
    }, []);

    const tabComponent = useMemo(() => {
        switch (tabSelected) {
            case "States":
                return <StateList />;
            case "Cities":
                return <CityList />;
            case "Areas":
                return <AreaList />;
            case "Pincodes":
                return <PincodeList />;
            case "Colony":
                return <ColonyList />;
            case "Factory":
                return <FactoryList />;
            case "Factory License Type":
                return <FactoryLicenseTypeList />;
            case "Factory License":
                return <FactoryLicenseList />;
            case "Factory Servicing City":
                return <FactoryServicingCityList />;
            default:
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: "70vh",
                        }}
                    >
                        Coming Soon
                    </div>
                );
        }
    }, [
        tabSelected,
        // openCoinFilter, refetchApi
    ]);

    return (
        <div>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
            />

            {tabComponent}

            {/* <div style={{ width: "100%" }}>
        <div className="listGrid">
          <div>Asset</div>
          <div>Cost</div>
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
        </div>
      </div> */}
            {/* <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >

        <Subdrawer />
      </div> */}
        </div>
    );
};

export default NewLists;
