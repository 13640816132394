import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import { message } from "antd";
import PincodeDrawer from "./PincodeDrawer";
// import FilterDrawer from "./FilterSubDrawer";

const Pincodes = () => {
    const { loginData, globalSearch, collapse, getDisplayDate, getDays, fetchProductData, setFetchProductData, FilterDrawerOpen, SetFilterDrawerOpen, refetchProduct, setRefechProduct, selectedBusiness, setTheFormData, setSelectedFormData, setFetchForm, setEditFilterTrigger,pincodeDrawerOpen , setPincodeDrawerOpen,
        selectedPincode, setSelectedPincode } = useContext(GlobalContex);

    const [pincodeData, setPincodeData] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("")


    const fetchData = async () => {
        // console.log(pageNumber, "page")
        setDataLoading(true);

        // console.log("call API")

        setDataLoading(true);

        try {
            const deliveryData = await fetch(`https://frissly.apimachine.com/api/business/delivery/mode?businessId=${selectedBusiness?._id}`)
            const jsonDeliveryData = await deliveryData.json();
            if (jsonDeliveryData.status) {
                const response = await fetch(`https://frissly.apimachine.com/api/business/delivery/mode/distance-based/?deliveryId=${jsonDeliveryData.data?.[0]?._id}`);
                const jsonData = await response.json();
                const newData = jsonData.data;

                setPincodeData(newData);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setDataLoading(false);

    };






    useEffect(() => {
        fetchData();
        setEditFilterTrigger(false)
    }, [fetchProductData, refetchProduct, setRefechProduct, selectedBusiness]);



    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid,
        showSubDraw
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div id="usersData"
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflow: 'auto'
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.filter(
                                    (item) =>
                                        item?.pincode
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase()) ||
                                        item?.charge
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase())).map((item) => {
                                                return contentSection(item, "listDataGrid", desktopDataGrid);
                                            })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        {/* <FilterDrawer selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} /> */}
                        <PincodeDrawer/>
                    </div>
                </div>

                <div className="mobileWrapper">
                    <div style={{ overflowY: "scroll", height: "80vh" }}>
                        {headerSection("listGridMobile", mobileDataGrid)}

                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return contentSection(
                                        item,
                                        "listDataGridMobile",
                                        mobileDataGrid
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", mobileDataGrid)
                        )}
                        {/* <FilterDrawer selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} /> */}
                        <PincodeDrawer/>
                    </div>
                </div>
            </>
        );
    };

    // Change these three Sections according to the design

    const headerSection = (gridClass, gridValues) => {
        return (
            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Pincode</div>
                <div style={{ textAlign: "left" }}>Name</div>
                <div style={{ textAlign: "left" }}>Time</div>
                <div style={{ textAlign: "left" }}>Amount</div>
                <div style={{ textAlign: "left" }}></div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues) => {
        let imageDisp
        if (item?.icon || item?.icon !== "") {
            imageDisp = item?.icon
        } else {
            imageDisp = defaultImg
        }
        return (
            <div
                // onClick={(e) => {
                //     setSelectedPincode(item);
                //     setPincodeDrawer(true);
                // }}
                className={gridClass}
                style={{
                    gridTemplateColumns: gridValues,
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    {/* <img
                        src={imageDisp}
                        alt=""
                        style={{
                            borderRadius: "50%",
                            width: "30px",
                        }}
                    /> */}
                    <div >
                        <div className="title">{item?.pincode} &nbsp;</div>
                        <div className="subtitle">{item?._id}</div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div className="title">{item?.name}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div className="title">{item?.delivery_time}</div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div className="title">{item?.charge}</div>
                </div>
                <div className="btngrp">
                    <button className="actionbtn" onClick={(e) => {
                        setSelectedPincode(item);
                        setPincodeDrawerOpen(true);
                    }}>Actions</button>
                    {/* <button className="actionbtn" style={{ width: "135px" }} onClick={() => {
                        setTheFormData(true)
                        setSelectedFormData("Filters")
                        setFetchForm(item)
                    }}>Filter page</button> */}
                </div>
            </div>
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                        </div>

                    </div>
                );
            });
    };

    return (
        <div>
            {conditionalResposiveView(
                pincodeData,
                dataLoading,
                "1.5fr 1fr 1fr 1fr 1fr", // Desktop view Grid columns
                "250px 200px 200px" // Mobile view Grid columns
            )}
        </div>
    );
};

export default Pincodes;
