import React, { useEffect } from "react";
import { useContext } from "react";
import { GlobalContex } from "../globalContex";
import AddNewCategory from "./AddNewCategory";
import AddNewSubCategory from "./AddNewSubCategory";
import AddNewFilter from "./AddNewFilter";
import AddNewBrand from "./AddNewBrand";
import GlobalSidebar from "../globalComponents/GlobalSidebar";
import LocalSidebar from "../globalComponents/LocalSidebar";
import TabsLayout from "../globalComponents/TabsLayout";
import MobileMenu from "../globalComponents/MobileMenu";
import AddNewProduct from "./AddNewProduct";
import AddNewBusiness from "./AddNewBusiness";
import AddNewAdmin from "./AddNewAdmin"
import AddNewPincode from "./AddNewPincode";
import AddNewCoupon from "./AddNewCoupon";
import AddNewState from "./AddNewState";
import AddNewCity from "./AddNewCity";
import AddNewArea from "./AddNewArea";
import AddNewLogisticPincode from "./AddNewLogisticPincode";
import AddNewColony from "./AddNewColony";
import AddNewFactory from "./AddNewFactory";
import AddNewFactoryLicenseType from "./AddNewFactoryLicenseType";
import AddNewFactoryLicense from "./AddNewFactoryLicense";
import AddNewFactoryServicingCity from "./AddNewFactoryServicingCity";
import AddNewMachineCategory from "./AddNewMachineCategory";
import AddNewMachine from "./AddNewMachine";
import AddNewVehicle from "./AddNewVehicle";
import AddNewVehicleCategory from "./AddNewVehicleCategory";
import AddLogisticsHub from "./AddLogisicsHub";
// import NavBar from "../globalComponents/NavBar";



const AddNewForms = () => {
  const {
    selectedForm,
    setSelectedForm,
    collapse,
    setTabs,
    setSelectedTab,
    selectedTab,
    loginData,
    setBankerEmail,
    modalOpen,
    setModalOpen,
    localMenu,
    globalMenu,
    mcbMenu,
    mallsMenu,
    setDispdraw,
    showMobileMenu,
    tabSelected,
    setTabSelected,
    selectedAddNew
  } = useContext(GlobalContex);

  useEffect(() => {
    setSelectedForm(selectedAddNew)
  }, [selectedAddNew])

  // const openside = (e) => {
  //   themail = e.currentTarget.id;
  //   value.setselectemail(e.currentTarget.id);
  //   value.setdispdraw("TranslateX(0%)");
  //   value.settriggerevent("none");
  // };

  const conditionalPages = () => {
    switch (selectedForm) {
      case "Category":
        return (
          <AddNewCategory />
        );
      case "Sub-Category":
        return (
          <AddNewSubCategory />
        );
      case "Filter":
        return <AddNewFilter />
      case "Brand":
        return <AddNewBrand />
      case "Product":
        return <AddNewProduct />
      case "Business":
        return <AddNewBusiness />
      case "Admin":
        return <AddNewAdmin />
      case "Pincode":
        return <AddNewPincode />
      case "Coupons":
        return <AddNewCoupon />
      case "State":
        return <AddNewState />
      case "City":
        return <AddNewCity />
      case "Area":
        return <AddNewArea />
      case "Logistic Pincode":
        return <AddNewLogisticPincode />
      case "Colony":
        return <AddNewColony />
      case "Factory":
        return <AddNewFactory />
      case "Factory License Type":
        return <AddNewFactoryLicenseType />
      case "Factory License":
        return <AddNewFactoryLicense />
      case "Factory Servicing City":
        return <AddNewFactoryServicingCity />
      case "Machine Category":
        return <AddNewMachineCategory />
      case "Machine":
        return <AddNewMachine />
      case "Vehicle Category":
        return <AddNewVehicleCategory />
      case "Vehicle":
        return <AddNewVehicle />
      case "Logistics Hub":
        return <AddLogisticsHub />
      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
    }
  };
  return <>
    <>
      <div className="desktopWrapper">
        <div className={collapse ? "grid-cap-collapsed" : "grid-cap"}>
          <GlobalSidebar globalMenu={globalMenu} />
          <LocalSidebar localMenu={mallsMenu} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TabsLayout />
            {/* <NavBar/> */}
            <div style={{}}>{conditionalPages()}</div>
          </div>
        </div>
      </div>
      {showMobileMenu ? (
        <MobileMenu />
      ) : (
        <div className="mobileWrapper">
          <TabsLayout />
          <div style={{}}>{conditionalPages()}</div>
        </div>
      )}
    </>
  </>
};

export default AddNewForms;
