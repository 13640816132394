import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
// import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const OrdersDrawer = ({ selectedProduct, setSelectedProduct }) => {
    const [step, setStep] = useState("default")
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [selectStatus, setSelectStatus] = useState("")
    const [onlineReferenceNo, setOnlineReferenceNo] = useState("")
    const [rowNo, setRowNo] = useState("")
    const [shippingId, setShippingId] = useState("")
    const [postOrder, setPostOrder] = useState(false)




    const { bankerEmail, setTabSelected, productSubDrawer, setProductSubDrawer, login, loginData,
        refetchProduct, setRefechProduct, productDrawerOpen, setProductsDrawerOpen, selectedBusiness, selectedOrder, setSelectedOrder,
        ordersDrawerOpen, setOrdersDrawerOpen, userDetails, setUserDetails } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Action");
        setPath(["Action"]);
    }, [ordersDrawerOpen]);


    let StatusFields = [
        {
            name: "Payment received",
            value: "paymentRecieved"
        },
        {
            name: "Payment cancelled",
            value: "paymentCancelled"
        },
        {
            name: "Order confirmed",
            value: "confirmedOrder"
        },
        {
            name: "Payment received",
            value: "paymentRecieved"
        },
        {
            name: "Manufacturing",
            value: "manufacuring"
        },
        {
            name: "Dispatch ready",
            value: "dispactchReady"
        },
        {
            name: "Dispatched",
            value: "dispactch"
        },
        {
            name: "Delivered",
            value: "delivered"
        },
    ]

    // useEffect(() => {
    //     fetchCurrentSubCategory()
    // }, [currentSubCategory])

    // useEffect(() => {
    //   console.log(ActionBtn, "ActionBtn")
    //   if (ActionBtn === "Actions") {
    //     setStep("Actions")
    //   } else{
    //     setStep("default")
    //   }
    // }, [step, ActionBtn])

    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);

    const signJwt = async (fileName, emailDev, secret) => {
        try {
            const jwts = await new jose.SignJWT({ name: fileName, email: emailDev })
                .setProtectedHeader({ alg: "HS512" })
                .setIssuer("gxjwtenchs512")
                .setExpirationTime("10m")
                .sign(new TextEncoder().encode(secret));
            return jwts;
        } catch (error) {
            console.log(error, "kjbedkjwebdw");
        }
    };

    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    const updateStatusField = () => {
        let body = {
            transactionId: selectedOrder?._id,
            businessusername: selectedBusiness?.businessusername,
            paymentStatus: selectStatus?.value
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Status...`)
        Axios.put(`https://frissly.apimachine.com/api/order/${selectedOrder?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setRefechProduct(!refetchProduct)
                    if (selectStatus?.value === "confirmedOrder") {
                        setStep("GoFrugal")
                        setPath([...path, "GoFrugal"]);
                    } else {
                        setStep("success")
                        setSuccessMsg("You have successfully update status. You will now be redirected to your updated orders list.")
                        setTabSelected("Orders")
                        setStep("default");
                        setOrdersDrawerOpen(false);
                    }
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }
    // parseInt(element?.product?.externalId)
    const updateSalesOrder = () => {
        let orderItems = []
        selectedOrder?.products?.forEach((element, index) => {
            if (element?.quantity !== 0) {
                orderItems.push({
                    rowNo: parseInt(rowNo),
                    itemId: parseInt(element?.product?.externalId),
                    itemReferenceCode: element?.product?.externalId,
                    salePrice: parseInt(element?.total?.$numberDecimal),
                    quantity: element?.quantity,
                    itemAmount: parseInt(element?.total?.$numberDecimal),
                    taxPercentage: 0
                })
            }
        });
        var keyToSum = 'quantity';

        var sum = orderItems.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue[keyToSum];
        }, 0);
        let body = {
            onlineReferenceNo: onlineReferenceNo,
            totalQuantity: parseInt(sum),
            totalAmount: parseInt(selectedOrder?.orderTotal?.$numberDecimal),
            totalTaxAmount: 0,
            totalDiscountAmount: 0,
            courierPartner: null,
            shippingName: userDetails?.name,
            shippingAddress1: userDetails?.deliveryAddresses?.[0]?.address1,
            shippingState: userDetails?.deliveryAddresses?.[0]?.state,
            shippingCountry: userDetails?.deliveryAddresses?.[0]?.country,
            shippingPincode: userDetails?.deliveryAddresses?.[0]?.pincode,
            shippingMobile: userDetails?.phone,
            shippingEmail: null,
            shipmentItems: parseInt(selectedOrder?.orderTotal?.$numberDecimal),
            shippingId: shippingId,
            appUserName: null,
            customerName: userDetails?.name,
            outletId: 3,
            customerPhone: userDetails?.phone,
            customerMobile: userDetails?.phone,
            customerEmail: userDetails?.email,
            orderItems
            // orderItems: [
            //     {
            //         rowNo: rowNo,
            //         itemId: selectedOrder?.products?.[0]?.product?.externalId,
            //         itemReferenceCode: selectedOrder?.products?.[0]?.product?.externalId,
            //         salePrice: selectedOrder?.products?.[0]?.total?.$numberDecimal,
            //         quantity: selectedOrder?.products?.[0]?.quantity,
            //         itemAmount: selectedOrder?.products?.[0]?.total?.$numberDecimal,
            //         taxPercentage: 0
            //     }
            // ]
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Status...`)
        Axios.post(`https://frissly.apimachine.com/api/order/sharan/tpa/order`, body, {
            headers: {
                'Content-Type': 'application/json',
                'X-Auth-Token': `10A7ABA7A856C847FB5BC784D923F732ABD22191D4268294E62FDB86C99E497C2806F3AB73EA374C`
            },
        })
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setRefechProduct(!refetchProduct)
                    setStep("success")
                    setSuccessMsg("You have successfully update status. You will now be redirected to your updated orders list.")
                    setTabSelected("Orders")
                    setStep("default");
                    setPath(["Action"]);
                    setOrdersDrawerOpen(false);
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }












    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );

            case "Confirmation":
                return (
                    <>
                        <div className="sidebarTitle">Are you sure?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={(e) => updateStatusField()}
                            >
                                <div>Yes Confirm</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={(e) => {
                                    setStep("Update order status");
                                    setPath(["Action", "Update order status"]);
                                }}
                            >
                                <div>Nevermind</div>
                            </div>
                        </div>
                    </>
                )

            case "GoFrugal":
                return (
                    <>
                        <div className="sidebarTitle">Do you want to post this sale to GoFrugal?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                style={{ background: postOrder ? "rgb(102, 105, 176)" : "", color: postOrder ? "white" : "" }}
                                onClick={() => setPostOrder(true)}
                            >
                                <div>Yes</div>
                            </div>
                            <div
                                className="sidebarCard"
                                onClick={(e) => {
                                    setStep("Default");
                                    setOrdersDrawerOpen(false);
                                    setPostOrder(false)
                                }}
                            >
                                <div>No</div>
                            </div>
                            <div className="sidebarTitle">Enter online reference number</div>
                            <div className="sidebarBox">
                                <input type="text" className="inputBox" value={onlineReferenceNo} onChange={(e) => setOnlineReferenceNo(e.target.value)} placeholder="Number.." />
                            </div>
                            <div className="sidebarTitle">Enter row number</div>
                            <div className="sidebarBox">
                                <input type="text" className="inputBox" value={rowNo} onChange={(e) => setRowNo(e.target.value)} placeholder="Number.." />
                            </div><div className="sidebarTitle">Shipping ID</div>
                            <div className="sidebarBox">
                                <input type="text" className="inputBox" value={shippingId} onChange={(e) => setShippingId(e.target.value)} placeholder="Number.." />
                            </div>
                            <div className="theBottomSubmitCard" onClick={() => updateSalesOrder()}>Submit</div>
                            <div className="theBottomCard" onClick={() => setPath(["Action"])}>Go Back</div>
                        </div>
                    </>
                )
            case "Update order status":
                return (
                    <>
                        <div className="sidebarTitle">What is the new status for this order?</div>
                        <div className="sidebarScroll">
                            {StatusFields.map((item, index) => {
                                return <div id={index}
                                    className="sidebarCard"
                                    onClick={() => {
                                        setSelectStatus(item)
                                        setStep("Confirmation");
                                        setPath([...path, "Confirmation"]);
                                    }}
                                >
                                    <div>{item?.name}</div>
                                </div>
                            })}

                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="sidebarTitle">What would you like to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update order status");
                                    setPath([...path, "Update order status"]);

                                }}
                            >
                                <div>Update order status</div>
                            </div>
                            <div
                                className="sidebarCard"
                            // onClick={() => {
                            //     setStep("Update Sub-Category");
                            //     setPath([...path, "Update Sub-Category"]);

                            // }}
                            >
                                <div>Edit order</div>
                            </div>
                            <div
                                className="sidebarCard"
                            // onClick={() => {
                            //     setStep("Update Brand");
                            //     setPath([...path, "Update Brand"]);
                            // }}
                            >
                                <div>Delete order</div>
                            </div>
                        </div>
                    </>
                )
        }
    }



    return (
        <div
            className={ordersDrawerOpen ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* <img
                  src={
                    selectedBusiness?.profile_pic
                      ? selectedBusiness?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {selectedOrder?._id}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* {path.length > 1 ? (
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => handleBackStep()}
                  >
                    <img src={back} alt="" />
                  </div>
                ) : (
                  ""
                )} */}
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            setOrdersDrawerOpen(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default OrdersDrawer;
