import { memo, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import classNames from "./globalNewSidebar.module.scss";
import backWhite from "../../static/images/icons/backWhite.svg";
import greenClose from "../../static/images/greenClose.svg";
import bondTier from "../../static/images/icons/bondTier.svg";
import sip from "../../static/images/icons/sip.svg";
import offerings from "../../static/images/sidebarIcons/offerings.svg";
import shares from "../../static/images/sidebarIcons/shares.svg";
import bonds from "../../static/images/sidebarIcons/bonds.svg";

import lxProfile from "../../static/images/sidebarIcons/lxProfile.svg";
import bankerProfile from "../../static/images/sidebarIcons/bankerProfile.svg";

import eternal from "../../static/images/sidebarIcons/Eternal.svg";
import fixedTerm from "../../static/images/sidebarIcons/FixedTerm.svg";
import venture from "../../static/images/sidebarIcons/Venture.svg";

import mcbBrand from "../../static/images/globaldrawer/mcb_brand.svg";
import mcbApp from "../../static/images/globaldrawer/mcb_app.svg";
import mcbConglomerate from "../../static/images/globaldrawer/mcb_conglomerate.svg";
import mcbApplite from "../../static/images/globaldrawer/mcb_applite.svg";
import mcbOtcdesks from "../../static/images/globaldrawer/mcb_otcdesks.svg";
import revenue from "../../static/images/sidebarIcons/revenue.svg";
import Asset from "../../static/images/sidebarIcons/assetimg.svg";

import ProductIcon from "../../static/images/addNewIcons/productIcon.svg"
import CategoryIcon from "../../static/images/addNewIcons/categoryIcon.svg"
import SubCategoryIcon from "../../static/images/addNewIcons/subCategoryIcon.svg"
import FilterIcon from "../../static/images/addNewIcons/filterIcon.svg"
import ProductFieldIcon from "../../static/images/addNewIcons/ProductFieldIcon.svg"
import BrandIcon from "../../static/images/addNewIcons/brand.svg"
import AdminIcon from "../../static/images/addNewIcons/AdminIcon.svg"
import ManagerIcon from "../../static/images/addNewIcons/ManagerIcon.svg"
import nonSupportIcon from "../../static/images/addNewIcons/nonSupportIcon.svg"
import supportIcon from "../../static/images/addNewIcons/supportIcon.svg"
import deliveryIcon from "../../static/images/addNewIcons/deliveryIcon.svg"
import pincodeIcon from "../../static/images/addNewIcons/pincodeIcon.svg"
import couponIcon from "../../static/images/addNewIcons/couponIcon.svg"




import NewShareToken from "./NewShareToken";
import NewBondTier from "./NewBondTier";
import Eternal from "./NewEternal";
import publication from "../../static/images/addNewIcons/publication.svg";
import user from "../../static/images/addNewIcons/user.svg";
import author from "../../static/images/addNewIcons/author.svg";
import article from "../../static/images/addNewIcons/article.svg";
import video from "../../static/images/addNewIcons/video.svg";
import category from "../../static/images/addNewIcons/category.svg";
import navBar from "../../static/images/addNewIcons/navBar.svg";
import webStory from "../../static/images/addNewIcons/webstory.svg";
import company from "../../static/images/addNewIcons/company.svg";
import WappIco from "../../static/images/addNewIcons/WappIco.svg";
import CourseIcon from "../../static/images/addNewIcons/newCourseIco.svg"
import BusinessIcon from "../../static/images/addNewIcons/BusinessIcon.svg"
import { GlobalContex } from "../../globalContex";
import { useContext } from "react";
import NewBond from "./NewBond";
import NewLxProfile from "./NewLxProfile";
import NewBankerProfile from "./NewBankerProfile";
import NewSip from "./NewSip";
import NewMcbApp from "./NewMcbApp";
import NewMcbBrand from "./NewMcbBrand";
import NewAppData from "./NewAppData";
import NewWithdrawal from "./NewWithdrawal";
import NewAsset from "./NewAsset";
import NewPublication from "./NewPublication";
import NewAuthor from "./NewAuthor";
import NewArticle from "./NewArticle";
import NewNavBar from "./NewNavBar";
import NewCategory from "./NewCategory";
import NewVideo from "./NewVideo";
import NewWebStory from "./NewWebStory";
import NewCompany from "./NewCompany";
import NewCaseStudy from "./NewCaseStudy";
import NewReport from "./NewReport";
import NewDocumentary from "./NewDocumentary";
import NewCourse from "./NewCourse";
import NewCourseSection from "./NewCourseSection";
import NewCourseVideo from "./NewCourseVideo";
import NewWebinar from "./NewWebinar";
import NewProduct from "./NewProduct";
import { message } from "antd";

function GlobalDrawer({ onClose, pathname, onClick }) {
  const { globalMenuAdd, setGlobalMenuAdd, licenseCheck, selectedBusiness, slider,
    setSlider, step, setStep, mainMenu,
    setMainMenu, setAddNewField, selectedAddNew, setSelectedAddNew } =
    useContext(GlobalContex);

  const [query, setQuery] = useState("");
  const [subMenu, setSubMenu] = useState("");

  const [menuList, setMenuList] = useState([]);
  const [menuList1, setMenuList1] = useState([
    {
      name: "Business",
      icon: BusinessIcon,
      enabled: true,
    },
    // {
    //   name: "Manager ",
    //   icon: ManagerIcon,
    //   enabled: true,
    // },
    // {
    //   name: "Non-Support Staff ",
    //   icon: nonSupportIcon,
    //   enabled: true,
    // },
    // {
    //   name: "Support Staff ",
    //   icon: supportIcon,
    //   enabled: true,
    // },
    // {
    //   name: "Delivery",
    //   icon: deliveryIcon,
    //   enabled: true,
    // },
    // {
    //   name: "",
    //   icon: "",
    //   enabled: true,
    // }
  ])
  const [menuList2, setMenuList2] = useState([
    {
      name: "Admin",
      icon: AdminIcon,
      enabled: true,
    }
  ])
  const [menuList3, setMenuList3] = useState([
    {
      name: "Pincode",
      icon: pincodeIcon,
      enabled: true,
    }
  ])
  const [menuList4, setMenuList4] = useState([
    {
      name: "Coupons",
      icon: couponIcon,
      enabled: true,
    }
  ])
  const [menuList5, setMenuList5] = useState([
    {
      name: "State",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "City",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Area",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Logistic Pincode",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Colony",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Factory",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Factory License Type",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Factory License",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Factory Servicing City",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Machine Category",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Machine",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Vehicle Category",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Vehicle",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "Logistics Hub",
      icon: couponIcon,
      enabled: true,
    },
    {
      name: "",
      icon: "",
      enabled: "",
    }
  ])
  const [drawerTitle, setDrawerTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(pathname, "jbdkjwqed");
    if (pathname === "/Admin") {
      setMenuList([
        {
          name: "Product",
          icon: ProductIcon,
          enabled: true,
        },
        {
          name: "Category",
          icon: CategoryIcon,
          enabled: true,
        },
        {
          name: "Sub-Category",
          icon: SubCategoryIcon,
          enabled: true,
        },
        {
          name: "Filter",
          icon: FilterIcon,
          enabled: true,
        },
        // {
        //   name: "Product Field",
        //   icon: ProductFieldIcon,
        //   enabled: true,
        // },
        {
          name: "Brand",
          icon: BrandIcon,
          enabled: true,
        },
        {
          name: "",
          icon: "",
          enabled: "",
        },
      ]);
    }
  }, []);

  useEffect(() => {
    switch (pathname) {
      default:
        setDrawerTitle("New Item");
        break;
    }
  }, []);

  const handleMenuClick = (name) => {
    console.log(name.length)
    setSelectedAddNew(name)
    setSlider(false);
    setAddNewField(true)
  }

  const content = useMemo(() => {
    switch (mainMenu) {
      case "LX Profile":
        return (
          <NewLxProfile
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Banker Profile":
        return (
          <NewBankerProfile
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "ShareToken":
        return (
          <NewShareToken
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case "Eternal":
        return <Eternal step={step} setStep={setStep} setMainMenu={setMainMenu}
        />;
      case "Bond Tier":
        return (
          <NewBondTier
            step={step}
            setStep={setStep}
            loading={loading}
            setMainMenu={setMainMenu}
            setLoading={setLoading}
          />
        );

      case "Bond":
        return (
          <NewBond
            step={step}
            setStep={setStep}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Sip":
        return (
          <NewSip
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case "App":
        return (
          <NewMcbApp
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Brand":
        return (
          <NewMcbBrand
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "App Data":
        return (
          <NewAppData
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Withdrawal":
        return (
          <NewWithdrawal
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Asset":
        return (
          <NewAsset
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Publication":
        return (
          <NewPublication
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Author":
        return (
          <NewAuthor
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Article":
        return (
          <NewArticle
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Navbar":
        return (
          <NewNavBar
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Category":
        return (
          <NewCategory
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Video":
        return (
          <NewVideo
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Web Story Template":
        return (
          <NewWebStory
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Company":
        return (
          <NewCompany
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "CaseStudy":
        return (
          <NewCaseStudy
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Report":
        return (
          <NewReport
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Documentary":
        return (
          <NewDocumentary
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Course":
        return (
          <NewCourse
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case "Course Section":
        return (
          <NewCourseSection
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case "Course Video":
        return (
          <NewCourseVideo
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );
      case "Webinar":
        return (
          <NewWebinar
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      case "Product":
        return (
          <NewProduct
            step={step}
            setStep={setStep}
            setMainMenu={setMainMenu}
            loading={loading}
            setLoading={setLoading}
          />
        );

      default:
        return (
          <>
            {/* <label className={classNames.searchBox}>
              <input
                type="text"
                placeholder="Search For New Item To Add1.."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
              />
            </label> */}
            <div className={classNames.verticalScrollBox}>
              <p className={classNames.titleName}>Products</p>
              <div style={{ justifyContent: "space-between", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {menuList
                  ?.filter((o) =>
                    o?.name?.toLowerCase().includes(query?.toLowerCase())
                  )
                  ?.map(({ name, icon }) => (
                    <ItemBox
                      name={name}
                      icon={icon}
                      onClick={() => handleMenuClick(name)}
                    />
                  ))}
              </div>
              <p className={classNames.titleName}>Business</p>
              <div style={{ justifyContent: "space-between", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {menuList1
                  .map(({ name, icon }) => (
                    <ItemBox
                      name={name}
                      icon={icon}
                      onClick={() => handleMenuClick(name)}
                    />
                  ))}
              </div>
              <p className={classNames.titleName}>Personalle</p>
              <div style={{ justifyContent: "space-between", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {menuList2
                  .map(({ name, icon }) => (
                    <ItemBox
                      name={name}
                      icon={icon}
                      onClick={() => handleMenuClick(name)}
                    />
                  ))}
              </div>
              <p className={classNames.titleName}>Delivery</p>
              <div style={{ justifyContent: "space-between", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {menuList3
                  .map(({ name, icon }) => (
                    <ItemBox
                      name={name}
                      icon={icon}
                      onClick={() => handleMenuClick(name)}
                    />
                  ))}
              </div>
              <p className={classNames.titleName}>Promotions</p>
              <div style={{ justifyContent: "space-between", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {menuList4
                  .map(({ name, icon }) => (
                    <ItemBox
                      name={name}
                      icon={icon}
                      onClick={() => handleMenuClick(name)}
                    />
                  ))}
              </div>
              <p className={classNames.titleName}>New Lists</p>
              <div style={{ justifyContent: "space-between", display: "flex", width: "100%", flexWrap: "wrap" }}>
                {menuList5
                  .map(({ name, icon }) => (
                    <ItemBox
                      name={name}
                      icon={icon}
                      onClick={() => handleMenuClick(name)}
                    />
                  ))}
              </div>
            </div>
          </>
        );
    }
  }, [mainMenu, step, setStep, menuList, menuList1, loading, setLoading, query, setQuery]);

  return (
    <div className={classNames.GlobalDrawer} style={{ transform: slider ? "TranslateX(0%)" : "TranslateX(100%)" }}>
      <div
        // className={classNames.overlay}
        onClick={() => {
          try {
            // onClose();
            setStep("");
            setMainMenu("");
            setSlider(false);
          } catch (error) { }
        }}
      />
      <div
        // initial={{ x: "32%", opacity: 0 }}
        // animate={{ x: 0, opacity: 1 }}
        // exit={{ x: "32%", opacity: 0 }}
        className={classNames.sidebar}
        style={{ transform: slider ? "TranslateX(0%)" : "TranslateX(100%)" }}
      // style={{ width: wideDrawer ? "750px" : "450px" }}
      // style={{ width: "300px", position: "fixed", right: 0 }}
      >
        {loading ||
          step === "Success" ||
          step === "Token Expired" ||
          step === "Failed" ? (
          ""
        ) : (
          <div className={classNames.header}>
            <div className={classNames.content}>
              <div className={classNames.title}>
                {mainMenu ? "New" + " " + mainMenu : "New Item"}
              </div>
              <div className={classNames.breadCrumbs}>
                <span
                  onClick={() => {
                    setStep("");
                    setMainMenu("");
                  }}
                >
                  Add New
                </span>
                {mainMenu && (
                  <>
                    -&gt;&nbsp;
                    <span
                      onClick={() => {
                        setStep("");
                      }}
                    >
                      {mainMenu}
                    </span>
                  </>
                )}
                {step && (
                  <>
                    -&gt;&nbsp;
                    <span>{step}</span>
                  </>
                )}
              </div>
            </div>
            {/* <div
              className={classNames.backBtn}
              onClick={(e) => {
                if (step !== "") {
                  setStep("");
                } else {
                  setMainMenu("");
                }
              }}
            >
              <img src={backWhite} alt="" />
            </div> */}
            <div
              className={classNames.closeBtn}
              onClick={() => {
                try {
                  setSlider(false);
                  setStep("");
                  setMainMenu("");
                } catch (error) { }
              }}
            >
              <img src={greenClose} alt="" />
            </div>
          </div>
        )}

        {content}
      </div>
    </div>
  );
}

function ItemBox({ name, icon, onClick }) {
  return (
    <div
      style={{ visibility: name ? "unset" : "hidden", pointerEvents: name === "Product Field" ? "none" : "" }}
      className={classNames.itemBox}
    >
      <div className={classNames.globalDrawerImg} onClick={onClick}>
        <img src={icon} alt="" />
      </div>
      <div className={classNames.label} style={{ paddingTop: "10px" }}>
        {name}
      </div>
    </div>
  );
}

export default memo(GlobalDrawer);
