import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewFactoryServicingCity = () => {
    const tabs = ["Add Factory Servicing City"];
    const [licenseNumber, setLicenseNumber] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [description, setDescription] = useState("")
    const [updatedData, setUpdatedData] = useState("");
    const [imgLoading, setImgLoading] = useState(false);
    const [licenseDocument, setLicenseDocument] = useState("")
    const [gstCode, setGstCode] = useState("")
    const [allFactories, setAllFactories] = useState([])
    const [allCities, setAllCities] = useState([])
    const [selectedFactory, setSelectedFactory] = useState("")
    const [selectedCity, setSelectedCity] = useState("")



    const { tabSelected, setTabSelected, setAddNewField, factoryServicingCityTrigger, setfactoryServicingCityTrigger, selectedBusiness, cityTrigger, setCityTrigger } = useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add Factory Servicing City");
        console.log(selectedBusiness?._id, "businessDetail")
        axios.get(
            `https://frissly.apimachine.com/api/business/logistics/get/factory?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setAllFactories(data.data);
            }
        });
        axios.get(
            `https://frissly.apimachine.com/api/business/logistics/get/city?businessId=${selectedBusiness?._id}`
        ).then(({ data }) => {
            if (data.status) {
                setAllCities(data.data);
            }
        });
    }, []);

    useEffect(() => {
        if (factoryServicingCityTrigger) {
            console.log(selectedFactory , selectedCity , "factory11")
            if (allFactories.length > 0) {
                setApiLoading(true)
                let body = {
                    businessId: selectedBusiness?._id,
                    factoryId: !selectedFactory ? allFactories?.[0]?._id : selectedFactory,
                    cityId: !selectedCity ? allCities?.[0]?._id : selectedCity
                }
                console.log(body, "fwetwe")
                if (body.factoryId) {
                    axios.post("https://frissly.apimachine.com/api/business/logistics/create/factory/servicing/city", body)
                        .then(({ data }) => {
                            if (data.status) {
                                toast.success("Factory Servicing City Added Successfully")
                                setAllFactories([])
                                setTimeout(() => {
                                    setAddNewField(false)
                                    // navigate("/Admin")
                                    setTabSelected("Staff")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setfactoryServicingCityTrigger(false)
    }, [factoryServicingCityTrigger])

    function renameFile(originalFile, newName) {
        return new File([originalFile], newName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });
    }
    const uploadImage = async (e, setUpdatedData, setLoading) => {
        setLoading(true);
        const fileName = `${new Date().getTime()}${e.target.files[0].name.substr(
            e.target.files[0].name.lastIndexOf(".")
        )}`;
        const formData = new FormData();
        const file = renameFile(e.target.files[0], fileName);
        formData.append("files", file);

        let { data } = await axios.post(
            `https://publications.apimachine.com/article/upload`,
            formData,
            {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                },
            }
        );
        setUpdatedData(data[0].urlName);
        setLoading(false);
    };



    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        {/* <p className="categoryName">License Number</p>
                        <input type="text" value={licenseNumber} onChange={(e) => setLicenseNumber(e.target.value)} className="inputStyle" placeholder="Add License Number...." /> */}
                        {/* <p className="categoryName">Description</p>
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} rows="6" className="inputStyle" placeholder="Add Description..." ></textarea> */}
                        {/* <p className="categoryName topmargin">License Document</p>
                        <input type="text" value={licenseDocument} onChange={(e) => setLicenseDocument(e.target.value)} className="inputStyle" placeholder="Add License Document...." /> */}
                        <p className="categoryName topmargin">Select Factory</p>
                        <div className="select-wrapper">
                            <select name="factory" className="selectStyle" value={selectedFactory} onChange={(e) => setSelectedFactory(e.target.value)}>
                                {allFactories.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div>
                        <p className="categoryName topmargin">Select City</p>
                        <div className="select-wrapper">
                            <select name="city" className="selectStyle" value={selectedCity} onChange={(e) => setSelectedCity(e.target.value)}>
                                {allCities.map((e) => {
                                    return <>
                                        <option value={e._id}>{e?.name}</option>
                                    </>
                                })}
                            </select>
                        </div>

                        {/* <p className="categoryName topmargin">GST code</p>
                        <input type="text" value={gstCode} onChange={(e) => setGstCode(e.target.value)} className="inputStyle" placeholder="Add Gst Code...." /> */}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewFactoryServicingCity;