import axios from "axios";
import React, { Fragment, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
import { GlobalContex } from "../../globalContex";

function ProductStatusList({ onClose }) {
    const {
        bankerEmail,
        selectedAssetFilters,
        setSelectedAssetFilters,
        selectedStatusFilters,
        setSelectedStatusFilters,
        selectedLengthFilter,
        setSelectedLengthFilter,
        selectedBusiness,
        selectedCustomer,
        setSelectedCustomer,
        statusValues,
        selectedProductStatus , 
        setSelectedProductStatus
    } = useContext(GlobalContex);
    const [search, setSearch] = useState("");
    const [allAssets, setAllAssets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [customersData, setCustomersData] = useState([])

    // useEffect(() => {
    //     setLoading(true);
    //     axios
    //         .get(
    //             `https://frissly.apimachine.com/api/order/business/user/?businessusername=${selectedBusiness?.businessusername}`
    //         )
    //         .then(({ data }) => {
    //             setCustomersData(data.data)
    //             setLoading(false);
    //         })
    // }, []);

   

    return (
        <Fragment>
            <div className="filterTitle">Select the status type</div>
            <div className="filterSearch">
                <input
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    placeholder="Search Status....|"
                />
            </div>
            <div className="filterCoinCard">
                {loading
                    ? Array(6)
                        .fill("")
                        .map((_, i) => (
                            <div className="cardBody" key={i}>
                                <Skeleton className="dp coinImage" circle />
                                {/* <img className="coinImage" src={item.icon} alt="" /> */}
                                <div className="coinDetail">
                                    <Skeleton className="name" width={200} />
                                    <Skeleton className="email" width={200} />
                                </div>
                            </div>
                        ))
                    : statusValues
                        ?.filter(
                            (item) =>
                                item
                                    ?.toLowerCase()
                                    .includes(search.toLowerCase())
                        )
                        ?.map((item) => (
                            <div
                                className="cardBody"
                                key={item}
                                onClick={(e) => setSelectedProductStatus(item)}
                                style={{
                                    background: selectedProductStatus === item
                                        ? "whitesmoke"
                                        : "",
                                }}
                            >
                                {/* <img className="coinImage" src={item.icon} alt="" /> */}
                                <div className="coinDetail">
                                    <div className="name">{item}</div>
                                    {/* <div className="email">
                                        {item._id}
                                    </div> */}
                                </div>
                            </div>
                        ))}
                <div className="space"></div>
            </div>
        </Fragment>
    );
}

export default ProductStatusList;
