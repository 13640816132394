import React, { useContext, useEffect, useState } from "react";
import "../AddNewCategory/category.scss";
import { GlobalContex } from "../../globalContex";
import NavBar from "../../globalComponents/NavBar";
import axios from "axios";
import cloudUploadIcon from "../../static/images/clipIcons/cloudUpload.svg";
import loadingGif from "../../static/images/loading.gif";
import CloseIcon from "../../static/images/close.svg"
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";




const AddNewAdmin = () => {
    const tabs = ["Add Admin"];
    const [name, setName] = useState("")
    const [apiLoading, setApiLoading] = useState(false)
    const [username, setUserName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [email, setEmail] = useState("")
    const navigate = useNavigate();



    const { tabSelected, setTabSelected, setAddNewField, adminTrigger, setAdminTrigger, selectedBusiness } = useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Add Admin");
        console.log(selectedBusiness?._id, "businessDetail")
        // axios.get(
        //     `https://frissly.apimachine.com/api/business`
        // ).then(({ data }) => {
        //     if (data.status) {
        //         setFetchUsers(data.data);
        //         setUserName(data?.data[0]?.businessusername)
        //     }
        // });
    }, []);

    useEffect(() => {
        if (adminTrigger) {
            if (name) {
                setApiLoading(true)
                let body = {
                    email: email,
                    phone: phoneNumber,
                    userName: username,
                    name: name,
                    designation: "Admin"
                }
                console.log(body, "fwetwe")
                if (body.name || body.email) {
                    axios.post("https://frissly.apimachine.com/api/user/business/create", body)
                        .then(({ data }) => {
                            if (data.status) {
                                // toast.success("Admin Added Successfully")
                                let theSecondBody = {
                                    userId: data.data._id,
                                    businessId: selectedBusiness?._id
                                }
                                axios.post("https://frissly.apimachine.com/api/user/business/mapping/create", theSecondBody)
                                    .then(({ data }) => {
                                        if (data.status) {
                                            toast.success("Admin Added Successfully")
                                        }
                                    })
                                setTimeout(() => {
                                    setAddNewField(false)
                                    // navigate("/Admin")
                                    setTabSelected("Staff")
                                }, 1500)
                            } else {
                                toast.error(data?.message || "Something went wrong")
                            }
                        })
                        .catch((err) => {
                            toast.error("API Error");
                        })
                }
                setApiLoading(false)
            }
        }
        setAdminTrigger(false)
    }, [adminTrigger])





    return (
        <>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
                apiLoading={apiLoading}
                setApiLoading={setApiLoading}
            />
            <div className="formDisplayData">
                <div>
                    <div className="basicForm">
                        <p className="categoryTitle">Basic Details</p>
                        <p className="categoryName">Name</p>
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} className="inputStyle" placeholder="Add Name...." />
                        <p className="categoryName topmargin">Create a retailers.app personalle username</p>
                        <input type="text" value={username} onChange={(e) => setUserName(e.target.value)} className="inputStyle" placeholder="Add personalle username...." />
                        <p className="categoryName topmargin">Enter phone number</p>
                        <input type="text" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} className="inputStyle" placeholder="Add phone number..." />
                        <p className="categoryName topmargin">Enter Email</p>
                        <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} className="inputStyle" placeholder="Add email..." />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
};

export default AddNewAdmin;