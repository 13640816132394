import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
// import "./sold.scss";

import { GlobalContex } from "../../../../globalContex";

import copyToClipboard from "../../../../static/images/icons/copy.svg";
import defaultImg from "../../../../static/images/icons/app_placeholder.png";
import { message } from "antd";
// import ActionIndex from "./CategorySubDrawer/ActionIndex"
// import ProductDataSubDrawer from "./ProductsDataSubDrawer"

const Customers = () => {
    const { loginData, globalSearch, collapse, getDisplayDate, getDays, fetchProductData, setFetchProductData, productSubDrawer, setProductSubDrawer, refetchProduct, setRefechProduct, selectedBusiness, productDrawerOpen, setProductsDrawerOpen, TheFormData, setTheFormData,
        productForm, setSelectedFormData, fetchForm, setFetchForm } = useContext(GlobalContex);

    const [allUsers, setAllUsers] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState("")
    //   const [page, setPage] = useState(0);

    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;

    // useEffect(() => {
    //   setDataLoading(true);
    //   console.log(Math.ceil((window.innerHeight - 175) / 90), "innerHeight")
    //   let limit = Math.ceil((window.innerHeight - 175) / 90) + 1;
    //   axios
    //     .get(
    //       `https://comms.globalxchange.io/coin/vault/service/users/holdings/data/get?skip=0&limit=${limit}`
    //     )
    //     .then((res) => {
    //       setAllMarketsverseUsers(res.data.users);
    //       setDataLoading(false);
    //     });
    // }, [])

    const fetchData = async () => {
        // console.log(pageNumber, "page")
        setDataLoading(true);

        // console.log("call API")

        setDataLoading(true);

        try {
            const response = await fetch(`https://frissly.apimachine.com/api/order/business/user/?businessusername=${selectedBusiness?.businessusername}`);
            const jsonData = await response.json();
            const newData = jsonData.data;

            setAllUsers(newData);
            console.log(newData, "newData")
        } catch (error) {
            console.error('Error fetching data:', error);
        }

        setDataLoading(false);

    };






    useEffect(() => {
        fetchData();
    }, [fetchProductData, refetchProduct, setRefechProduct, selectedBusiness]);



    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid,
        showSubDraw
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div id="usersData"
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflow: 'auto'
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.filter(
                                    (item) =>
                                        item?.username
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase()) ||
                                        item?.email
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase())).map((item) => {
                                                return contentSection(item, "listDataGrid", desktopDataGrid);
                                            })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        {/* <ProductDataSubDrawer selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} /> */}
                    </div>
                </div>

                <div className="mobileWrapper">
                    <div style={{ overflowY: "scroll", height: "80vh" }}>
                        {headerSection("listGridMobile", mobileDataGrid)}

                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data?.map((item, index) => {
                                    return contentSection(
                                        item,
                                        "listDataGridMobile",
                                        mobileDataGrid
                                    );
                                })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", mobileDataGrid)
                        )}
                        {/* <ProductDataSubDrawer selectedProduct={selectedProduct} setSelectedProduct={setSelectedProduct} /> */}

                    </div>
                </div>
            </>
        );
    };

    // Change these three Sections according to the design

    const headerSection = (gridClass, gridValues) => {
        return (
            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Name</div>
                <div style={{ textAlign: "left" }}>Contact</div>
                <div style={{ textAlign: "left" }}>Total Spent</div>
                <div style={{ textAlign: "left" }}></div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues) => {
        return (
            <div

                className={gridClass}
                style={{
                    gridTemplateColumns: gridValues,
                }}
            >
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>
                    <div>
                        <div className="title">{item?.name}&nbsp;</div>
                        <div className="subtitle">{item?._id}</div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                }}>
                    <div>
                        <div className="title">{item?.phone}&nbsp;</div>
                        <div className="subtitle">{item?.email}</div>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                }}>
                    <div>
                        <div className="title">--</div>
                    </div>
                </div>

                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginRight: "20px",
                    }}
                >
                    <div className="title">{item?.toDisplay}</div>
                </div> */}
                <div className="btngrp">
                    <button className="actionbtn"
                    // onClick={(e) => {
                    //     setSelectedProduct(item);
                    //     setProductsDrawerOpen(true);
                    // }}
                    >Actions</button>
                    <button className="actionbtn" style={{ width: "135px" }}
                    // onClick={() => {
                    //     setTheFormData(true)
                    //     setSelectedFormData("Product")
                    //     setFetchForm(item)
                    // }}
                    >
                        Customer page</button>
                </div>
            </div >
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                        }}
                    >
                        <div key={i} style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={120} />
                            {/* <Skeleton width={80} style={{ height: "10px" }} /> */}
                        </div>
                        {/* <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={70} />
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start"
                        }}>
                            <Skeleton width={100} />
                        </div> */}
                    </div>
                );
            });
    };

    return (
        <div>
            {conditionalResposiveView(
                allUsers,
                dataLoading,
                "1fr 1fr 1fr 1fr", // Desktop view Grid columns
                "250px 200px 200px" // Mobile view Grid columns
            )}
        </div>
    );
};

export default Customers;
