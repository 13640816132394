import React, { useMemo, useState, useEffect, useContext } from "react";

import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import MachineCategory from "./MachineCategory"
import Machine from "./Machine";
import VehicleCategory from "./VehicleCategory";
import Vehicle from "./Vehicle"
import LogisticsHub from "./LogisticsHub"


const MachineAndVehicle = () => {
    const tabs = ["Machine Category", "Machine", "Vehicle Category", "Vehicle", "Logistics Hub"];

    const { setShowSubDraw, tabSelected, setTabSelected, selectedAddNew, setSelectedAddNew, setEditProductTrigger } =
        useContext(GlobalContex);

    useEffect(() => {
        setTabSelected("Machine Category");
        setEditProductTrigger(false)
    }, []);

    const tabComponent = useMemo(() => {
        switch (tabSelected) {
            case "Machine Category":
                return <MachineCategory />;
            case "Machine":
                return <Machine />;
            case "Vehicle Category":
                return <VehicleCategory />;
            case "Vehicle":
                return <Vehicle />;
            case "Logistics Hub":
                return <LogisticsHub />;
            default:
                return (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: "70vh",
                        }}
                    >
                        Coming Soon
                    </div>
                );
        }
    }, [
        tabSelected,
        // openCoinFilter, refetchApi
    ]);

    return (
        <div>
            <NavBar
                tabs={tabs}
                tabSelected={tabSelected}
                setTabSelected={setTabSelected}
                enabledFilters={[true, true, true, false, false, true]}
            />

            {tabComponent}

            {/* <div style={{ width: "100%" }}>
        <div className="listGrid">
          <div>Asset</div>
          <div>Cost</div>
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
        </div>
      </div> */}
            {/* <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >

        <Subdrawer />
      </div> */}
        </div>
    );
};

export default MachineAndVehicle;
