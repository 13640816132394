import Axios from "axios";
import React, { Fragment, useEffect, useState,useContext } from "react";
import Scrollbars from "react-custom-scrollbars";
import Skeleton from "react-loading-skeleton";
// import { GlobalContex } from "../../../../globalContex";
import { GlobalContex } from "../../../globalContex";



const CurrencyList = ({ setSelectCurrency, onClose }) => {
  const [allCountry, setAllCountry] = useState([]);
  const [assetLoading, setAssetLoading] = useState(true);
  const { selectedBusiness } = useContext(GlobalContex)
  useEffect(() => {
    let pubdata
    if (selectedBusiness) {
      pubdata = selectedBusiness._id
    } else {
      pubdata = "63a1a2c60e46260e093cf260"
    }
    Axios.get(`https://comms.globalxchange.io/coin/vault/get/all/coins`)
      .then((res) => {
        if (res.data.status) setAllCountry(res.data.coins);
      })
      .finally(() => setAssetLoading(false));
  }, []);
  const [search, setSearch] = useState("");
  return (
    <Fragment>
      {/* <div className="titleOp">Select Issuance Country</div> */}
      <div className="searchWrap" style={{ borderRadius: "35px", marginBottom: "25px", height: "69px" }}>
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type="text"
          placeholder="Search Your Courses.."
        />
      </div>
      <Scrollbars className="searchList" style={{ border: "none" }}>
        {assetLoading
          ? Array(6)
            .fill("")
            .map((_, i) => (
              <div className="countryuser" key={i}>
                <Skeleton className="dp" circle />
                <div className="userDetail">
                  <Skeleton className="name" width={200} />
                  <Skeleton className="email" width={200} />
                </div>
              </div>
            ))
          : allCountry
            .filter(
              (item) =>
                item.coinSymbol?.toLowerCase().includes(search.toLowerCase())
            )
            .map((item) => (
              <div
                className="countryuser"
                key={item._id}
                onClick={() => {
                  setSelectCurrency(item);
                  onClose();
                }}
              >
                <div className="userDetail">
                  <div className="name">{item.coinSymbol}</div>
                  {/* <div className="email">{banker.email}</div> */}
                </div>
              </div>
            ))}
        <div className="space"></div>
      </Scrollbars>
    </Fragment>
  );
};

export default CurrencyList;
