import React, { useMemo, useState, useEffect, useContext } from "react";

import Skeleton from "react-loading-skeleton";
import NavBar from "../../../globalComponents/NavBar";
import { GlobalContex } from "../../../globalContex";
import "../../../static/scss/list.scss";
import Products from "./Products";
import Categories from "./Categories";
import SubCategories from "./SubCategories"
import Filters from "./Filter"
import Brand from "./Brand";
import Sub2Categories from "./Sub2Categories";
import Businesses from "./Businesses";

const Stores = () => {
  const tabs = ["Businesses", "Categories", "Sub-Categories", "Sub2-Categories", "Filters", "Brands"];

  const { setShowSubDraw, tabSelected, setTabSelected, selectedAddNew, setSelectedAddNew, selectedFormData, setSelectedFormData } =
    useContext(GlobalContex);

  useEffect(() => {
    if (selectedFormData && selectedFormData !== "Product") {
      setTabSelected(selectedFormData)
    } else {
      if (selectedAddNew === "Sub-Category") {
        setTabSelected("Sub-Categories")
      } else if (selectedAddNew === "Filter") {
        setTabSelected("Filters")
      } else if (selectedAddNew === "Brand") {
        setTabSelected("Brands")
      } else {
        setTabSelected("Businesses");
      }
      setTimeout(() => {
        setSelectedAddNew("")
      }, 100)
    }
    setTimeout(() => {
      setSelectedFormData("")
    }, 100)
  }, []);

  const tabComponent = useMemo(() => {
    switch (tabSelected) {
      case "Businesses":
        return <Businesses />;
      case "Categories":
        return <Categories />;
      case "Sub-Categories":
        return <SubCategories />;
      case "Sub2-Categories":
        return <Sub2Categories />;
      case "Filters":
        return <Filters />;
      case "Brands":
        return <Brand />;

      default:
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: 700,
              fontSize: "20px",
              height: "70vh",
            }}
          >
            Coming Soon
          </div>
        );
    }
  }, [
    tabSelected,
    // openCoinFilter, refetchApi
  ]);

  return (
    <div>
      <NavBar
        tabs={tabs}
        tabSelected={tabSelected}
        setTabSelected={setTabSelected}
        enabledFilters={[true, true, true, false, false, true]}
      />

      {tabComponent}

      {/* <div style={{ width: "100%" }}>
        <div className="listGrid">
          <div>Asset</div>
          <div>Cost</div>
          <div>Length</div>
          <div>Daily Return</div>
          <div>Monthly Return</div>
        </div>
      </div> */}
      {/* <div
        style={{
          // display: "flex",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >

        <Subdrawer />
      </div> */}
    </div>
  );
};

export default Stores;
