import React, { useContext, useState } from "react";
// import "../../../../../static/scss/subdrawer.scss";
// import "./subdrawer.module.scss"
// import "./subdrawer.scss"
import close from "../../../../../static/images/icons/close.svg";
import LoadingAnimation from "../../../../../globalComponents/LoadingAnimation";
import PurpleClose from "../../../../../static/images/purpleClose.svg"
import lock from "../../../../../static/images/icons/lock.svg";
import Axios from "axios";
import * as jose from "jose";
import loadingGif from "../../../../../static/images/loading.gif";
import cloudUploadIcon from "../../../../../static/images/clipIcons/cloudUpload.svg";
import uploadGroupIcon from "../../../../../static/images/uploadGroupIcon.svg"
import { ToastContainer, toast } from "react-toastify";
import { GlobalContex } from "../../../../../globalContex";
import { useEffect } from "react";
import purpleTick from "../../../../../static/images/icons/purpleTick.svg"

function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}

const secret = "uyrw7826^&(896GYUFWE&*#GBjkbuaf"; // secret not to be disclosed anywhere.
const emailDev = "rahulrajsb@outlook.com"; // email of the developer.


const BusinessDrawer = ({ selectedCategory }) => {
    const [step, setStep] = useState("default")
    const [name, setName] = useState("");
    const [username, setUsername] = useState("")
    const [bio, setBio] = useState("");
    const [linkedIn, setLinkedIn] = useState("");
    const [twitter, setTwitter] = useState("");
    const [country, setCountry] = useState("");
    const [colouredIcon, setColouredIcon] = useState("");
    const [colouredIconLoading, setColouredIconLoading] = useState("");
    const [path, setPath] = useState([]);
    const [successMsg, setSuccessMsg] = useState("")
    const [loadingtext, setloadingtext] = useState("")
    const [allCategories, setAllCategories] = useState([])
    const [cateNum, setCateNum] = useState(0)
    const [deliveryData, setDeliveryData] = useState([])
    const [checkDelivery, setCheckDelivery] = useState("")
    const [selectedDelivery, setSelectedDelivery] = useState("")
    const deliveryCards = [
        {
            key: "Distance based",
            value: "distanceBased"
        },
        {
            key: "Percentage of product",
            value: "orderValueBased"
        }
    ]


    const { bankerEmail, setTabSelected, CategoriesDrawerOpen, SetCategoriesDrawerOpen, login, loginData,
        refetchProduct, setRefechProduct, businessDrawer, setBusinessDrawer, selectedBusiness, selectedStatus, setSelectedStatus,
        statusValues, clickedBusiness, setClickedBusiness
    } = useContext(GlobalContex);

    useEffect(() => {
        setStep("Action");
        setPath(["Action"]);
        console.log("businessDetail", clickedBusiness)
    }, [businessDrawer]);



    useEffect(() => {
        setStep(path[path.length - 1]);
    }, [path]);


    const handleBreadcrumbClick = (clickedStep) => {
        const foundStepIndex = path.findIndex((o) => o === clickedStep);
        const tempPath = path;
        tempPath.splice(foundStepIndex + 1, tempPath.length);
        setPath([...tempPath]);
        console.log(path, "lwndwed");
    };

    const deleteCategory = () => {
        let body = {
            status: "inactive"
        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Deleting ${selectedCategory?.name}...`)
        Axios.put(`https://frissly.apimachine.com/api/category/${selectedCategory?._id}`, body)
            .then(({ data }) => {
                console.log(data, "data")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    setStep("success")
                    setSuccessMsg(`You have successfully deleted ${selectedCategory?.name}. You will now be redirected to your updated categories list.`)
                    setTimeout(() => {
                        setTabSelected("Categories")
                        setStep("default");
                        SetCategoriesDrawerOpen(false);
                        setColouredIcon("")
                    }, 1000);
                    setRefechProduct(!refetchProduct)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }


    const ActionCheckDelivery = () => {
        Axios.get(`https://frissly.apimachine.com/api/business/delivery/mode?businessId=${clickedBusiness?._id}`)
            .then(({ data }) => {
                console.log(data, "delivery")
                if (data.status === false) {
                    if (data.message === "jwt expired") {
                        setStep("Token Expired");
                    } else {
                        setStep("default")
                        toast.warning(data.message || "API Error");
                    }
                    setStep("default")
                    toast.warning(data.message || "API Error");
                } else {
                    if (data.data.length === 0) {
                        setCheckDelivery("newData")
                    } else {
                        setCheckDelivery("existingData")
                    }
                    setDeliveryData(data.data)
                }
            })
            .catch((err) => {
                toast.error("API Error");
            })
    }

    const ActionUpdateDelivery = () => {
        let body
        let theAPI
        if (checkDelivery === "newData") {
            body = {
                deliveryType: selectedDelivery?.value,
                businessId: clickedBusiness?._id
            }
            theAPI = Axios.post(`https://frissly.apimachine.com/api/business/delivery/mode/create`, body)
        } else {
            body = {
                deliveryType: selectedDelivery?.value,
                status: "active"
            }
            theAPI = Axios.put(`https://frissly.apimachine.com/api/business/delivery/mode/${clickedBusiness?._id}`, body)

        }
        console.log(body)
        setStep("loading")
        setloadingtext(`Updating Delivery`)
        theAPI.then(({ data }) => {
            console.log(data, "data")
            if (data.status === false) {
                if (data.message === "jwt expired") {
                    setStep("Token Expired");
                } else {
                    setStep("default")
                    toast.warning(data.message || "API Error");
                }
                setStep("default")
                toast.warning(data.message || "API Error");
            } else {
                setStep("success")
                setSuccessMsg(`You have successfully updated delivery for ${clickedBusiness?.name}. You will now be redirected to your updated business list.`)
                setTimeout(() => {
                    setTabSelected("Business")
                    setStep("default");
                    setBusinessDrawer(false);
                    setSelectedDelivery("")
                }, 1000);
                setRefechProduct(!refetchProduct)
            }
        })
            .catch((err) => {
                toast.error("API Error");
            })
    }



    const fullHeightDrawer = (message) => {
        if (message) {
            return (
                <div
                    style={{
                        height: window.innerHeight - 150,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                            marginBottom: "20px",
                        }}
                    >
                        {message}
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        height: window.innerHeight - 123,
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadingAnimation logoAnim sideDraw={true} />
                    <div
                        style={{
                            position: "absolute",
                            bottom: 0,
                            textAlign: "center",
                        }}
                    >
                        Updating Icon...
                    </div>
                </div>
            );
        }
    };


    const conditionalSteps = () => {
        switch (step) {
            case "loading":
                return fullHeightDrawer(loadingtext);
            case "Token Expired":
                return (
                    <>
                        <div className="sidebarTitle">Token Expired. Login Again.</div>
                        <div
                            className="sidebarCard"
                            onClick={(e) => {
                                localStorage.clear();
                                window.location.reload();
                            }}
                        >
                            <img src={lock} alt="" style={{ width: "20px" }} />
                            <div style={{ paddingLeft: "10px" }}>Logout</div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div
                        className="assetDispText"
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            height: "60vh",
                            padding: "0px 20px",
                        }}
                    >
                        {successMsg}
                    </div>
                );
            case "Update delivery pricing":
                return (
                    <>
                        <div className="sidebarTitle">{checkDelivery === "newData" ? "You have yet to configure a delivery type for your business. Please select one of the delivery types" : "What would you like to do?"}</div>
                        <div className="sidebarScroll">
                            {deliveryCards.map((item, index) => {
                                return (
                                    <div
                                        className="sidebarCard" style={{ justifyContent: "space-between" }}
                                        onClick={() => ActionUpdateDelivery()}
                                    >
                                        <div onClick={() => setSelectedDelivery(item)}>{item?.key}</div>
                                        <div><img style={{ display: deliveryData?.[0]?.deliveryType === item?.value ? "" : "none" }} src={purpleTick} alt="purpleTick" /></div>
                                    </div>
                                )
                            })}
                        </div>
                    </>
                )
            default:
                return (
                    <>
                        <div className="sidebarTitle">What do you want to do?</div>
                        <div className="sidebarScroll">
                            <div
                                className="sidebarCard"
                                onClick={() => {
                                    setStep("Update delivery pricing");
                                    setPath([...path, "Update delivery pricing"]);
                                    ActionCheckDelivery()
                                }}
                            >
                                <div>Update delivery pricing logic</div>
                            </div>
                        </div>
                    </>
                )
        }
    }



    return (
        <div
            className={businessDrawer ? "right-drawer-visible" : "right-drawer-hidden"}
            style={{ height: window.innerHeight - 123, top: 132 }}
        >
            <div
                style={{
                    padding: "30px",
                    height: "90px",
                }}
            >
                {
                    step === "loading" ||
                        step === "Token Expired" ||
                        step == "success" ? (
                        ""
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* <img
                  src={
                    selectedBusiness?.profile_pic
                      ? selectedBusiness?.profile_pic
                      : defaultImg
                  }
                  alt=""
                  style={{
                    width: "28px",
                    height: "28px",
                    borderRadius: "100%",
                  }}
                /> */}
                                    <div>
                                        <div style={{ fontSize: "22px" }}>
                                            {clickedBusiness?.name}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {/* {path.length > 1 ? (
                  <div
                    className="backButton icon-imgs"
                    onClick={(e) => handleBackStep()}
                  >
                    <img src={back} alt="" />
                  </div>
                ) : (
                  ""
                )} */}
                                    <div
                                        className="backButton icon-imgs colorImg"
                                        onClick={(e) => {
                                            setPath([]);
                                            setBusinessDrawer(false);
                                        }}
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        <img src={PurpleClose} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div
                                className="breadcrumb"
                                style={{ display: "flex", flexDirection: "row" }}
                            >
                                {path?.map((item, index) => {
                                    return (
                                        <div>
                                            {index !== 0 ? <span>&nbsp;{`->`}&nbsp;</span> : ""}
                                            <span
                                                onClick={(e) => handleBreadcrumbClick(item)}
                                                className={
                                                    index === path.length - 1 ? "crumbSelected" : "crumbs"
                                                }
                                            >
                                                {item}
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                {conditionalSteps()}
                <ToastContainer />
            </div>
        </div>
    );
};

export default BusinessDrawer;
