import axios from "axios";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { GlobalContex } from "../../../../globalContex";
import Skeleton from "react-loading-skeleton";

import defaultImg from "../../../../static/images/icons/defaultImg.svg";
// import AppsSubDrawer from "./ArticlesSubDrawer";
// import "./dashboardApps.scss";

import { Typography } from "antd";
// import ArticlesSubDrawer from "./ArticlesSubDrawer";
// import ActionIndex from "./ArticlesSubDrawer/ActionIndex";

const Webinars = () => {
    const { Paragraph } = Typography;
    const {
        loginData,
        bankerEmail,
        selectedMcbDashboardApp,
        setSelectedMcbDashboardApp,
        showSubDraw,
        setShowSubDraw,
        refetchAppData,
        setSelectedTab,
        mcbMenu,
        isMobile,
        selectedBusiness,
        actionsSubDrawer,
        setActionsSubDrawer,
        authorDetail,
        setAuthorDetail,
        refetchArticles,
        setRefetchArticles,
        globalSearch,
        refetchCourses,
        getDisplayDate
    } = useContext(GlobalContex);
    const [allCourses, setAllCourses] = useState([]);
    const [allApps1, setAllApps1] = useState([]);
    const [appLoading, setAppLoading] = useState(false);
    const [selectedArticle, setSelectedArticle] = useState(null);

    useEffect(() => {
        setAppLoading(true);
        let pubdata;
        if (selectedBusiness) {
            pubdata = selectedBusiness._id
        } else {
            pubdata = "63a1a2c60e46260e093cf260"
        }
        axios
            .get(
                `https://publications.apimachine.com/webinar/list?publication_id=${pubdata}`
            )
            .then(({ data }) => {
                setAppLoading(false);
                if (data.status) {
                    setAllCourses(data.data);
                    // setAppLoading(false);
                }
                // setAppLoading(false);
            });
    }, [
        bankerEmail,
        selectedBusiness,
        refetchCourses
    ]);

    useEffect(() => {
        console.log('State updated:', selectedArticle);
    }, [selectedArticle]);

    const ActionClick = (item) => {
        setSelectedArticle(item);
        setActionsSubDrawer(true)
    }

    const compareDatesWithToday = (dateString) => {
        // Get the current date
        const today = new Date();

        // Parse the entered date and create a Date object
        const enteredDate = new Date(dateString.split('-').reverse().join('-'));

        // Compare the dates using regular comparison operators
        if (enteredDate < today) {
            return -1; // enteredDate is earlier than today
        } else if (enteredDate > today) {
            return 1; // enteredDate is later than today
        } else {
            return 0; // Both dates are the same (today)
        }
    }


    const conditionalResposiveView = (
        data,
        dataLoading,
        desktopDataGrid,
        mobileDataGrid
    ) => {
        return (
            <>
                <div className="desktopWrapper">
                    <div style={{ width: "100%" }}>
                        {headerSection("listGrid", desktopDataGrid)}
                    </div>
                    <div
                        style={{
                            // display: "flex",
                            fontWeight: 700,
                            fontSize: "20px",
                            height: window.innerHeight - 175,
                            overflowY: "scroll",
                        }}
                    >
                        {!dataLoading ? (
                            data?.length > 0 ? (
                                data.filter(
                                    (item) =>
                                        item?.name
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase()) ||
                                        item?._id
                                            ?.toLowerCase()
                                            .includes(globalSearch.toLowerCase())).map((item) => {
                                                return contentSection(item, "listDataGrid", desktopDataGrid);
                                            })
                            ) : (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "60vh",
                                    }}
                                >
                                    No Records Found
                                </div>
                            )
                        ) : (
                            loadingSection("listDataGrid", desktopDataGrid)
                        )}
                        {/* <ActionIndex
              selectedArticle={selectedArticle}
              setSelectedArticle={setSelectedArticle}
            /> */}
                    </div>
                </div>

                <div className="mobileWrapper">
                    {!showSubDraw ? (
                        <div style={{ overflowY: "scroll", height: "80vh" }}>
                            {headerSection("listGridMobile", mobileDataGrid)}

                            {!appLoading ? (
                                allCourses?.length > 0 ? (
                                    allCourses.map((item, index) => {
                                        return contentSection(
                                            item,
                                            "listDataGridMobile",
                                            mobileDataGrid
                                        );
                                    })
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "60vh",
                                        }}
                                    >
                                        No Records Found
                                    </div>
                                )
                            ) : (
                                loadingSection("listDataGrid", mobileDataGrid)
                            )}
                            {/* <AppsSubDrawer allApps={allApps} /> */}
                        </div>
                    ) : (
                        // <AppsSubDrawer allApps={allApps} />
                        <></>
                    )}
                </div>
            </>
        );
    };

    // Change these three Sections according to the design

    const headerSection = (gridClass, gridValues) => {
        return (
            <div className={gridClass} style={{ gridTemplateColumns: gridValues }}>
                <div>Name</div>
                <div style={{ textAlign: "left" }}>Date</div>
                <div style={{ textAlign: "left" }}>Cost</div>
                <div style={{ textAlign: "left" }}>Status</div>
                <div style={{ textAlign: "left" }}>Recording</div>
                <div style={{ textAlign: "left" }}></div>
            </div>
        );
    };

    const contentSection = (item, gridClass, gridValues) => {
        const comparison = compareDatesWithToday(item?.date);
        return (
            <div
                className={gridClass}
                style={{
                    gridTemplateColumns: gridValues,
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src={item.image ? item.image : defaultImg}
                        alt=""
                        style={{
                            // borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                        }}
                    // className={classNames.icon}
                    />
                    <div style={{ paddingLeft: "15px" }}>
                        <div className="title">
                            <Paragraph copyable={{ text: item?.title }}>
                                {item?.title?.length > 20 ? item?.title.slice(0, 20) + "..." : item?.title}
                            </Paragraph>
                        </div>
                        <div className="subtitle">
                            <Paragraph copyable={{ text: item?._id }}>
                                {item?._id?.length > 20 ? item?._id.slice(0, 20) + "..." : item?._id}
                            </Paragraph>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    {/* <img
          src={defaultImg}
          alt=""
          style={{
            width: "30px",
            height: "30px",
          }}
        /> */}
                    <div>
                        <div className="title">
                            <Paragraph copyable={{ text: getDisplayDate(item?.date) }}>
                                {getDisplayDate(item?.date)}
                            </Paragraph>
                        </div>
                        <div className="subtitle">
                            <Paragraph copyable={{ text: item?.startTime + "-" + item?.endTime + " IST" }}>
                                {item?.startTime} - {item?.endTime} IST
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div style={{ textAlign: "left" }}>
                        <div className="title">
                            <Paragraph copyable={{ text: (item?.costStructure) }}>
                                {item?.costStructure}
                            </Paragraph>
                        </div>
                        <div className="subtitle">
                            <Paragraph copyable={{ text: (item?.cost) + " " + (item?.currency) }}>
                                {item?.cost}&nbsp;{item?.currency}
                            </Paragraph>
                        </div>
                    </div>
                </div>
                {/* <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div style={{ textAlign: "right" }}>
                        <div className="title">
                            <Paragraph copyable={{ text: item?.costStructure }}>
                                {item?.cost}&nbsp;{item?.currency}
                            </Paragraph>
                        </div>
                    </div>
                </div> */}
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div style={{ textAlign: "right" }}>
                        <div className="title">
                            <Paragraph copyable={{ text: "Upcoming" }}>
                                {comparison === 1 ? "Upcoming" : "Completed"}
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <div style={{ textAlign: "right" }}>
                        <div className="title">
                            <Paragraph copyable={{ text: item?.recordingStatus }}>
                                {item?.recordingStatus}
                            </Paragraph>
                        </div>
                    </div>
                </div>
                <div className="btngrp">
                    <button
                        className="readbtn" style={{ width: "120px" }}
                    >
                        Registration
                    </button>
                </div>
                {/* <div className="btngrp">
                    <button
                        className="readbtn"
                        onClick={() => window.open(`https://web3today.io/news/article/${item.custom_url}`)}
                    >
                        Read
                    </button>
                    <button
                        className="actionbtn"
                    >Stats</button>
                    <button
                        className="actionbtn"
                        onClick={() => ActionClick(item)}
                    >
                        Actions
                    </button>
                </div> */}
            </div>
        );
    };

    const loadingSection = (gridClass, gridValues) => {
        return Array(10)
            .fill("")
            .map((item, i) => {
                return (
                    <div
                        className={gridClass}
                        style={{
                            width: "100%",
                            gridTemplateColumns: gridValues,
                            // borderBottom: "solid 0.5px #EEEEEE",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            />
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                            }}
                        >
                            {/* <Skeleton
                                className="dp"
                                circle
                                width={50}
                                height={50}
                                style={{ marginRight: "20px" }}
                            /> */}
                            <div className="userDetail">
                                <Skeleton width={100} />
                                <Skeleton width={120} style={{ height: "10px" }} />
                            </div>
                        </div>
                        {Array(5).fill("").map((item, i) => {
                            return <div
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "flex-start",
                                }}
                            >
                                <div className="userDetail">
                                    <Skeleton width={80} />
                                </div>
                            </div>
                        })}
                    </div>
                );
            });
    };

    return (
        <>
            {conditionalResposiveView(
                allCourses,
                appLoading,
                "2fr 1.7fr 0.8fr 1fr 0.6fr 1fr 0.1fr", // Desktop view Grid columns
                "350px 250px 250px" // Mobile view Grid columns
            )}
        </>
    );
};

export default Webinars;
